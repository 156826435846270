import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { AniosReducer } from "../reducers/AniosReducer";

import { AuthReducer } from "../reducers/AuthReducer";
import { CotizadorReducer } from "../reducers/CotizadorReducer";
import { MarcasReducer } from "../reducers/MarcasReducer";
import MessageReducer from "../reducers/MessageReducer";
import { ModelosReducer } from "../reducers/ModelosReducer";
import { VersionVehiculoReducer } from "../reducers/VersionVehivuloReducer";
import { CotizaSeguroReducer } from "../reducers/CotizaSeguroReducer";
import { CodigoPostalReducer } from "../reducers/CodigoPostalReducer";
import { PostCotizaReducer } from "../reducers/PostCotizaReducer";
import { SucursalesReducer } from "../reducers/SucursalesReducer";
import { PutCotizaReducer } from "../reducers/PutCotizaReducer";
import { SolicitudesReducer } from "../reducers/SolicitudesReducer";
import { AlertMessageReducer } from "../reducers/AlertMessageReducer";
import { CatalogosReducer } from "../reducers/CatalogosReducer";
import { RFCReducer } from "../reducers/RFCReducer";
import { SaveSolicitudReducer } from "../reducers/SaveSolicitudReducer";
import { UpdatePartialSolicitudReducer } from "../reducers/UpdatePartialSolicitudReducer";
import { SendCallReducer } from "../reducers/SendCallReducer";
import { SendSMSReducer } from "../reducers/SendSMSReducer";
import { ScoreResultReducer } from "../reducers/ScoreResultReducer";
import { JobsReducer } from "../reducers/JobsReducer";
import { LoadingReducer } from "../reducers/LoadingReducer";
import { MensajesErroresReducer } from "../reducers/MensajesErroresReducer";
import { ModalSucessReducer } from "../reducers/ModalSucessReducer";
import { MenuReducer } from "../reducers/MenuReducer";
import { SendDoctoAdicionalReducer } from "../reducers/SendDoctoAdicionalReducer";
import { AssignedCommercialReducer } from "../reducers/AssignedCommercialReducer";
import { PurchaseDetailReducer } from "../reducers/PurchaseDetailReducer";
import { PlanAutorizadoReducer } from "../reducers/PlanAutorizadoReducer";
import { DashboardReducer } from "../reducers/DashboardReducer";
import { ImageProfileReducer } from "../reducers/ImageProfileReducer";
import { CotizadorSeguroReducer } from "../reducers/seguro/CotizadorSeguroReducer";
import { SolicitudSeguroReducer } from "../reducers/seguro/SolicitudSeguroReducer";
import { CatalogosSeguroReducre } from "../reducers/seguro/CatalogosSeguroReducer";
import { ObtencionSolicitudesSegurosReducer } from "../reducers/seguro/ObtencionSolicitudesSegurosReducer";
import { ObtencionSolicitudSeguroReducer } from "../reducers/seguro/ObtencionSolicitudSeguro";

const reducers = combineReducers({
    menu: MenuReducer,
    alertMessage: AlertMessageReducer,
    auth: AuthReducer,
    message: MessageReducer,
    marcas: MarcasReducer,
    modelos: ModelosReducer,
    anios: AniosReducer,
    versionVehiculo: VersionVehiculoReducer,
    cotizador: CotizadorReducer,
    cotizaSeguro: CotizaSeguroReducer,
    codigoPostal: CodigoPostalReducer,
    postCotiza: PostCotizaReducer,
    sucursalSelection: SucursalesReducer,
    putCotiza: PutCotizaReducer,
    solicitudes: SolicitudesReducer,
    catalogos: CatalogosReducer,
    rfc: RFCReducer,
    saveSolicitud: SaveSolicitudReducer,
    updatePartialSolicitud: UpdatePartialSolicitudReducer,
    sendCall: SendCallReducer,
    sendSMS: SendSMSReducer,
    scoreResult: ScoreResultReducer,
    jobs: JobsReducer,
    loading: LoadingReducer,
    mensajeError: MensajesErroresReducer,
    modalSucces: ModalSucessReducer,
    sendDoctoAdicional: SendDoctoAdicionalReducer,
    assignedCommercial: AssignedCommercialReducer,
    purchaseDetail: PurchaseDetailReducer,
    planAutorizado: PlanAutorizadoReducer,
    dashboard: DashboardReducer,
    profileImage: ImageProfileReducer,
    cotizadorSeguro: CotizadorSeguroReducer,
    solicitudSeguro: SolicitudSeguroReducer,
    catalogosSeguro: CatalogosSeguroReducre,
    obtencionSolicitudSeguros: ObtencionSolicitudesSegurosReducer,
    obtencionSolicitudSeguro: ObtencionSolicitudSeguroReducer

});

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
);