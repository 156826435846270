import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files';
import { FormatoCantidad, readFile } from '../helpers/functionGlobal';

const FileUpload = ({ name, fileTypes, multiple = false, maxSize = 1, textoTipoDoc, textoFormatos, textoLinkCarga, textoLinkReemplaza, handleChange }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState("")
    const archivos = (files) => {
        if(!multiple) {
            return <>
                <b className='name-image'>{files ? files.name : ""}</b>
                <p className='size-image'>{files ? FormatoCantidad(files.size, 0, "", ",") : ""} KB</p>
            </>
        } else {
            return Object.values(files).map((archivo, index) => {
                return <div key={index}>
                    <b className='name-image'>{archivo ? archivo.name : ""}</b>
                    <p className='size-image'>{archivo ? FormatoCantidad(archivo.size, 0, "", ",") : ""} KB</p>
                </div>
            });
        }
    }

    return (
        <>
            <FileUploader
                multiple={multiple}
                maxSize={maxSize}
                handleChange={((file) => {
                    if(multiple) {
                        if(file.length <= 10 && file.length > 0) {
                            setFile(file);
                            handleChange(file, name);
                            setError("");
                        } else {
                            if(file.length >= 10) {
                                setError("No se puede cargar mas de 10 archivos");
                                setFile(null);
                            }
                        }
                    } else {
                        setError("");
                        setFile(file);
                        handleChange(file, name);
                    }
                    
                })}
                name={name}
                types={fileTypes}
                classes="carga-documento"
                onSizeError = {(file) => {
                    setError(`El tamaño de la imagen debe ser de ${maxSize} MB`);
                }}
            >
                {
                file === null ?
                <div className='texto-default'>
                    <i className='icon-upload'></i>
                    <b>{textoTipoDoc}</b>
                    <p className='texto-formatos'>{textoFormatos}</p>
                    <p className='nav-link m-0'>{textoLinkCarga}</p>
                </div>
                :
                <div className='datos-image'>
                    <i className='icon-picture'></i>
                    <div>
                        {archivos(file)}
                        <p className='nav-link m-0 text-end'>{textoLinkReemplaza}</p>
                    </div>
                    
                </div>
                }
            </FileUploader>
            <p className='texto-error'>{error}</p>
        </>
        
    )
}

export default FileUpload