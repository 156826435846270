import { type } from "../types/index";
import { updateDataSolicitud } from "./solicitudes";

export const cotizadorPaso1 = (data,combos) => (dispatch) => {
    console.log("cotizadorPaso1 data",data,"combos",combos);
    const { anio, codigoPostal, marca, modelo, version, clienteIngresos, tipoComprobante, historialCrediticio } = data;
    const {marcaText, modeloText, versionText} = combos
    //console.log(clienteIngresos);
    let comprobantes = 0;
    if(clienteIngresos) {
        comprobantes = tipoComprobante;
    }
    const dataPaso1 = {
        paso: 2,
        IdDescripcion: version,
        IdDescripcionText: versionText,
        Modelo: parseInt(anio),
        CP: codigoPostal,
        Auto: {
            IdDescripcion: version,
            IdDescripcionText: versionText,
            Modelo: modelo,
            Marca: marca,
            MarcaText: marcaText,
            ModeloText: modeloText,


        },
        Historial: historialCrediticio,
        Comprobantes: comprobantes
    }

    const dataSolicitud = {
        idMarca: marca,
        marcaVehiculo: marcaText,
        modeloAuto: modelo,
        tipoVehiculo: modeloText,
        modelo: parseInt(anio),
        historial: historialCrediticio,
        comprobanteIngresos: comprobantes,
        idDescripcion: version,
        versionVehiculo: versionText
    }
    dispatch(updateDataSolicitud(dataSolicitud));
    dispatch({
        type: type.addCotizador,
        payload: dataPaso1
    })
}

export const cotizadorPaso2 = (data) => (dispatch) => {

    const { costoAuto, enganche, montonFinanciar, MinEnganche } = data;

    const dataPaso2 = {
        paso: 3,
        ValorAuto: costoAuto,
        Enganche: enganche,
        montoFinanciamiento: montonFinanciar,
        MinEnganche: MinEnganche
    }
    dispatch({
        type: type.addCotizador,
        payload: dataPaso2
    });
}

export const cotizadorPaso2SinSeguro = (data) => (dispatch) => {

    const { costoAuto, enganche, montonFinanciar, MinEnganche } = data;

    const dataPaso2 = {
        ValorAuto: costoAuto,
        Enganche: enganche,
        montoFinanciamiento: montonFinanciar,
        MinEnganche: MinEnganche
    }
    dispatch({
        type: type.addCotizador,
        payload: dataPaso2
    });
}

export const cotizadorPaso3 = (data, seguroSelect, isTradicional) => (dispatch) => {
    let dataSeguro = {
        paso: 4,
        isTradicional: isTradicional,
        cotizacion: {
            Inicial: data,
            PagoMensual: "",
            Plazo: "",
            SeguroFinanciado: "",
        },
        seguroSelect: {
            IdCotizacionSeguro: "",
            Descripcion: "",
            Aseguradora: "",
            PrimaTotal: "",
            MensualSinIva: "",
            Inicial: "",
            id_aseguradora: null,
            PrimaNeta: "",
            idDescripcion: "",
            CP: "",
            MODELO: ""
        }
    };

    if(seguroSelect !== null ) {
        dataSeguro = {
            paso: 4,
            isTradicional: isTradicional,
            cotizacion: {
                Inicial: data,
                PagoMensual: "",
                Plazo: "",
                SeguroFinanciado: true,
            },
            seguroSelect: seguroSelect
        }
    }
    dispatch({
        type: type.addCotizador,
        payload: dataSeguro
    });
}

export const cotizadorPaso4 = (data) => (dispatch) => {

    const dataSeguro = {
        paso: 5,
        cotizacion: data
    }
    dispatch({
        type: type.addCotizador,
        payload: dataSeguro
    });
}

export const cotizadorPaso4Tradicional = (data) => (dispatch) => {

    const dataSeguro = {
        cotizacion: data
    }
    dispatch({
        type: type.addCotizador,
        payload: dataSeguro
    });
}

export const resetCotizador = () => (dispatch) => {
    dispatch({
        type: type.resetCotizador
    });
}

export const regresarPaso = (paso) => (dispatch) => {

    const data = {
        paso: paso
    }
    if(paso === 1) {
        let dataSeguro = {
            isTradicional: false,
            cotizacion: {
              Inicial: "",
              PagoMensual: "",
              Plazo: "",
              SeguroFinanciado: true,
            },
            montoFinanciamiento: "",
            ValorAuto: "",
            Enganche: "",
            MinEnganche: "",
            seguroSelect: []
        };
        dispatch({
            type: type.addCotizador,
            payload: dataSeguro
        });
    }
    dispatch({
        type: type.backStepCotizador,
        payload: data
    });
}