import React, { useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';


import { getDashboard } from '../actions/dashboard';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { getSecurityKey } from '../helpers/SecurityKey';
import { MensajesAlertas } from '../models/MensajesAlertas';

import CardSemiRound from './CardSemiRound';
import CardSolicitudes from './CardSolicitudes';
import CardVersusCard from './CardVersusCard';


const Dashboard = (props) => {

  const { closeModal } = props;
  const dispatch = useDispatch();

  const securityKey = getSecurityKey();
  //const { poliza, contrato } = obtencionPurchase.dataPurchaseDetail

  const sucursalSelec = useSelector((state) => state.sucursalSelection);
  const { Clave, CveDistribuidor} = sucursalSelec.sucursalSeleccionada
  const obtencionDashboard = useSelector((state) => state.dashboard);
  const { firstCard, secondCard, thirdCard, profilePicture } = obtencionDashboard.dataDashboard;

  useEffect(() => {
    dispatch(getDashboard(Clave, CveDistribuidor, securityKey))
    .then((res)=> {
      if(isNaN(res)) {
        if(res.thirdCard.rows.length === 0) {
          dispatch(showAlert(MensajesAlertas.mensajeNoHaySolicitudesDashboard,
            () => { closeModal(); },
            "",
            res.thirdCard.message
          ));
        }
      }
    });
  }, [Clave, CveDistribuidor, profilePicture]);

  return (
    <div className='row g-4 pt-4'>
      <div className='col-5'>
        {<CardSemiRound data={firstCard}  />}
      </div>
      <div className='col-7'>
        {<CardVersusCard dataCard={secondCard} />}
      </div>
      <div className='col-12'>
        {<CardSolicitudes data={thirdCard} />}
      </div>
      
    </div>
    
  )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert())
	};
};

//export default Dashboard
export default connect(null, mapDispatchToProps)(Dashboard);