import { type as types } from "../types/index";

const initialState = {isCotizaSeguro: false, cotizaSeguro: null};

export const CotizaSeguroReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (action.type) {
        case types.getCotizaSeguros:
            const result = {
                ...state, 
                isCotizaSeguro: true,
                cotizaSeguro: payload
            };
            return result;
        case types.getCotizaSegurosFail:
            return {
                ...state,
                isCotizaSeguro: false,
                cotizaSeguro: null
            }
        default:
            return state;
    }
}
