import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';

import { getSecurityKey } from '../../helpers/SecurityKey';
import { codigoPostal } from '../../actions/codigoPostal';
import ModalError from '../ModalError';
import { resetFormSolicitud, solicitudPaso2 } from '../../actions/seguros/solicitudSeguros';
import { hideAlert, showAlert } from '../../actions/alertMessage';
import { resetCotizadorSeguro } from '../../actions/seguros/cotizadorSeguros';
import { MensajesAlertas } from '../../models/MensajesAlertas';
import { useNavigate } from 'react-router-dom';

const dataDireccion = {
  ciudadState: "",
  estadoState: "",
  municipioState: "",
  coloniasState: []
};

const FormDatosDomicilioSeguro = ( props) => {

  const { closeModal, clearCotizador } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const securityKey = getSecurityKey();
  
  const dataUser = useSelector((state) => state.auth);
  const obtencionSolicitud = useSelector((state) => state.solicitudSeguro);
  const { calle, numeroExterior, numeroInterior, CP, colonia, municipio, estado, coloniaStr, id } = obtencionSolicitud;
  //console.log(obtencionSolicitud);
  const [direccionState, setDireccionState] = useState(dataDireccion)
  const { ciudadState, estadoState, municipioState, coloniasState } = direccionState;
  const [ModalShow, setModalShow] = useState(false);
  const [showColonia, setShowColonia] = useState(colonia);
  
  const [combos, setCombos] = useState({
    colonia: colonia
  });

  const validateDomicilio = Yup.object({
    calle: Yup.string().required('El campo es requerido').nullable(),
    noExterior: Yup.string().required('El campo es requerido').nullable(),
    codigoPostal: Yup.string().required('El campo es requerido').nullable(),
    alcaldia: Yup.string().required('El campo es requerido'),
    estado: Yup.string().required('El campo es requerido'),
    colonia: Yup.string().required('El campo es requerido'),
    existeColonia: Yup.boolean(),
    anadeColonia: Yup.string().when('existeColonia', {
        is: true,
        then: Yup
            .string()
            .required('El campo es requerido')
    }),
  });

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(validateDomicilio),
    mode: "onChange" 
  });

  const onSubmit = (data) => {
    const { correo } = dataUser.user;
    dispatch(solicitudPaso2(data, correo, securityKey, id));
  };

  const llenadoCombo = (e) => {
    const { name, value } = e.target;
    const comboSelec = {
        ...combos,
        [name]:value
    };
    setCombos(comboSelec);
  }

  const getCodigoPostal = (cp, tipoCarga) => {
    //const cp = e.target.value;
    if(cp !== "") {
        dispatch(codigoPostal(dataUser.user.correo, String(cp), securityKey))
        .then((res)=>{
            if(res.Codigo === 0) {
              const dataResponse = {
                ciudadState: res.ciudad,
                estadoState: res.estado,
                municipioState: res.municipio,
                coloniasState: res.colonias
              }
              setDireccionState(dataResponse);
              setValue("estado", res.estado);
              setValue("alcaldia", res.municipio);
              if(tipoCarga === 1) {
                setValue("colonia", colonia);
              } else {
                const estatoCombos = {
                  colonia: ""
                }
                setCombos(estatoCombos);
                setValue("colonia", "");
              }
            } else if(res.Codigo === -1) {
              const dataResponse = {
                ciudadState: "",
                estadoState: "",
                municipioState: "",
                coloniasState: ""
              }
              setDireccionState(dataResponse);
              setValue("estado","");
              setValue("alcaldia", "");
              setValue("colonia", "");
            }
        })
    }
  }
  const optionColonias = coloniasState ? coloniasState.map((optionColonia, index) =>{
    return <option key={index} value={optionColonia} >{optionColonia}</option>
  }) : "";

  useEffect(() => {
    setValue("calle", calle);
    setValue("noExterior", numeroExterior);
    setValue("noInterior", numeroInterior);
    setValue("codigoPostal", CP);
    CP && getCodigoPostal(CP, 1);
  }, []);

  const salirSolicitud = () => {
    dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); } ));
  }
  return (
    <Form className='formulario-domicilio' onSubmit={handleSubmit(onSubmit)}>
      <Card className='card-formulario card-domicilio'>
        <Card.Title className='my-3'><i className='icon-pin me-3'></i>Domicilio</Card.Title>
        <Card.Text className='texto-informacion-roboto'>Los datos deben coincidir con la identificación oficial de tu cliente.</Card.Text>
        <Card.Body className='p-0'>
          <Row>
            <Form.Group as={Col} xs="12" className='mb-3'>
                <Form.Label>Calle</Form.Label>
                <Form.Control 
                    type="text" 
                    name="calle" 
                    placeholder="Escribe la calle"
                    maxLength={100}
                    autoComplete="off"
                    className={`${errors.calle ? 'is-invalid' : ""}`}
                    {...register('calle')}
                />
                <p className='texto-error'>{errors.calle?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs="4" className='mb-3'>
              <Form.Label>No. exterior</Form.Label>
              <Form.Control 
                  type="text" 
                  name="noExterior" 
                  placeholder="No. exterior"
                  maxLength={16}
                  autoComplete="off"
                  className={`${errors.noExterior ? 'is-invalid' : ""}`}
                  {...register('noExterior')}
              />
              <p className='texto-error'>{errors.noExterior?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs="4" className='mb-3'>
                <Form.Label>No. interior(opcional)</Form.Label>
                <Form.Control 
                    type="text" 
                    name="noInterior" 
                    placeholder="No. interior"
                    maxLength={16}
                    autoComplete="off"
                    className={`${errors.noInterior ? 'is-invalid' : ""}`}
                    {...register('noInterior')}
                />
                <p className='texto-error'>{errors.noInterior?.message}</p>
            </Form.Group>

            <Form.Group as={Col} xs="4" className='mb-3'>
              <Form.Label>Código postal</Form.Label>
              <Form.Control 
                  type="text" 
                  name="codigoPostal" 
                  placeholder="Código postal"
                  maxLength={5}
                  autoComplete="off"
                  className={`${errors.codigoPostal ? 'is-invalid' : ""}`}
                  {...register('codigoPostal')}
                  onBlur={ (e) => {
                      const cp = e.target.value;
                      getCodigoPostal(cp, 2)
                  }}
              />
              <p className='texto-error'>{errors.codigoPostal?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="12" className='mb-3' >
              <Form.Label htmlFor="colonia">Colonia</Form.Label>
              <Form.Select 
                  name="colonia"
                  value={combos.colonia === null ? "" : combos.colonia}
                  className={`${errors.colonia ? 'is-invalid' : ""} ${combos.colonia ? 'custom-select' : ''}`}
                  {...register('colonia', {
                      onChange: (e) => {
                          llenadoCombo(e);
                      }
                  })}
              >
                  <option value="">Selecciona una colonia</option>
                  {optionColonias}
              </Form.Select>
              <p className='texto-error'>{errors.colonia?.message}</p>
            </Form.Group>
            {
              /*showColonia === "0" &&
              <Form.Group as={Col} xs="12" className='mb-3' >
                  <Form.Label htmlFor="colonia">Colonia</Form.Label>
                  <Form.Select 
                      name="colonia"
                      className={`${errors.colonia ? 'is-invalid' : ""} ${combos.colonia ? 'custom-select' : ''}`}
                      {...register('colonia', {
                          onChange: (e) => {
                              llenadoCombo(e);
                          }
                      })}
                  >
                      <option value="">Selecciona una colonia</option>
                      {optionMunicipios}
                  </Form.Select>
                  <p className='texto-error'>{errors.colonia?.message}</p>
              </Form.Group>*/
            }
            {/*<Form.Group as={Col} xs="12" className='existe-colonia' >
              <Form.Check
                  name='existeColonia'
                  label="La colonia no está en el listado"
                  {...register("existeColonia")}
                  onChange={(e) => {
                      let check = e.target.checked;
                      check ? setShowColonia("-1") : setShowColonia("0");
                      
                  }}
              />
                </Form.Group>*/}
            {
              /*showColonia === "-1" &&
              <Form.Group as={Col} xs="12" className='mb-3'>
                  <Form.Label>Colonia</Form.Label>
                  <Form.Control 
                      type="text" 
                      name="anadeColonia" 
                      placeholder="Añade la colonia"
                      maxLength={100}
                      autoComplete="off"
                      className={`${errors.anadeColonia ? 'is-invalid' : ""}`}
                      {...register('anadeColonia')}
                  />
                  <p className='texto-error'>{errors.anadeColonia?.message}</p>
              </Form.Group>*/
            }
            <Form.Group as={Col} xs="6" className='mb-3'>
              <Form.Label>Alcaldía o municipio</Form.Label>
              <Form.Control 
                  type="text" 
                  name="alcaldia"
                  placeholder=""
                  maxLength={100}
                  autoComplete="off"
                  className={`${errors.alcaldia ? 'is-invalid' : ""}`}
                  disabled={true}
                  {...register('alcaldia')}
              />
              <p className='texto-error'>{errors.alcaldia?.message}</p>
            </Form.Group>
            <Form.Group as={Col} xs="6" className='mb-3'>
              <Form.Label>Estado</Form.Label>
              <Form.Control 
                  type="text" 
                  name="estado" 
                  //value={estado}
                  placeholder=""
                  maxLength={100}
                  autoComplete="off"
                  className={`${errors.estado ? 'is-invalid' : ""}`}
                  disabled={true}
                  {...register('estado')}
              />
              <p className='texto-error'>{errors.estado?.message}</p>
            </Form.Group>
          </Row>
          <Row className='mt-5'>
            <Col xs={6} className='px-2'>
                <Button type="button" className="btn btn-light w-100" onClick={salirSolicitud} >Cancelar</Button>
            </Col>
            <Col xs={6} className='px-2'>
                <Button type="submit" className="btn btn-primary w-100" >Siguiente</Button>
            </Col>
          </Row>
        </Card.Body>
        <ModalError show={ModalShow} onHide={() => setModalShow(false)} />
      </Card>
    </Form>
  )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
    clearCotizador: () => {
        dispatch(resetFormSolicitud());
        dispatch(resetCotizadorSeguro());
    } 
	};
};
export default connect(null, mapDispatchToProps)(FormDatosDomicilioSeguro);