import { type as types } from "../types/index";

const initialState = {isSendSMS: false, isLoading: false, dataSMS: null};

export const SendSMSReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.sendSMS:
            return {
                ...state,
                isSendSMS: true,
                dataSMS: payload
            };
        case types.sendSMSFail:
            return {
                ...state,
                isSendSMS: false,
                dataSMS: null
            }
        default:
            return state;
    }
}
