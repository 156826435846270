import { type as types } from "../types/index";
//import { DataDashboard } from "../models/DataDashboard";

const initialState = {isImageProfile: false, dataImageProfile: null };

export const ImageProfileReducer = (state = initialState, action) => {

    const {type, payload } = action;

    switch (type) {

        case types.setImageProfile:
            const result = {
                isImageProfile: true,
                dataImageProfile: payload
            };
            return result;
        case types.setImageProfileFail:
            return {
                ...state,
                isImageProfile: true
            }
        default:
            return state;
    }
}
