import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import logo_atria from "../assets/images/logos/atria-white.png";
import Loading from '../components/Loading';
import { login } from '../actions/auth';
import AlertError from '../components/AlertError';
import AlertaMessage from '../components/AlertaMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';

const LoginScreen = () => {

  const [loadingShow, setLoadingShow] = useState(false);

  const alertMessage = useSelector((state) => state.alertMessage);
  const { show } = alertMessage;

  const user = useSelector((state) => state.auth);
  const { isLoggedIn, errorMessage} = user;
  const dispatch = useDispatch();
  const [viewPassword, setViewPassword] = useState(false);
  const switchPassword = () => setViewPassword(!viewPassword);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    const { usuario, password } = data;
    setLoadingShow(true);
    dispatch(login(usuario, password))
    .then((res)=>{
      setLoadingShow(false);
    })
    .catch(()=>{
      setLoadingShow(false);
    });
  }

  return (
    <section className='seccion-login'>
      <Container>
        <Row>
          <Col className='col-informacion-pagina'>
            <img src={logo_atria} alt="Atria" className='logo-atria' />

            <h1 className='texto-titulo'>Crédito<br /> Automotriz ágil<br /> y flexible.</h1>
          </Col>
          <Col className='col-login d-flex align-items-center justify-content-center'>
            <Card className="card-login" >
              <Card.Body>
                <Card.Title>Hola,<br /> bonito día.</Card.Title>
                <Card.Text>Inicia sesión y arranca con todo.</Card.Text>
                { (!isLoggedIn && errorMessage !== null) && <AlertError textoError={errorMessage} estatus={true}  />}
                
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Form.Group as={Col} xs="12" className='mb-3'>
                      <Form.Label>Usuario</Form.Label>
                      <Form.Control 
                        type="text" 
                        name="usuario" 
                        placeholder="Ingresa un usuario"
                        maxLength={60}
                        autoComplete="off"
                        className={`${errors.usuario ? 'is-invalid' : ""}`}
                        {...register('usuario', { required: "El campo es requerido" })} 
                      />
                      <p className='texto-error'>{errors.usuario?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="12" className='col-input mb-3'>
                      <Form.Control 
                          type={ viewPassword ? 'text' : 'password' }
                          name="password" 
                          placeholder="Ingresa una contraseña"
                          maxLength={30}
                          autoComplete="off"
                          className={`${errors.password ? 'is-invalid' : ""}`}
                          {...register('password', { required: "El campo es requerido" })} 
                      />
                      <i className={ viewPassword ? 'icon-eye-off' : 'icon-eye'} onClick={switchPassword} ></i> 
                      <p className='texto-error'>{errors.usuario?.message}</p>
                    </Form.Group>
                    
                    <Form.Group as={Col} md="7">
                      <Form.Check
                        name='recordarme'
                        label="Recordarme"
                        className='form-check-input-primary-100'
                      />
                    </Form.Group>
                    
                    <Form.Group as={Col} md="5" className='text-forgot-password align-self-center text-end'>
                      OLVIDÉ MI CONTRASEÑA
                    </Form.Group>

                    <Form.Group as={Col} xs="6" className="mt-4 ms-auto" >
                      <Button type="submit" className="btn btn-primary w-100" >Iniciar sesión</Button>
                    </Form.Group>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Loading show={loadingShow} />
        <AlertaMessage show={show} data={MensajesAlertas.errorConexion} />
      </Container>
    </section>
  )
}
export default LoginScreen;