import { type as types } from "../types/index";


const initialState = {isPlanAutorizado: false, dataPlanAutorizado: {}};

export const PlanAutorizadoReducer = (state = initialState, action) => {

    const {type, payload } = action;
    switch (type) {
        case types.getPlanAutorizado:
            return {
                ...state,
                isPlanAutorizado: true,
                dataPlanAutorizado: {
                    ...payload
                }
            }
        case types.getPlanAutorizadoFail:
            return {
                ...state,
                isPlanAutorizado: false,
                dataPlanAutorizado: {}
            }
        default:
            return state;
    }
}