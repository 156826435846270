import { type as types } from "../types/index";

const sucursalSeleccionada = JSON.parse(sessionStorage.getItem("sucursalSeleccionada"));
const initialState = sucursalSeleccionada ? { sucursalSeleccionada } : { sucursalSeleccionada: null}

export const SucursalesReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.updateSucursalSelection:
            const result = {
                sucursalSeleccionada: payload
            };
            return result;
        default:
            return state;
    }
}
