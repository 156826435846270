import { type } from "../../types/index";
import CatalogosService from "../../services/catalogos.service";
import { UpdateSecurityKey } from "../../helpers/SecurityKey";
import { MensajesAlertas } from "../../models/MensajesAlertas";

export const getCatalogosSeguro = (usuario, securitykey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    return CatalogosService.getCatalogosSeguros(usuario, securitykey).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(isNaN(data)) {
                if(data.codigo === 0) {
                    dispatch({
                        type: type.getCalogosSeguros,
                        payload: data
                    });
                    UpdateSecurityKey(data.llaveSeguridad);
    
                } else {
                    dispatch({
                        type: type.getCalogosSegurosFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.mensaje
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.getCotizaSegurosFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getCalogosSegurosFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    ) .catch((error)=>{
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "Hay un problemas con el servicio intente mas tarde"
            }
        });
    });
}