import React from 'react'

const CircleStep = ({ texto, estatus="activo" }) => {

  const mostrarPaso = () => {
    switch (estatus) {
      case "activo":
          return <div className='circle-step active'>{texto}</div>
      case "inactivo":
        return <div className='circle-step'>{texto}</div>
      case "completo":
        return <div className='circle-step complete'><i className='icon-paloma '></i></div>
    }
  }
  return (
    <>
      {mostrarPaso()}
    </>
  )
}

export default CircleStep