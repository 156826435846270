import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { MenuSolicitudes } from '../models/MenuSolicitudes';
import CardTabla from './CardTabla'
import MenuChip from './MenuChip';
import { getSecurityKey } from '../helpers/SecurityKey';
import { getSolicitudes } from '../actions/solicitudes';
import { EstatusSolicitudes } from '../models/EstatusSolicitudes';
import { FormatoCantidad } from '../helpers/functionGlobal';

const Solicitudes = ({actualizaSolicitud}) => {

  const [estatusSolictud, setestatusSolictud] = useState("Todos")

  const dispatch = useDispatch();
  const securityKey = getSecurityKey();
  

  const dataUser = useSelector((state) => state.auth);
  const sucursalSelec = useSelector((state) => state.sucursalSelection);
  
  const obtencionSolicitudes = useSelector((state) => state.solicitudes);
  //console.log(dataSolicitudes.GetSolicitudBySucursalDistribuidorResult.GetSolicitudBySucursalDistribuidorResult.lenght);
  //console.log(obtencionSolicitudes);
  const { dataSolicitudes} = obtencionSolicitudes;  

  useEffect(() => {
    const { user } = dataUser;
    const { Clave, CveDistribuidor, Vendedor } = sucursalSelec.sucursalSeleccionada;
    dispatch(getSolicitudes(user.correo, CveDistribuidor, Clave, Vendedor, securityKey))
  }, [dispatch, dataUser, sucursalSelec, actualizaSolicitud]);

  const tipoSolicitudes = (e) => {
    setestatusSolictud(e);
  }
  return (
    <>
      <h2 className='texto-subtitulo'>Créditos</h2>
      <p className='texto-informacion'>{ dataSolicitudes ? FormatoCantidad(dataSolicitudes.length, 0, "",",") : 0 }</p>
      <MenuChip 
        items={MenuSolicitudes} 
        onClick={tipoSolicitudes}
      />

      <CardTabla 
        items={ dataSolicitudes ? dataSolicitudes : [] }
        estatusSelec = {estatusSolictud} 
        listaEstatus = {EstatusSolicitudes}
      />
      
    </>
  )
}

export default Solicitudes