import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormatoCantidad, logoImg, logoSeguro } from '../helpers/functionGlobal';
import { saveSolicitudFinish } from '../actions/saveSolicitud';
import { getSecurityKey } from '../helpers/SecurityKey';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { resetSolicitud } from '../actions/solicitudes';
import { resetCotizador } from '../actions/cotizador';
import { resetRFC } from '../actions/rfc';

const ResumenSolicitud = (props) => {
    const { closeModal, clearCotizador } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const securityKey = getSecurityKey();
    
    const dataUser = useSelector((state) => state.auth);
    /*const obtencionCotizador = useSelector((state) => state.cotizador);
    const { PagoMensual, Plazo } = obtencionCotizador.cotizacion;
    const { ValorAuto, Enganche } = obtencionCotizador;*/

    //const { Aseguradora } = obtencionCotizador.seguroSelect; 
    /*const obtencionPutCotiza = useSelector((state) => state.putCotiza);
    const { financing_amount, commission, insurance_amount} = obtencionPutCotiza.putCotiza;*/

    const dataSolicitud = useSelector((state) => state.solicitudes);
    const { nombre, apellidoPaterno, apellidoMaterno, segundoNombre, genero, 
        estado_civil, fechaNacimiento, rfc, dependientes_economicos, 
        nivel_de_estudios, cuenta_con_vehiculo, calle, noExterior, noInterior, codigoPostal,
        tipoVivienda, coloniaStr, municipio, estado, mesesDomicilio, aniosDomicilio, telefonoFijo, telefonoMovil, id,
        mensaualidad, plazo, costoAuto, enganche, Aseguradora, resultadoImporte, comision_por_apertura, email, SeguroFinanciado,
        seller
    } = dataSolicitud.dataSolicitud; 

    const sucursalSelec = useSelector((state) => state.sucursalSelection);
    const { CveDistribuidor } = sucursalSelec.sucursalSeleccionada;

    const envioFinal = () => {
        dispatch(saveSolicitudFinish(dataUser.user.correo, securityKey, id, CveDistribuidor, seller))
    }

    const modalExito = () => { 
        dispatch(showAlert(MensajesAlertas.mensajeSolicitudPreautorizada, () => { closeModal(); } ));
    }

    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); }  ));
    }

    return (
        <div className='seccion-resumen-solicitud'>
            <Card className='card-plan-cotizacion'>
                <Card.Body >
                    <p className='tipo-pago'>Mensualidad</p>
                    <p className='monto'>${FormatoCantidad(mensaualidad, 2, ".", ",")}</p>
                    <p className='mensualidades'>a {plazo} meses</p>
                    <Row>
                        <Col xs={12} className="mb-3">
                            <p className='texto-label'>Costo del auto</p>
                            <p className='texto-resultado'>${FormatoCantidad(costoAuto, 2, ".", ",")}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Enganche</p>
                            <p className='texto-resultado'>${FormatoCantidad(enganche, 2, ".", ",")}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Monto a financiar</p>
                            <p className='texto-resultado'>${FormatoCantidad(resultadoImporte, 2, ".", ",")}</p>
                        </Col>
                        {
                            Aseguradora && 
                            <Col xs={12} >
                                <p className='texto-label'>Seguro { SeguroFinanciado ? "Financiado" : "de Contado" } <img src={logoImg(`./${logoSeguro(Aseguradora)}`)} className="ms-2 logo-seguro" alt={Aseguradora} /></p>
                                <p className='texto-resultado'>${FormatoCantidad(comision_por_apertura, 2, ".", ",")}</p>
                            </Col>
                        }
                        
                    </Row>
                </Card.Body>
            </Card>

            <hr className='linea-separacion' />

            <Card className='card-formulario '>
                <Card.Body className='p-0'>

                    <Card.Title><i className='icon-user me-3'></i>Datos personales</Card.Title>

                    <Row>
                        <Col xs={12} className="mb-3">
                            <p className='texto-label'>Nombre</p>
                            <p className='texto-resultado'>{`${nombre} ${segundoNombre} ${apellidoPaterno} ${apellidoMaterno}`}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Género</p>
                            <p className='texto-resultado'>{genero}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Estado civil</p>
                            <p className='texto-resultado'>{estado_civil}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Fecha de nacimiento</p>
                            <p className='texto-resultado'>{fechaNacimiento}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>RFC</p>
                            <p className='texto-resultado'>{`${rfc}`}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Dependientes económicos</p>
                            <p className='texto-resultado'>{dependientes_economicos}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Nivel de estudios</p>
                            <p className='texto-resultado'>{nivel_de_estudios}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Vehículos con los que cuenta</p>
                            <p className='texto-resultado'>{cuenta_con_vehiculo ? "Si" : "No"}</p>
                        </Col>
                    </Row>

                </Card.Body>
            </Card>

            <hr className='linea-separacion' />

            <Card className='card-formulario '>
                <Card.Body className='p-0'>

                    <Card.Title><i className='icon-pin me-3'></i>Domicilio</Card.Title>

                    <Row>
                        <Col xs={12} className="mb-3">
                            <p className='texto-label'>Calle</p>
                            <p className='texto-resultado'>{calle}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>No. exterior</p>
                            <p className='texto-resultado'>{noExterior}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>No. interior</p>
                            <p className='texto-resultado'>{noInterior}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Código postal</p>
                            <p className='texto-resultado'>{codigoPostal}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Tipo de vivienda</p>
                            <p className='texto-resultado'>{tipoVivienda}</p>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <p className='texto-label'>Colonia</p>
                            <p className='texto-resultado'>{coloniaStr}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Alcaldía o municipio</p>
                            <p className='texto-resultado'>{municipio}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Estado</p>
                            <p className='texto-resultado'>{estado}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Tiempo de residencia en el domicilio</p>
                            <p className='texto-resultado'>{aniosDomicilio} años {mesesDomicilio} meses</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <hr className='linea-separacion' />

            <Card className='card-formulario '>
                <Card.Body className='p-0'>

                    <Card.Title><i className='icon-phone me-3'></i>Contacto</Card.Title>

                    <Row>
                        <Col xs={12} className="mb-3">
                            <p className='texto-label'>Número de celular</p>
                            <p className='texto-resultado'>{telefonoMovil}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Teléfono fijo</p>
                            <p className='texto-resultado'>{telefonoFijo}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Correo electrónico</p>
                            <p className='texto-resultado'>{email}</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Row className='mt-3 pt-3'>
                <Col xs={6} className='mt-16'>
                    <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
                </Col>
                <Col xs={6} className='mt-16'>
                    <Button type="button" className="btn btn-primary w-100" onClick={envioFinal} >Enviar solicitud</Button>
                </Col>
            </Row>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
            dispatch(resetRFC());
        } 
	};
};

//export default ResumenSolicitud
export default connect(null, mapDispatchToProps)(ResumenSolicitud)