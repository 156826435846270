import { type as types } from "../../types";

const initialState = {isCatalgo: false, dataCatalogosSeguro: null};

export const CatalogosSeguroReducre = (state = initialState, action) => {

    const {type, payload } = action;

    switch (type) {
        
        case types.getCalogosSeguros:
            return {
                isCatalogo: true,
                dataCatalogosSeguro: payload
            };
        case types.getCalogosSegurosFail:
            return initialState;
        default:
            return state;
    }
}