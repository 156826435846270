import { type as types } from "../types/index";

const initialState = {isVersionVehiculo: false, version: null};

export const VersionVehiculoReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.getVersionVehiculo:
            const result = {
                ...state, 
                isVersionVehiculo: true,
                version: payload
            };
            return result;
        case types.getVersionVehiculoFail:
            return {
                ...state,
                isVersionVehiculo: false,
                version: null
            }
        case types.resetVersionVehiculo:
            return initialState;
        default:
            return state;
    }
}
