import {axiosInstance} from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const getCodigoPostal = (user, codigoPostal, securitykey) => {
    return axiosInstance.post('getPostalCode/', {
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "usuario": user,
        "canal": "1",
        "version": "2.0.0",
        "codigoPostal": codigoPostal,
        "llaveSeguridad": securitykey
    }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

export default { getCodigoPostal };