export const validExpresionRegular = {
    numeros: /^[0-9]+$/
}

export function validMontoMin(value, montoMin) {
    let respuesta = false;
    if(value !== "" && value !== undefined) {
        const expMonetario = /[0-9]+[.|,]*[0-9]*[.|,]*[0-9]*/;
        const valor = expMonetario.test(value);
        const reemplaza = value.replace(/[^0-9\\.]+/g,'');
        if(valor && (reemplaza === 0 || reemplaza >= montoMin)){
            respuesta = true;
        } 
    }
    return respuesta;
}

export function validMontoMax(value, montoMax) {
    let respuesta = false;
    if(value !== "" && value !== undefined) {
        const expMonetario = /[0-9]+[.|,]*[0-9]*[.|,]*[0-9]*/;
        const valor = expMonetario.test(value);
        const reemplaza = value.replace(/[^0-9\\.]+/g,'');
        //999999999
        if(valor && reemplaza <= montoMax){
            respuesta = true;
        } 
    }
    return respuesta;
}