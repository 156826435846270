import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { Card, Col, Container, Nav, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import ModalErrores from '../components/ModalErrores';
import { getSecurityKey } from '../helpers/SecurityKey';
import { obtencionSeguro } from '../actions/seguros/obtencionSeguros';
import { formatDate, logoImg, logoSeguro } from '../helpers/functionGlobal';

const DetalleSeguroScreen = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const securityKey = getSecurityKey();
    const { id } = useParams();
    //console.log(id);

    const loading = useSelector((state) => state.loading);
    const obtencionError = useSelector((state) => state.mensajeError);
    const { isError } = obtencionError;
    const { tipoError, mensajeError } = obtencionError.dataError;
    const dataUser = useSelector((state) => state.auth);
    const sucursalSelec = useSelector((state) => state.sucursalSelection);
    const { CveDistribuidor, Clave} = sucursalSelec.sucursalSeleccionada;
    const obtencionSolicitudSeguro = useSelector((state) => state.obtencionSolicitudSeguro);
    //console.log(obtencionSolicitudSeguro);
    const { isSolicitudSeguro, dataSolicitud } = obtencionSolicitudSeguro;
    console.log(obtencionSolicitudSeguro);
    //const { track } = dataSolicitud;
    

    useEffect(() => {
        dispatch(obtencionSeguro(dataUser.user.correo, securityKey, id, CveDistribuidor, Clave));
    }, []);

    const listadoHistorial = isSolicitudSeguro ? dataSolicitud.track.map((historial, index) => {
        const { created_str, message, idFaseStr, idFaseColor } = historial;
        let fechaFinal = created_str.split(" ");
        return (
            <li className={`item-proceso `}  key={index} >
                <div className='descripcion flex-shrink-1'>{message}</div>
                <div className='snippet-status flex-shrink-2' style={{background: idFaseColor}}>{idFaseStr}</div>
                <div className='fecha flex-shrink-3'>{fechaFinal[0]}</div>
            </li>
        );
    }) : "" ;

    return (
        <section className='seccion-detalle-solicitud'>
            <Container>
                <Row>
                    <Col xs={4} className='col-menu-lateral'>
                        <div className='link-inicio'>
                            <Nav.Link className="nav-link" onClick={() => navigate("/home")}><i className='icon-arrow-left me-2'></i>Inicio</Nav.Link>
                        </div>
                        <h1 className='texto-titulo'>Seguros</h1>
                        <ul className='opcion-menu'>
                            <li  >
                                <i className='icon-historial'></i>
                                <span className="nav-link" >Historial</span>
                                <span className='circle'></span>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={8} className="col-contenido" >
                        <div className='text-end mt-3'>
                            <img src={require('../assets/images/logos/atria-color.png')} alt="Atria" className='logo-atria-color'  />
                        </div>
                        <Card className='card-datos-solicitud'>
                            <Card.Body className='px-0'>
                                
                                <Row className='mt-2'>
                                    <Col xs={5}>
                                        <Card.Title>{dataSolicitud ? `${dataSolicitud.primerNombre} ${ dataSolicitud.segundoNombre !== null ? dataSolicitud.segundoNombre : "" } ${dataSolicitud.apellidoPaterno} ${dataSolicitud.apellidoMaterno !== null ? dataSolicitud.apellidoMaterno : ""}` : ""}</Card.Title>
                                    </Col>
                                    <Col xs={7}>
                                        <Card.Title className='text-end titulo-folio'>Folio: {dataSolicitud ? dataSolicitud.folio : "" }</Card.Title>
                                        <p className='mt-1 mb-0 text-end'>Solicitud creada el { (dataSolicitud && dataSolicitud.created !== null) && formatDate(dataSolicitud.created) }</p>
                                    </Col>
                                    {/*<Col xs={6}>
                                        <p className='m-0'>{dataSolicitud ? dataSolicitud.idFaseStr : "" }</p>
                                    </Col>*/}
                                    <Col xs={6} className='mb-3' >
                                        {
                                            (dataSolicitud && dataSolicitud.Aseguradora !== null && dataSolicitud.Aseguradora !== "" && dataSolicitud.Aseguradora !== "Null") &&
                                            <>
                                                <img src={logoImg(`./${logoSeguro(dataSolicitud.Aseguradora)}`)} className="logo-seguro mt-2" alt={dataSolicitud.Aseguradora}  />
                                            </>
                                        }
                                        
                                    </Col>

                                    <Col xs={6} className='text-end'>                                        {
                                            (dataSolicitud && dataSolicitud.get_poliza !== null) &&
                                            <p className='mt-1 mb-0'><a href={dataSolicitud.get_poliza} target='_blank' className='texto-liga liga-poliza' >Descargar póliza</a></p>
                                        }
                                    </Col>
                                    <Col xs={6} className='text-start'>
                                        {
                                            (dataSolicitud && dataSolicitud.auto !== null) &&
                                            <p className='mt-1 mb-0'>Auto: <b>{dataSolicitud.auto}</b></p>
                                        }
                                    </Col>
                                </Row>
                                
                            </Card.Body>
                        </Card>
                        <Card className='card-listado-proceso'>
                            <Card.Body>
                                <ul className='lista-procesos lista-procesos-seguros'>
                                    {listadoHistorial}
                                </ul>
                            </Card.Body>
                        </Card>
                        <Footer />
                    </Col>
                </Row>
                <Loading show={loading} />
                <ModalErrores show={isError} tipoError={tipoError} mensajeError={mensajeError} />
            </Container>
        </section>
    )
}

export default DetalleSeguroScreen