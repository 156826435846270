import {axiosInstance} from "../helpers/axios";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

const userAgent = window.navigator.userAgent;

const login = (user, password) => {
    return axiosInstance.post("login/", {
        "login": user,
        "password": password,
        "canal": "3",
        "version": "1.0",
        "force": "1",
        "userAgent": userAgent,
        "carrierCelular": userAgent
    })
    .then((res) => {
        const data = res.data;
        if(data.Codigo === 0) {
            data.correo = user;
            sessionStorage.setItem("user", JSON.stringify(data));
            UpdateSecurityKey(data.llaveSeguridad);
        }
        return data;
    })
    .catch(err => {
        console.log("error");
        //console.log(err.message);
        return "ERROR_SERVICIO";
    })
}

const logout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("menuSeleccion");
    sessionStorage.removeItem("securityKey");
};

export default { login, logout };