import { type as types } from "../../types";
import { dataSolicitudSeguro } from "../../models/DataSolicitudSeguro";

const initialState = dataSolicitudSeguro;

export const SolicitudSeguroReducer = (state = initialState, action) => {

    const {type, payload } = action;

    switch (type) {
        
        case types.addDataSolicitudSeguro:
            return {
                ...state, 
                ...payload
            };
        case types.resetSolicitudSeguro:
            return {
                ...initialState
            }
        default:
            return state;
    }
}