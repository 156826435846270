import React from 'react'
import { Button, Card, Col, Nav, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { FormatoCantidad, logoImg, logoSeguro } from '../../helpers/functionGlobal';
import { createInsuranceDocument, terminaCotizacionSeguros } from '../../actions/seguros/cotizadorSeguros';
import { getSecurityKey } from '../../helpers/SecurityKey';

const FormCotizadorResumenSeguro = (props) => {
    const { closeModal, iniciarSolicitud } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const securityKey = getSecurityKey();

    const obtencionCotizador = useSelector((state) => state.cotizadorSeguro);
    const { Modelo } = obtencionCotizador;
    const { MarcaText, ModeloText, IdDescripcionText, IdDescripcion } = obtencionCotizador.Auto;
    const { id_aseguradora, Aseguradora, IdCotizacionSeguro, MODELO, Inicial, PrimaNeta, PrimaTotal, codigo_postal, paquete, gastos_medicos, CP, coberturas } = obtencionCotizador.seguroSelect;
    const dataUser = useSelector((state) => state.auth);

    const obtencionSeguros = useSelector((state) => state.cotizaSeguro);
    const { isCotizaSeguro, cotizaSeguro} = obtencionSeguros;   
    
    const finalizacionCotizacion = () => {
        dispatch(terminaCotizacionSeguros(id_aseguradora, IdDescripcion, IdCotizacionSeguro, MODELO, Inicial, PrimaNeta, PrimaTotal, codigo_postal, Aseguradora, cotizaSeguro.id, MarcaText, ModeloText, IdDescripcionText));
        navigate("/solicitud-seguro")
    }


    const descargarCotización = () => {
        dispatch(createInsuranceDocument(dataUser.user.correo, securityKey, cotizaSeguro.id, MarcaText, ModeloText, IdDescripcionText)).then(
            (res) => {
                if(res.codigo === 0) {
                    //setDocumento(res.url);
                    window.open(res.url);
                }
            }
        );
    }

    return (
        <div className='seccion-resumen-solicitud'>
            
            <Row>
                {/*<Col>
                    <Card className='card-resumen-seguro'>
                        <Card.Body>
                            <Card.Title className='mb-3'>Datos aseguradora</Card.Title>
                            <Row>
                                <Col xs={6} className="mb-3">
                                    <p className='texto-resultado'>{Aseguradora}</p>
                                </Col>
                                <Col xs={6} className='mb-3' >
                                    <img src={logoImg(`./${logoSeguro(Aseguradora)}`)} className="ms-2 logo-seguro" alt={Aseguradora}  />
                                </Col>
                                <Col xs={8} className="mb-3">
                                    <p className='texto-label'>Precio final:</p>
                                    <p className='texto-resultado'>${FormatoCantidad(PrimaTotal, 2,".",",")}</p>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className='card-resumen-seguro'>
                        <Card.Body>
                            <Card.Title className='mb-3'>Plan anual</Card.Title>
                            <Row>
                                <Col xs={12} className="mb-3">
                                    <p className='texto-label'>Cobertura:</p>
                                    <p className='texto-resultado'>{paquete}</p>
                                </Col>
                                {
                                    coberturas.map((cobertura, index) => {
                                        const { text, sumaAsegurada } = cobertura;
                                        return(
                                            <Col xs={12} className="mb-3" key={index}>
                                                <p className='texto-label'>{text}:</p>
                                                <p className='texto-resultado'>{sumaAsegurada}</p>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>*/}
                <Col xs={12}>
                    
                    <Col xs={12} className="text-end mb-3" >
                        <Nav.Link  className='me-3' onClick={descargarCotización}>Descargar cotización</Nav.Link> 
                    </Col>
                    
                    <Card className='card-resumen-seguro'>
                        <Card.Body>
                            
                            <Card.Title className='mb-3'>Datos aseguradora</Card.Title>
                            <Row>
                                <Col xs={6} className="mb-3">
                                    <p className='texto-resultado'>{Aseguradora}</p>
                                </Col>
                                <Col xs={6} className='mb-3' >
                                    <img src={logoImg(`./${logoSeguro(Aseguradora)}`)} className="ms-2 logo-seguro" alt={Aseguradora}  />
                                </Col>
                                <Col xs={8} className="mb-3">
                                    <p className='texto-label'>Precio final:</p>
                                    <p className='texto-resultado'>${FormatoCantidad(PrimaTotal, 2,".",",")}</p>
                                </Col>
                            </Row>
                            <Card.Title className='mb-3'>Plan anual</Card.Title>
                            <Row>
                                <Col xs={12} className="mb-3">
                                    <p className='texto-label'>Cobertura:</p>
                                    <p className='texto-resultado'>{paquete}</p>
                                </Col>
                                <Col xs={12} >
                                    <Table responsive className='tabla-detalles'>
                                        <thead>
                                            <tr>
                                            <th>Cobertura</th>
                                            <th>Deducible</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                coberturas.map((cobertura, index) => {
                                                    const { text, sumaAsegurada } = cobertura;
                                                    return <tr key={index} >
                                                            <td>{text}</td>
                                                            <td>{sumaAsegurada}</td>
                                                            </tr>
                                                    
                                                    })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} className='mt-5 pt-4'>
                    <Card className='card-resumen-seguro'>
                        <Card.Body>
                            <Card.Title className='mb-3'>Datos del auto</Card.Title>
                            <Row>
                                <Col xs={4} className="mb-3">
                                    <p className='texto-label'>Marca:</p>
                                    <p className='texto-resultado'>{MarcaText}</p>
                                </Col>
                                <Col xs={4} className="mb-3">
                                    <p className='texto-label'>Año:</p>
                                    <p className='texto-resultado'>{Modelo}</p>
                                </Col>
                                <Col xs={4} className="mb-3">
                                    <p className='texto-label'>Modelo:</p>
                                    <p className='texto-resultado'>{ModeloText}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} className="mb-3">
                                    <p className='texto-label'>Versión del vehículo:</p>
                                    <p className='texto-resultado'>{IdDescripcionText}</p>
                                </Col>
                                <Col xs={4} className="mb-3">
                                    <p className='texto-label'>CP de la cotización:</p>
                                    <p className='texto-resultado'>{CP}</p>
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <Button type="submit" className="btn btn-primary w-100" onClick={() => { finalizacionCotizacion() }}>Llenar solicitud</Button>
                </Col>
            </Row>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => {},
        iniciarSolicitud: () => {}
	};
};
//export default FormCotizadorResumenSeguro
export default connect(null, mapDispatchToProps)(FormCotizadorResumenSeguro)