import { type } from "../types/index";
import PutCotizaService from "../services/put.cotiza";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { cotizadorPaso4, cotizadorPaso4Tradicional } from "./cotizador";
import { updateDataSolicitud } from "./solicitudes";

export const putCotiza =
  (
    Inicial,
    PagoMensual,
    Plazo,
    SeguroFinanciado,
    IdDescripcion,
    Modelo,
    CP,
    usuario,
    llaveSeguridad,
    IdCotizacionSeguro,
    ValorAuto,
    Enganche,
    Historial,
    Comprobantes,
    isTradicional,
    obtencionCotizador,
    Clave,
    CveDistribuidor,
    seguroSelect,
    clave_de_institucion, 
    Enganche_str, 
    Nombre, Perfil, 
    porcentajeEnganche, 
    enable, 
    Financiamiento, 
    Financiamiento_str, 
    Montos, 
    Pagomensual_str, 
    tasa_global, 
    ValorAuto_str,
    nombreInstitucion,
    engancheInic
  ) =>
  (dispatch) => {
    dispatch({ type: type.clearMessage });
    dispatch({ type: type.setLoading });
    let idCotizacion = null;
    const userAgent = window.navigator.userAgent;
    if (!isTradicional) {
      idCotizacion = IdCotizacionSeguro;
    }
    return PutCotizaService.getPutCotiza(
      Inicial,
      PagoMensual,
      Plazo,
      SeguroFinanciado,
      IdDescripcion,
      Modelo,
      CP,
      usuario,
      llaveSeguridad,
      idCotizacion,
      ValorAuto,
      Enganche,
      Historial,
      parseInt(Comprobantes),
      obtencionCotizador.Auto,
      seguroSelect,
      clave_de_institucion, 
      Enganche_str, 
      Nombre, Perfil, 
      porcentajeEnganche, 
      enable, 
      Financiamiento, 
      Financiamiento_str, 
      Montos, 
      Pagomensual_str, 
      tasa_global, 
      ValorAuto_str,
      Clave,
      CveDistribuidor,
      engancheInic
    ).then(
      (data) => {
        dispatch({ type: type.setLoading });
        const dataCotizador = {
          Inicial: Inicial,
          PagoMensual: PagoMensual,
          Plazo: Plazo,
          SeguroFinanciado: SeguroFinanciado,
        };
        if (data.Codigo === 0) {
        //if (data.IdCotizacionSeguro !== "") {
          dispatch({
            type: type.getPutCotiza,
            payload: data,
          });
          UpdateSecurityKey(data.llaveSeguridad);
          const { cotizacion, seguroSelect, Auto } = obtencionCotizador;
          const modeloCotizador = {
            CveDistribuidor: CveDistribuidor,
            Clave: Clave,
            userAgent: userAgent,
            carrierCelular: userAgent,
            Cotizacion: {
              Inicial: cotizacion.Inicial,
              PagoMensual: PagoMensual,
              Plazo: Plazo,
              SeguroFinanciado: SeguroFinanciado,
              id_aseguradora: seguroSelect.id_aseguradora,
            },
            Auto: {
              IdDescripcion: cotizacion.IdDescripcion,
              Modelo: cotizacion.Modelo,
              Marca: Auto.MarcaText,
              Version: Auto.IdDescripcionText,
              Submarca: Auto.ModeloText,
            },
            IdDescripcion: obtencionCotizador.Modelo,
            CP: obtencionCotizador.CP,
            Modelo: obtencionCotizador.Modelo,
            usuario: usuario,
            canal: "3",
            version: "2.0.0",
            llaveSeguridad: llaveSeguridad,
            IdCotizacionSeguro: seguroSelect.IdCotizacionSeguro,
            ValorAuto: obtencionCotizador.ValorAuto,
            Plazo: Plazo,
            Enganche: obtencionCotizador.Enganche,
            Historial: obtencionCotizador.Historial,
            Comprobantes: obtencionCotizador.Comprobantes,
          };
          const dataSolicitud = {
            mensaualidad: PagoMensual,
            plazo: Plazo,
            SeguroFinanciado: SeguroFinanciado,
            resultadoImporte: data.ImporteCredito,
            MontoSeguro: data.ImporteSeguro,
            Monthly_Amount: PagoMensual,
            Down_Payment: data.down_payment,
            Insurance_Amount: data.insurance_amount,
            comision_por_apertura: data.insurance_amount,
            modeloCotizador: JSON.stringify(modeloCotizador),
            idCotizacionSeguro: data.IdCotizacionSeguro,
            idCotizacion: data.idCotizacion,
            idCotizaciones: data.idCotizaciones,
            enganche: Enganche,
            partner: data.Cotizacion.Nombre,
            clave_de_institucion: data.Cotizacion.clave_de_institucion,
            Financiado: data.Cotizacion.SeguroFinanciado
          };

          dispatch(updateDataSolicitud(dataSolicitud));
          dispatch(cotizadorPaso4(dataCotizador));
          /*if(idCotizacion !== null) {
                    dispatch(cotizadorPaso4(dataCotizador));
                } else {
                    dispatch(cotizadorPaso4Tradicional(dataCotizador));
                }*/
        } else {
          dispatch({
            type: type.getPutCotizaFail,
          });
          dispatch({
            type: type.setError,
            payload: {
              tipoError: data.Codigo,
              mensajeError: data.Mensaje,
            },
          });
        }
        dispatch({
          type: type.textMessage,
          payload: data,
        });
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({ type: type.setLoading });
        dispatch({
          type: type.getPutCotizaFail,
        });
        dispatch({
          type: type.textMessage,
          payload: message,
        });
        dispatch({
          type: type.setError,
          payload: {
            tipoError: 0,
            mensajeError: message,
          },
        });
        return Promise.reject(error);
      }
    );
  };
