import { type as types } from "../types/index";

const initialState = {isModelo: false, modelos: null};

export const ModelosReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.getModelos:
            const result = {
                ...state, 
                isModelo: true,
                modelos: payload
            };
            return result;
        case types.getModelosFail:
            return {
                ...state,
                isModelo: false,
                modelos: null
            }
        case types.resetModelos:
            return initialState
        default:
            return state;
    }
}
