import {axiosInstance} from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const getSolicitudes = (usuario, distribuidor, sucursal, vendedor, securitykey) => {
    return axiosInstance.post('getSolicitudes/', {
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "usuario": usuario,
        "canal": "3",
        "version": "2.0.0",
        "distribuidor": distribuidor,
        "sucursal": sucursal,
        "vendedor": vendedor,
        "llaveSeguridad": securitykey
    }).then((res) => {
        const data = res.data;
        return data;
    })
    .catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

const getHistorialEstatus = (usuario, securitykey, idSolicitud) => {
    return axiosInstance.post('getHistorialEstatus/', {
        "canal": "3",
        "version": "2.0.0",
        "carrierCelular": userAgent,
        "userAgent": userAgent,
        "idSolicitud": idSolicitud,
        "ip_dispositivo": "0.0.0.0",
        "latitud": "19.414179",
        "longitud": "-100.391864",
        "usuario": usuario,
        "llaveSeguridad": securitykey
    }).then((res) => {
        const data = res.data;
        return data;
    })
    .catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

const getHistorialEstatusFondeo = (usuario, securitykey, idSolicitud) => {
    return axiosInstance.post('getHistorialFondeo/', {
        "canal": "3",
        "version": "2.0.0",
        "carrierCelular": userAgent,
        "userAgent": userAgent,
        "idSolicitud": idSolicitud,
        "ip_dispositivo": "0.0.0.0",
        "latitud": "19.414179",
        "longitud": "-100.391864",
        "usuario": usuario,
        "llaveSeguridad": securitykey
    }).then((res) => {
        const data = res.data;
        return data;
    })
    .catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

export default { getSolicitudes, getHistorialEstatus, getHistorialEstatusFondeo };