import React, {useEffect, useState }  from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { getSecurityKey } from '../../helpers/SecurityKey';

import { marcasSeguros } from '../../actions/marcas';
import { hideAlert, showAlert } from '../../actions/alertMessage';
import { modelosSeguro } from '../../actions/modelos';
import { aniosSeguro } from '../../actions/anios';
import { versionVehiculoSeguro } from '../../actions/versionVehiculo';
import { codigoPostal } from '../../actions/codigoPostal';
import { MensajesAlertas } from '../../models/MensajesAlertas';
import { getSeguros } from '../../actions/seguros/getSeguros';
import { resetCotizadorSeguro } from '../../actions/seguros/cotizadorSeguros';


const FormDatosAutoSeguro = (props) => {

    const { closeModal, clearCotizador } = props;

    const [textoVersionVehiculo, setTextoVersionVehiculo] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dataUser = useSelector((state) => state.auth);
    const securityKey = getSecurityKey();
    
    const obtencionMarcas = useSelector((state) => state.marcas);
    const { marcas: getMarcas, isMarcas} = obtencionMarcas;

    const obtencionModelos = useSelector((state) => state.modelos);
    const obtencionAnios = useSelector((state) => state.anios);
    const {isAnio, anios: allAnios } = obtencionAnios;
    const obtencionVersionVehiculo = useSelector((state) => state.versionVehiculo);
    const { isVersionVehiculo, version: allVersionVehiculo } = obtencionVersionVehiculo;
    const obtencionCodigoPostal = useSelector((state) => state.codigoPostal);
    const { isCodigoPostal, dataCodigoPostal } = obtencionCodigoPostal;

    const obtencionCotizador = useSelector((state) => state.cotizadorSeguro);
    const { CP, Auto, Modelo, IdDescripcion, IdDescripcionText } = obtencionCotizador;

    const [combos, setCombos] = useState({
        marca: Auto.Marca,
        marcaText: Auto.Marca,
        modelo: Auto.Modelo,
        modeloText: Auto.Modelo,
        anio: Modelo,
        anioText: Modelo,
        version: IdDescripcion,
        versionText: IdDescripcionText
    });

    useEffect(() => {
        const securityKey = sessionStorage.getItem("securityKey");
        obtencionCotizador.CP === "" ?
            dispatch(marcasSeguros(dataUser.user.correo, securityKey))
        :
            setValue("marca", Auto.Marca);
            setValue("marcaText", Auto.MarcaText);
            setValue("modelo", Auto.Modelo);
            setValue("modeloText", Auto.ModeloText);
            setValue("anio", Modelo);
            setValue("anioText", Modelo);
            setValue("version", IdDescripcion);
            setValue("versionText", IdDescripcionText);
            setValue("codigoPostal", CP);
    }, [dispatch, dataUser]);
    const [stateModelo, setModelo] = useState(Auto.Modelo);

    const getModelos = (e) => {
        const idMarca = e.target.value;
        const securityKey = sessionStorage.getItem("securityKey");
        dispatch(modelosSeguro(dataUser.user.correo, idMarca, securityKey));
    }

    const getAnios = (e) => {
        const idModelo = e.target.value;
        setModelo(idModelo);
        dispatch(aniosSeguro(dataUser.user.correo, idModelo, securityKey));
    }

    const getVersionVehiculo = (e) => {
        const anio = parseInt(e.target.value);
        dispatch(versionVehiculoSeguro(dataUser.user.correo, stateModelo, anio, combos.marca, securityKey));
    }

    const getCodigoPostal = (e) => {
        const cp = e.target.value;
        if(cp !== "") {
            dispatch(codigoPostal(dataUser.user.correo, String(cp), securityKey));
        }
    }

    const optionMarcas = isMarcas ? getMarcas.map((marca, index) =>{
        return <option key={index} value={marca.value} >{marca.label}</option>
    }) : "";

    const optionModelos = obtencionModelos.isModelo ? obtencionModelos.modelos.Tipo.map((modelo, index) =>{
        return <option key={index} value={modelo.value} >{modelo.label}</option>
    }) : "";

    const optionAnios = isAnio ? allAnios.Modelos.map((modelo, index) =>{
        return <option key={index} value={modelo.value} >{modelo.label}</option>
    }) : "";

    const optionVersionVehiculo = isVersionVehiculo ? allVersionVehiculo.Descripcion.map((versionVehiculo, index) =>{
        return <option key={index} value={versionVehiculo.value} >{versionVehiculo.label}</option>
    }) : "";

    const validateAuto = Yup.object({
        marca: Yup.string().required('El campo es requerido'),
        modelo: Yup.string().required('El campo es requerido'),
        anio: Yup.string().required('El campo es requerido'),
        version: Yup.string().required('El campo es requerido')
    });
    
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validateAuto),
        mode: "onChange"
    });

    const onSubmit = (data) => {
        const {codigoPostal, anio, version} = data;
        //dispatch(cotizadorPaso1(data,combos));
        dispatch(getSeguros(version, codigoPostal, anio, dataUser.user.correo, securityKey, data,combos))
        .then((res) => {
            if(res.codigo === 0 || res.codigo === 1) {
                if(res.CotizaSeguro.length === 0) {
                    dispatch(showAlert(MensajesAlertas.mensajeNoHaySeguros, 
                        () => { 
                            /*Si closeModal();*/
                            closeModal();
                            navigate("/solicitud-seguro");
                        }, 
                        () => { /*No*/closeModal(); }  ));
                }
            }
        });
    }

    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionCotizacion, () => { closeModal(); clearCotizador(); navigate("/home"); }, () => { closeModal(); }  ));
    }

    const llenadoCombo = (e,index) => {
        //console.log("llenadoCombo",e.target)
        const { name, value } = e.target;
        const text = e.target.options[index].text;
        if(name === "marca") {
            const resetState = {
                modelo: "",
                modeloText: "",
                anio: "",
                anioText: "",
                version: "",
                versionText: ""
            }
            setCombos({
                ...resetState,
                [name]:value,
                [name+"Text"]:text
            });
        } else {
            const comboSelec = {
                ...combos,
                [name]:value,
                [name+"Text"]:text
            };
            setCombos(comboSelec);
        }
    }
    return (
        <Card className='card-formulario'>
            <Card.Body className='p-0'>
                <Form className='formulario-datos-auto' id='frmDatosAuto' onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="Marca">Marca</Form.Label>
                            <Form.Select 
                                name="marca" 
                                className={`${errors.marca ? 'is-invalid' : ""} ${combos.marca ? 'custom-select' : ''} `} 
                                {...register("marca", {
                                    onChange: (e) => {
                                        getModelos(e);
                                        let index = e.target.selectedIndex;
                                        llenadoCombo(e,index);
                                    },
                                })}
                            >
                                <option value="">Selecciona la marca del auto</option>
                                {optionMarcas}
                            </Form.Select>
                            <p className='texto-error'>{errors.marca?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="modelo">Modelo</Form.Label>
                            <Form.Select 
                                name="modelo" 
                                className={`${errors.modelo ? 'is-invalid' : ""} ${combos.modelo ? 'custom-select' : ''}`} 
                                {...register("modelo", {
                                    onChange: (e) => {
                                        let index = e.target.selectedIndex;
                                        getAnios(e);
                                        llenadoCombo(e,index);
                                    },
                                })}
                                >
                                <option value="">Modelo del auto</option>
                                {optionModelos}
                            </Form.Select>
                            <p className='texto-error'>{errors.modelo?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="anio">Año</Form.Label>
                            <Form.Select 
                                name="anio" 
                                className={`${errors.anio ? 'is-invalid' : ""} ${combos.anio ? 'custom-select' : ''}`}
                                {...register("anio", {
                                    onChange: (e) => {
                                        let index = e.target.selectedIndex;
                                        getVersionVehiculo(e);
                                        llenadoCombo(e,index);
                                    },
                                })} 
                            >
                                <option value="">Año del auto</option>
                                {optionAnios}
                            </Form.Select>
                            <p className='texto-error'>{errors.anio?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label htmlFor="version">Versión del vehículo</Form.Label>
                            <Form.Select 
                                name="version" 
                                className={`${errors.version ? 'is-invalid' : ""} ${combos.version ? 'custom-select' : ''}`} 
                                {...register("version", {
                                    onChange: (e) => {
                                        let index = e.target.selectedIndex;
                                        setTextoVersionVehiculo(e.target.options[index].text);
                                        llenadoCombo(e,index);
                                    }
                                })} 
                            >
                                <option value="">Selecciona la versión del vehículo</option>
                                {optionVersionVehiculo}
                            </Form.Select>
                            <p className='texto-error'>{errors.version?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Código postal</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="codigoPostal" 
                                placeholder="Escribe el Código postal"
                                maxLength={5}
                                autoComplete="off"
                                className={`${errors.codigoPostal ? 'is-invalid' : ""}`}
                                {...register("codigoPostal")}
                                onBlur={getCodigoPostal}
                            />
                            <p className='texto-error'>{errors.codigoPostal?.message}</p>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                            <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                            <Button type="submit" className="btn btn-primary w-100" disabled={ (isCodigoPostal) ? false : true } >Siguiente</Button>
                        </Form.Group>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}
const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizadorSeguro());
        } 
	};
};
export default connect(null, mapDispatchToProps)(FormDatosAutoSeguro)