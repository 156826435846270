import React, { useEffect, useRef } from 'react';
import { Card} from 'react-bootstrap';
import ProgressBar from 'react-customizable-progressbar';

const CardSemiRound = ({data}) => {

    const { title, strokeColor, trackStrokeColor, tooltipText, dataProgressBar, textMuted, percentage } = data;
    const { label, value1, label2, value2 } = dataProgressBar;

    const ref = useRef(null);

    useEffect(() => {
        const divEl = document.querySelector('.RCP__progress');
        const divTooltip = document.getElementById("tooltipOne");
        const divRCP = document.querySelector('.RCP');
        divEl.addEventListener('mouseenter', 
            (event) => {
                let clienteRect = divRCP.getBoundingClientRect();
                let y = event.clientY;
                let x = event.clientX
                divTooltip.style.top = (y - clienteRect.top + 10)+"px";
                divTooltip.style.left = (x - clienteRect.left + 10)+"px";
                if (divTooltip.style.display === "none") {
                    divTooltip.style.display = "block";
                }
            }
        );
        divEl.addEventListener('mouseout', (event) => {
            divTooltip.style.display = "none";
        })
    }, [])
    

    return (
        <Card className="card-gris card-semi-round">
            <Card.Body>
                <div ref={ref}>
                    <Card.Title>{title}</Card.Title>
                    <ProgressBar 
                    radius={100}
                    progress={percentage}
                    cut={120}
                    rotate={-210}
                    strokeWidth={16}
                    strokeColor={strokeColor}
                    strokeLinecap="round"
                    trackStrokeWidth={16}
                    trackStrokeColor={trackStrokeColor}
                    trackStrokeLinecap="round"
                    pointerRadius={0}
                    initialAnimation={true}
                    transition="1.5s ease 0.5s"
                    trackTransition="0s ease"
                    className="mx-auto"
                    >
                        <div className='datos-progress-bar'>
                            <i className='icon-vomito'></i>
                            <p className='label'>{label}</p>
                            <p className='texto-ganancia'>{value1}</p>
                            <p className='label'>{label2}</p>
                            <p className='texto-ganar'>{value2}</p>
                        </div>
                        <div className="tooltip-one" style={{display: 'none'}} id='tooltipOne'>{tooltipText}</div>
                    </ProgressBar>
                </div>
                <small className="card-text-mini">{textMuted}</small>
            </Card.Body>
        </Card>
    )
}

export default CardSemiRound