import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import FormDatosDomicilio from './FormDatosDomicilio';
import FormDatosContacto from './FormDatosContacto';
import { getSecurityKey } from '../helpers/SecurityKey';
import { getCatalogos } from '../actions/catalogos';
import { getRFC, resetRFC } from '../actions/rfc';
import { formatDate, revertDate, FormatoCantidad } from '../helpers/functionGlobal';
import { updateSaveSolicitud } from '../actions/saveSolicitud';
import { validExpresionRegular } from '../helpers/methodValidate';
import { resetCotizador } from '../actions/cotizador';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { resetSolicitud } from '../actions/solicitudes';
import { updatePartialSolicitudPersonal } from '../actions/updatePartialSolicitud';

registerLocale('es', es);

const FormDatosPersonales = (props) => {

    const date = new Date();
    date.setFullYear(date.getFullYear() - 21);

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 69);

    const { closeModal, clearCotizador } = props;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(date);
    const [switchDate, setSwitchDate] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const securityKey = getSecurityKey();

    const obtencionCatalogos = useSelector((state) => state.catalogos);
    const { isCatalogo, catalogos } = obtencionCatalogos;
    const dataUser = useSelector((state) => state.auth);
    
    const obtencionSolicitud = useSelector((state) => state.solicitudes);
    const { mensaualidad, plazo, nombre, segundoNombre, apellidoPaterno, apellidoMaterno, genero, estado_civil,
        fechaNacimiento, dependientes_economicos, rfc, nivel_de_estudios, cuenta_con_vehiculo,
        calle, noExterior, noInterior, codigoPostal: cp, tipoVivienda, municipio, estado, aniosDomicilio,
        mesesDomicilio, telefonoMovil, telefonoFijo, email, coloniaStr, colonia, etapaProceso, seller
    } = obtencionSolicitud.dataSolicitud;
    const obtenerRFC = useSelector((state) => state.rfc);
    const { RFC_10_D, homoclave } = obtenerRFC.dataRFC;

    const sucursalSelec = useSelector((state) => state.sucursalSelection);

    const [combos, setCombos] = useState({
        genero: genero, estadoCivil: estado_civil, dependientesEconomicos: dependientes_economicos,
        nivelEstudios: nivel_de_estudios, vehiculosCuenta: cuenta_con_vehiculo, vendedor: seller
    });

    useEffect(() => {
        setValue("mesesDomicilio", mesesDomicilio);
        dispatch(getCatalogos(dataUser.user.correo, securityKey)).
        then((res) => {
            if(res.codigo === 0) {
                
                if(nombre !== "" && nombre !== null) {
                    setValue("primerNombre", nombre);
                    setValue("segundoNombre", segundoNombre);
                    setValue("primerApellido", apellidoPaterno);
                    setValue("segundoApellido", apellidoMaterno);
                    setValue("genero", genero);
                    setValue("estadoCivil", estado_civil);
                    setValue("fechaNacimiento", revertDate(fechaNacimiento));
                    setValue("dependientesEconomicos", dependientes_economicos);
                    setValue("rfc", rfc.substring(0, rfc.length - 3));
                    setValue("homoclave", rfc.slice(-3));
                    setValue("nivelEstudios", nivel_de_estudios);
                    setValue("vehiculosCuenta", cuenta_con_vehiculo);
                    setValue("calle", calle);
                    setValue("noExterior", noExterior);
                    setValue("noInterior", noInterior);
                    setValue("codigoPostal", cp);
                    setTimeout(() => { setValue("vivienda", tipoVivienda); }, 900);
                    setValue("alcaldia", municipio);
                    setValue("estado", estado);
                    setValue("aniosDomicilio", aniosDomicilio);
                    setValue("mesesDomicilio", mesesDomicilio);
                    setValue("numeroCelular", telefonoMovil);
                    setValue("confirmarCelular", telefonoMovil);
                    setValue("telefonoFijo", telefonoFijo);
                    setValue("correoElectronico", email);
                    if(colonia === "-1") {
                        setValue("existeColonia", true);
                        setValue("anadeColonia", coloniaStr);
                    } else {
                        setTimeout(() => {
                            setValue("colonia", coloniaStr);
                        }, 1000);
                    }
                    if(sucursalSelec.sucursalSeleccionada.Vendedores.length === 1) {
                        setValue("vendedor", sucursalSelec.sucursalSeleccionada.Vendedores[0].id);
                    } else {
                        if(combos.vendedor !== null && combos.vendedor !== "") {
                            setValue("vendedor", combos.vendedor);
                        }
                    }
                }
            }
        })

    }, []);


    /* Llenado de combos */
    const optionGenero = isCatalogo ? catalogos.AdditionalDataCatalog[0].options.map((genero, index) => {
        return <option key={index} value={genero.value}>{genero.title}</option>
    }) : "";

    const optionEstadoCivil = isCatalogo ? catalogos.AdditionalDataCatalog[1].options.map((estadoCivil, index) => {
        return <option key={index} value={estadoCivil.value}>{estadoCivil.title}</option>
    }) : "";

    const optionNivelEstudio = isCatalogo ? catalogos.AdditionalDataCatalog[2].options.map((nivelEstudio, index) => {
        return <option key={index} value={nivelEstudio.value}>{nivelEstudio.title}</option>
    }) : "";

    const optionDependientes =  isCatalogo ? catalogos.AdditionalDataCatalog[3].options.map((dependiente, index) => {
        return <option key={index} value={dependiente.value}>{dependiente.title}</option>
    }) : "";

    const optionCuentaVehiculo =  isCatalogo ? catalogos.AdditionalDataCatalog[4].options.map((cuentaVehiculo, index) => {
        return <option key={index} value={cuentaVehiculo.value}>{cuentaVehiculo.title}</option>
    }) : "";

    const optionVendedores =  sucursalSelec ? sucursalSelec.sucursalSeleccionada.Vendedores.map((vendedor, index) => {
        return <option key={index} value={vendedor.id}>{vendedor.nomberCompleto}</option>
    }) : "";

    const validateAuto = Yup.object({
        vendedor: Yup.string().required('El campo es requerido'),
        primerNombre: Yup.string().required('El campo es requerido'),
        primerApellido: Yup.string().required('El campo es requerido'),
        genero: Yup.string().required('El campo es requerido'),
        estadoCivil: Yup.string().required('El campo es requerido'),
        fechaNacimiento: Yup.string().required('El campo es requerido'),
        dependientesEconomicos: Yup.string().required('El campo es requerido'),
        rfc: Yup.string().required('El campo es requerido'),
        homoclave: Yup.string().required('El campo es requerido'),
        nivelEstudios: Yup.string().required('El campo es requerido'),
        vehiculosCuenta: Yup.string().required('El campo es requerido'),
        calle: Yup.string().required('El campo es requerido'),
        noExterior: Yup.string().required('El campo es requerido'),
        codigoPostal: Yup.string().required('El campo es requerido').matches(validExpresionRegular.numeros, "Introduzca solo números").min(5, 'Introduzca al menos ${min} caracteres'),
        vivienda: Yup.string().required('El campo es requerido'),
        colonia: Yup.string().when('existeColonia', {
            is: false,
            then: Yup
                .string()
                .required('El campo es requerido')
        }),
        existeColonia: Yup.boolean(),
        anadeColonia: Yup.string().when('existeColonia', {
            is: true,
            then: Yup
                .string()
                .required('El campo es requerido')
        }),
        alcaldia: Yup.string().required('El campo es requerido'),
        estado: Yup.string().required('El campo es requerido'),
        aniosDomicilio: Yup.string().required('El campo es requerido').matches(validExpresionRegular.numeros, "Introduzca solo números"),
        mesesDomicilio: Yup.string().required('El campo es requerido'),
        numeroCelular: Yup.string().required('El campo es requerido').matches(validExpresionRegular.numeros, "Introduzca solo números").min(10, 'Introduzca al menos ${min} caracteres'),
        confirmarCelular: Yup.string().oneOf([Yup.ref('numeroCelular'), null], 'El campo es diferente del celular'),
        telefonoFijo: Yup.string().required('El campo es requerido').matches(validExpresionRegular.numeros, "Introduzca solo números").min(10, 'Introduzca al menos ${min} caracteres'),
        correoElectronico: Yup.string().required('El campo es requerido').email('Ingrese un email válido')
    });

    const { register, handleSubmit, formState: { errors }, control, getFieldState, getValues, setValue } = useForm({
        resolver: yupResolver(validateAuto),
        mode: "onChange" 
    });
    const onSubmit = (data) => {
        etapaProceso > 1 ?
            dispatch(updatePartialSolicitudPersonal(data, dataUser.user.correo, sucursalSelec, securityKey, obtencionSolicitud))
        :
            dispatch(updateSaveSolicitud(data, dataUser.user.correo, sucursalSelec, securityKey, obtencionSolicitud))
    };
    
    useEffect(() => {
        setValue("rfc", RFC_10_D);
        setValue("homoclave", homoclave);
    }, [RFC_10_D, homoclave]);

    const obtencionRFC = () => {
        const primerNombre = getFieldState("primerNombre").error,
        primerApellido = getFieldState("primerApellido").error,
        genero = getFieldState("genero").error,
        estadoCivil = getFieldState("estadoCivil").error;
        //fechaNacimiento = getFieldState("fechaNacimiento").error

        const primerNombreVal = getValues("primerNombre"),
        segundoNombreVal = getValues("segundoNombre"),
        primerApellidoVal = getValues("primerApellido"),
        segundoApellidoVal = getValues("segundoApellido"),
        generoVal = getValues("genero"),
        estadoCivilVal = getValues("estadoCivil"),
        fechaNacimientoVal = getValues("fechaNacimiento");

        const nombres = primerNombreVal+" "+segundoNombreVal;
        if(primerNombre === undefined && primerNombreVal!=="" && primerApellido === undefined && primerApellidoVal !=="" && 
        genero === undefined && generoVal!=="" && estadoCivil === undefined && estadoCivilVal!=="" && 
        (fechaNacimientoVal!=="" && fechaNacimientoVal !== undefined && fechaNacimientoVal !== "Invalid Date")) {
            dispatch(getRFC(nombres, primerApellidoVal, segundoApellidoVal, dataUser.user.correo, formatDate(fechaNacimientoVal), securityKey))
        }
    }
    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionCotizacion, () => { closeModal(); clearCotizador(); navigate("/home"); }, () => { closeModal(); }  ));
    }

    const llenadoCombo = (e) => {
        const { name, value } = e.target;
        const comboSelec = {
            ...combos,
            [name]:value
        };
        setCombos(comboSelec);
    }
    return (
        <>
            <Card className='card-informacion-plan'>
                <Card.Body className='text-center'>
                    <Card.Title className='d-flex justify-content-center'><i className='icon-documentos me-3'></i>Información del plan</Card.Title>
                    <Card.Subtitle className='tipo-pago'>Mensualidad</Card.Subtitle>
                    <Card.Text className='monto'>${FormatoCantidad(mensaualidad, 2, '.', ',' )}</Card.Text>
                    <Card.Text className='meses'>a {plazo} meses</Card.Text>
                </Card.Body>
            </Card>
            <Form className='formulario-usuario' onSubmit={handleSubmit(onSubmit)}>
                <Card className='card-formulario'>
                    <Card.Title><i className='icon-documentos me-3'></i>Vendedor</Card.Title>
                    <Card.Body className='p-0'>
                        <Row>
                            <Form.Group as={Col} xs="12" className='mb-3' >
                                <Form.Label htmlFor="vendedor">Vendedor</Form.Label>
                                <Form.Select 
                                    name="vendedor"
                                    className={`${errors.vendedor ? 'is-invalid' : ""} ${combos.vendedor ? 'custom-select' : ''}`}
                                    {...register("vendedor", {
                                        onChange: (e) => {
                                            llenadoCombo(e);
                                        }
                                    })} 
                                >
                                    <option value="">Selecciona un vendedor</option>
                                    {optionVendedores}
                                </Form.Select>
                                <p className='texto-error'>{errors.vendedor?.message}</p>
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='card-formulario'>
                    <Card.Title><i className='icon-user me-3'></i>Datos personales</Card.Title>
                    <Card.Body className='p-0'>
                        <Row>
                            <Form.Group as={Col} xs="12" className='mb-3'>
                                <Form.Label>Primer nombre</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="primerNombre" 
                                    placeholder="Escribe el primer nombre"
                                    maxLength={100}
                                    autoComplete="off"
                                    className={`${errors.primerNombre ? 'is-invalid' : ""}`}
                                    {...register("primerNombre")}
                                    onBlur={() => { obtencionRFC() }}
                                />
                                <p className='texto-error'>{errors.primerNombre?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="12" className='mb-3'>
                                <Form.Label>Segundo nombre (opcional)</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="segundoNombre" 
                                    placeholder="En caso de existir, escribe el segundo nombre"
                                    maxLength={100}
                                    autoComplete="off"
                                    className={`${errors.segundoNombre ? 'is-invalid' : ""}`}
                                    {...register("segundoNombre")}
                                    onBlur={() => { obtencionRFC() }}
                                />
                                <p className='texto-error'>{errors.segundoNombre?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="12" className='mb-3'>
                                <Form.Label>Primer apellido</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="primerApellido" 
                                    placeholder="Escribe el primer apellido"
                                    maxLength={100}
                                    autoComplete="off"
                                    className={`${errors.primerApellido ? 'is-invalid' : ""}`}
                                    {...register("primerApellido")}
                                    onBlur={() => { obtencionRFC() }}
                                />
                                <p className='texto-error'>{errors.primerApellido?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="12" className='mb-3'>
                                <Form.Label>Segundo apellido (opcional)</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="segundoApellido" 
                                    placeholder="Escribe el segundo apellido"
                                    maxLength={100}
                                    autoComplete="off"
                                    className={`${errors.segundoApellido ? 'is-invalid' : ""}`}
                                    {...register("segundoApellido")}
                                    onBlur={() => { obtencionRFC() }}
                                />
                                <p className='texto-error'>{errors.segundoApellido?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="4" className='mb-3' >
                                <Form.Label htmlFor="genero">Género</Form.Label>
                                <Form.Select 
                                    name="genero"
                                    className={`${errors.genero ? 'is-invalid' : ""} ${combos.genero ? 'custom-select' : ''}`}
                                    {...register("genero", {
                                        onChange: (e) => {
                                            obtencionRFC();
                                            llenadoCombo(e);
                                        }
                                    })} 
                                >
                                    <option value="">Selecciona un género</option>
                                    {optionGenero}
                                </Form.Select>
                                <p className='texto-error'>{errors.genero?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="4" className='mb-3' >
                                <Form.Label htmlFor="estadoCivil">Estado civil</Form.Label>
                                <Form.Select 
                                    name="estadoCivil"
                                    className={`${errors.estadoCivil ? 'is-invalid' : ""} ${combos.estadoCivil ? 'custom-select' : ''} `}
                                    {...register("estadoCivil", {
                                        onChange: (e) => {
                                            obtencionRFC();
                                            llenadoCombo(e);
                                        }
                                    })} 
                                >
                                    <option value="">Selecciona una opción</option>
                                    {optionEstadoCivil}
                                </Form.Select>
                                <p className='texto-error'>{errors.estadoCivil?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="4" className='mb-3'>
                                <Form.Label>Fecha de nacimiento</Form.Label>
                                <Controller
                                    control={control}
                                    name='fechaNacimiento'
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                    <div className='input-calendario'>
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={(fechaNacimiento !== "" && !switchDate) ? revertDate(fechaNacimiento) : startDate}
                                            maxDate={endDate}
                                            minDate={minDate}
                                            locale="es"
                                            name = {field.name}
                                            value= {startDate}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete="off"
                                            placeholderText={formatDate(date)}
                                            className={`form-control ${errors.fechaNacimiento ? 'is-invalid' : ""}`}
                                            onChange={(date) => {
                                                field.onChange(date); 
                                                setStartDate(date);
                                                setSwitchDate(true);
                                                obtencionRFC() ;
                                            }}
                                            onKeyDown={(e) => {
                                                e.preventDefault();
                                            }}
                                        />
                                        <i className='icon-calendario'></i>
                                    </div>
                                )}
                                />
                                <p className='texto-error'>{errors.fechaNacimiento?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="4" className='mb-3' >
                                <Form.Label htmlFor="dependientesEconomicos">Dependientes económicos</Form.Label>
                                <Form.Select 
                                    name="dependientesEconomicos" 
                                    className={`${errors.dependientesEconomicos ? 'is-invalid' : ""} ${combos.dependientesEconomicos ? 'custom-select' : ''}`}
                                    {...register("dependientesEconomicos", {
                                        onChange: (e) => {
                                            llenadoCombo(e);
                                        }
                                    })}
                                >
                                    <option value="">Selecciona dependientes económicos</option>
                                    {optionDependientes}
                                </Form.Select>
                                <p className='texto-error'>{errors.dependientesEconomicos?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="4" className='mb-3'>
                                <Form.Label>RFC</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="rfc" 
                                    placeholder="Escribe el RFC"
                                    maxLength={100}
                                    autoComplete="off"
                                    className={`${errors.rfc ? 'is-invalid' : ""}`}
                                    {...register("rfc")}
                                />
                                <p className='texto-error'>{errors.rfc?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="4" className='mb-3'>
                                <Form.Label>Homoclave</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="homoclave" 
                                    placeholder="3 últimos dígitos"
                                    maxLength={100}
                                    autoComplete="off"
                                    className={`${errors.homoclave ? 'is-invalid' : ""}`}
                                    {...register("homoclave")}
                                />
                                <p className='texto-error'>{errors.homoclave?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="6" className='mb-3' >
                                <Form.Label htmlFor="nivelEstudios">Nivel de estudios</Form.Label>
                                <Form.Select 
                                    name="nivelEstudios" 
                                    className={`${errors.nivelEstudios ? 'is-invalid' : ""} ${combos.nivelEstudios ? 'custom-select' : ''}`}
                                    {...register("nivelEstudios", {
                                        onChange: (e) => {
                                            llenadoCombo(e);
                                        }
                                    })} 
                                >
                                    <option value="">Selecciona un nivel de estudios</option>
                                    {optionNivelEstudio}
                                </Form.Select>
                                <p className='texto-error'>{errors.nivelEstudios?.message}</p>
                            </Form.Group>

                            <Form.Group as={Col} xs="6" className='mb-3' >
                                <Form.Label htmlFor="vehiculosCuenta">Vehículos con los que cuenta</Form.Label>
                                <Form.Select 
                                    name="vehiculosCuenta" 
                                    className={`${errors.vehiculosCuenta ? 'is-invalid' : ""} ${combos.vehiculosCuenta ? 'custom-select' : ''}`}
                                    {...register("vehiculosCuenta", {
                                        onChange: (e) => {
                                            llenadoCombo(e);
                                        }
                                    })} 
                                >
                                    <option value="">Selecciona una cantidad</option>
                                    {optionCuentaVehiculo}
                                </Form.Select>
                                <p className='texto-error'>{errors.vehiculosCuenta?.message}</p>
                            </Form.Group>
                        </Row>
                    </Card.Body>
                </Card>
                
                <hr />
                <FormDatosDomicilio register={register} errors={errors} setValue={setValue} control={control} />
                <hr />
                <FormDatosContacto register={register} errors={errors} />

                <Row className='mt-5'>
                    <Col xs={6} className='px-2'>
                        <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
                    </Col>
                    <Col xs={6} className='px-2'>
                        <Button type="submit" className="btn btn-primary w-100" >Siguiente</Button>
                    </Col>
                </Row>
            </Form>

        </>
    )
}
const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetRFC());
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
        } 
	};
};

//export default FormDatosPersonales
export default connect(null, mapDispatchToProps)(FormDatosPersonales)