import { type as types } from "../types/index";
import { DataDashboard } from "../models/DataDashboard";

const initialState = {isDashboard: false, dataDashboard: DataDashboard };

export const DashboardReducer = (state = initialState, action) => {

    const {type, payload } = action;

    switch (type) {

        case types.getDashboard:
            const result = {
                isDashboard: true,
                dataDashboard: payload
            };
            return result;
        case types.getDashboardFail:
            return {
                ...state,
                isDashboard: false
            }
        case types.updateImageProfile:
            return {
                isDashboard: true,
                dataDashboard: {
                    ...state.dataDashboard,
                    profilePicture: payload
                }
            };
        default:
            return state;
    }
}
