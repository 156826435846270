import { type } from "../types/index";
import SendSMSService from "../services/send.sms.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { updateDataSolicitud } from "./solicitudes";

export const sendSMS = (usuario, securitykey, idSolicitud, sucursal, claveSucursal, mensajeClave, telefono) => (dispatch) => {
    
    const userAgent = window.navigator.userAgent;
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        idSolicitud: idSolicitud,
        sucursal: sucursal,
        usuario: usuario,
        claveSucursal: claveSucursal,
        mensajeClave: mensajeClave,
        telefono: telefono,
        llaveSeguridad: securitykey
    }
    return SendSMSService.sendSMS(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { Codigo, llaveSeguridad } = data;
            if(Codigo === 0) {
                dispatch({
                    type: type.sendSMS,
                    payload: data
                });
                dispatch(updateDataSolicitud({paso: 3}));
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.sendSMSFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.sendSMSFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
    .catch((error)=>{
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "Hay un problemas con el servicio intente mas tarde"
            }
        });
    });
}
