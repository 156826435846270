import React from 'react';
import { Nav } from 'react-bootstrap'

const MenuChip = ({items, onClick}) => {

  const listaMenu = items.map((item) => {
    return <Nav.Item key={item.id} >
              <Nav.Link eventKey={item.id} onClick={() => { onClick(item.value)}} >{item.title}</Nav.Link>
          </Nav.Item>
  });

  return (
    <Nav className='menu-chip' variant='pills' defaultActiveKey="1">
        {listaMenu}
    </Nav>
  )
}

export default MenuChip