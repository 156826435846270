import {axiosInstance} from "../helpers/axios";

const updatePartialSolicitud = (data) => {
    return axiosInstance.post('updatePartialSolicitud/', data ).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

export default { updatePartialSolicitud };