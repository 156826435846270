import React from 'react';
import { useSelector } from 'react-redux';


import BarProgressStep from '../BarProgressStep';
import FormAseguradorasSeguro from './FormAseguradorasSeguro';
import FormCotizadorResumenSeguro from './FormCotizadorResumenSeguro';
import FormDatosAutoSeguro from './FormDatosAutoSeguro';

const FormCotizadorSeguro = () => {

    const obtencionCotizador = useSelector((state) => state.cotizadorSeguro);
    console.log(obtencionCotizador);
    const { paso, totalPasos, isCotizaSeguro, cotizaSeguro } = obtencionCotizador;

    /*const obtencionSeguros = useSelector((state) => state.cotizaSeguro);
    console.log(obtencionSeguros);
    const { isCotizaSeguro, cotizaSeguro} = obtencionSeguros;*/

    const FormularioMostrar = () => {
        switch (paso) {
            case 1:
              //return <></>
              return <FormDatosAutoSeguro />
              //return <FormDatosAuto />
            case 2:
              return <FormAseguradorasSeguro />
                //return <FormDatosAuto />
            case 3:
              return <FormCotizadorResumenSeguro />
                //return <FormDatosAuto />
        }
    }

    const headerSeccion = () => {
        return <>
        <h2 className='texto-subtitulo'>{TitlesDescripcion[(paso-1)].title}</h2>
        <BarProgressStep pasoActual={paso} pasosTotal={totalPasos} />
        </>
    }

    return (
        <div className='col-formularios-soliciud'>
            {headerSeccion()}
            {FormularioMostrar()}
        </div>
    )
}

export default FormCotizadorSeguro;

const TitlesDescripcion = [
    {
      title: "Cotizaciones"
    }, {
      title: "Selección del seguro"
    }, {
      title: "Resumen de la cotización"
    }
  ]