import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const ModalClausulasMedios = ({ show, onHide, onClick }) => {
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered
        className='modal-alerta'
    >
        <Modal.Header closeButton onClick={onHide}></Modal.Header>
        <Modal.Body>
            <h2 className='texto-subtitulo'>CLÁUSULA PARA UTILIZAR MEDIOS ELECTRÓNICOS DE AUTENTICACIÓN</h2>
            <p className='texto-informacion text-start'>Las partes acuerdan que el Cliente podrá expresar su consentimiento respecto al presente (contrato, términos y condiciones, convenio, etc.), así como tener acceso a los servicios ofrecidos por ATRIALA S.A.P.I. DE C.V. mediante medios electrónicos tales como NIP.</p>
            <Row className='justify-content-center'>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-light w-100" onClick={onHide} >Cancelar</Button>
                </Form.Group>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-primary w-100" onClick={onClick} >Acepto</Button>
                </Form.Group>
            </Row>
        </Modal.Body>
    </Modal>
  )
}

export default ModalClausulasMedios