import React, { Fragment, useState } from 'react'
import { Card, Nav, OverlayTrigger, ProgressBar as  ProgressBarLineal, Tooltip  } from 'react-bootstrap';
import { iconEstatus } from '../helpers/functionGlobal';
const CardSolicitudes = ({data}) => {
    
    const [showAllData, setShowAllData] = useState(false);
    const { title, subtitle, columns, rows } = data;
    const renderHeaderTable = columns.map(( item, index1 ) => {
        if(index1 === 1) {
            return (
                <Fragment key={index1}>
                    <th ></th>
                    <th ></th>
                    <th ></th>
                    <th ></th>
                </Fragment>
            );
        }
        return item.title.map((title, index) => {
            return(<th key={index} >{title}</th>);
        });   
    });

    const renderTableData = rows.map((item, index) => {
        //console.log(item);
        const { client_information, commission, progress_bar } = item.client;
        let icon = iconEstatus(progress_bar.tooltip.icon);
        if(( index < 5 && !showAllData) || showAllData) {
            return (
                <tr className='proceso' key={index}>
                    <td>
                        <div className='d-flex'>
                            <div className='numero' >{client_information.show_id}</div>
                            <div>
                                <p className='nombre m-0'>{client_information.clientName}</p>
                                <span className='me-2'>{client_information.folio}</span>
                                <b>{client_information.amount}</b>
                            </div>
                        </div>
                    </td>
                    <td colSpan={4}>
                        <ProgressBarLineal className="barra-etapas" >
                            <OverlayTrigger
                                overlay={
                                <Tooltip >
                                    <div className='text-start d-flex '>
                                        <i className={`${icon} flex-shrink-0`}></i>
                                        <div className='flex-grow-1'>
                                            <h6 className='titulo-tooltip' style={{color: progress_bar.tooltip.text_status }} >
                                                {progress_bar.tooltip.status}
                                            </h6>
                                            <p className='m-0' style={{color: progress_bar.tooltip.text_status }}>
                                                {progress_bar.tooltip.text}
                                            </p>
                                        </div>
                                    </div>
                                </Tooltip>
                                }
                            >
                                <ProgressBarLineal now={progress_bar.percentage} isChild={true} style={{backgroundColor: progress_bar.progress_bar_color }} />
                            </OverlayTrigger>
                
                        </ProgressBarLineal>
                        <p className='text-end m-0'>{progress_bar.text}</p>
                    </td>
                    <td>
                        <b className='texto-comision' style={{color: commission.text_color }} >{commission.text_commission}</b>
                    </td>
                </tr>
            )
        }
        return (<></>);
    });

    const mostrarTodosDatos = () => setShowAllData(!showAllData);

    return (
        <Card className='card-gris'>
            <Card.Body>
                <Card.Title className='titulo-tabla'>{title}<span className='ms-1'>{subtitle}</span></Card.Title>
            
                <table className="table table-etapas ">
                    <thead>
                        <tr>
                            {renderHeaderTable}
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableData}
                    </tbody>
                </table>
                {
                    rows.length > 0 &&
                    <div className='text-center'>
                        <Nav.Link href="#" className='texto-liga' onClick={mostrarTodosDatos}>{!showAllData ? "Ver todas" : "Ver menos"}</Nav.Link>
                    </div>
                }
                
                
            </Card.Body>
        </Card>
    )
}

export default CardSolicitudes;