import { type } from "../types/index";
import SolicitudesService from "../services/solicitudes.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const getSolicitudes = (usuario, distribuidor, sucursal, vendedor, securitykey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.resetSolicitud});
    dispatch({type: type.setLoading});
    return SolicitudesService.getSolicitudes(usuario, distribuidor, sucursal, vendedor, securitykey).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { codigo, llaveSeguridad } = data.GetSolicitudBySucursalDistribuidorResult;
            if(codigo === 0) {
                dispatch({
                    type: type.getSolicitudes,
                    payload: data.GetSolicitudBySucursalDistribuidorResult.GetSolicitudBySucursalDistribuidorResult
                });
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.getSolicitudesFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.GetSolicitudBySucursalDistribuidorResult.codigo,
                        mensajeError: data.GetSolicitudBySucursalDistribuidorResult.mensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getSolicitudesFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}

export const updateDataSolicitud = (data) => (dispatch) => {
    dispatch({
        type: type.updateSolicitud,
        payload: data
    });
}

export const retomarSolicitud = (data) => (dispatch) => {
    const { etapaProceso } = data;
    let paso = 1;
    if(etapaProceso === 7) {
        paso = 6
    } else if(etapaProceso === 6) {
        paso = 5;
    }
    data.paso = paso;
    dispatch(updateDataSolicitud(data));
}

export const detalleSolicitud = (data) => (dispatch) => {
    dispatch(updateDataSolicitud(data));
}

export const getHistorialSolicitud = (usuario, securitykey, idSolicitud, idFase, idEstatus) => (dispatch) => {
    dispatch({type: type.setLoading});
    if(idFase === "1" && idFase === "2" && idEstatus === "10" || 
        (idFase === "5" && ( idEstatus === "5" || idEstatus === "15" || idEstatus === "19" ||
            idEstatus === "18" || idEstatus === "20" || idEstatus === "21" || idEstatus === "16")    
        ) || idFase === "6" && (idEstatus === "23" || idEstatus === "22") ||
        (idFase === "2" && (idEstatus === "0" || idEstatus === "5" )) ||
        (idFase === "2" && idEstatus === "6" ) ||
        (idFase === "4" && ( idEstatus === "13" || idEstatus === "12" || idEstatus === "14" ))
    ) {

        return SolicitudesService.getHistorialEstatusFondeo(usuario, securitykey, idSolicitud).then(
            (data) => {
                dispatch({type: type.setLoading});
                if(data.getHistorialFondeoResult.codigo === 0) {
                    const { llaveSeguridad, getHistorialFondeoResult } = data.getHistorialFondeoResult;
                    dispatch({
                        type: type.getHistorialSolicitud,
                        payload: getHistorialFondeoResult
                    });
                    UpdateSecurityKey(llaveSeguridad);
    
                } else {
                    dispatch({
                        type: type.getHistorialSolicitudFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.getHistorialFondeoResult.codigo,
                            mensajeError: data.getHistorialFondeoResult.mensaje
                        }
                    });
                }
                dispatch({
                    type: type.textMessage,
                    payload: data
                });
                return Promise.resolve(data);
            },
            (error) => {
                const message = (error.response && error.response.data && error.response.data.message) ||
                error.message || error.toString();
                dispatch({type: type.setLoading});
                dispatch({
                    type: type.getHistorialSolicitudFail
                });
                dispatch({
                    type: type.textMessage,
                    payload: message
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 0,
                        mensajeError: message
                    }
                });
                return Promise.reject(error);
            }
        )

    } else {
        return SolicitudesService.getHistorialEstatus(usuario, securitykey, idSolicitud).then(
            (data) => {
                dispatch({type: type.setLoading});
                if(data.getHistorialEstatusResult.codigo === 0) {
                    const { llaveSeguridad, getHistorialEstatusResult } = data.getHistorialEstatusResult;
                    dispatch({
                        type: type.getHistorialSolicitud,
                        payload: getHistorialEstatusResult
                    });
                    UpdateSecurityKey(llaveSeguridad);
    
                } else {
                    dispatch({
                        type: type.getHistorialSolicitudFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.getHistorialEstatusResult.codigo,
                            mensajeError: data.getHistorialEstatusResult.mensaje
                        }
                    });
                }
                dispatch({
                    type: type.textMessage,
                    payload: data
                });
                return Promise.resolve(data);
            },
            (error) => {
                const message = (error.response && error.response.data && error.response.data.message) ||
                error.message || error.toString();
                dispatch({type: type.setLoading});
                dispatch({
                    type: type.getHistorialSolicitudFail
                });
                dispatch({
                    type: type.textMessage,
                    payload: message
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 0,
                        mensajeError: message
                    }
                });
                return Promise.reject(error);
            }
        )
    }

    
}

export const regresarPaso = (paso) => (dispatch) => {
    const data = {
        paso: paso
    }
    dispatch(updateDataSolicitud(data));
}

export const resetSolicitud = () => (dispatch) => {
    dispatch({
        type: type.resetSolicitud
    });
}