import React from 'react'
import { Accordion, Form, Table } from 'react-bootstrap'
import { FormatoCantidad, logoImg, logoSeguro } from '../helpers/functionGlobal';

const ElementoAccordion = ({name, value, position, onChange, datosSeguro, montoCotizar }) => {
  const {id_aseguradora, Aseguradora } = datosSeguro;
  //console.log(datosSeguro.coberturas);
  return (
    <Accordion alwaysOpen className='accordion-empresas'>
      <Accordion.Item eventKey={position} >
      <div className='datos-principales'>
        <Form.Check
          type='radio'
          className={`me-3`}
          name={name}
          value={id_aseguradora}
          checked={parseInt(value)===parseInt(id_aseguradora)}
          onChange={(e) => {
            onChange(e);
          }}
        />
        { <img src={logoImg(`./${logoSeguro(Aseguradora)}`)} alt={Aseguradora} className='logo-seguro' />}
        <div className='dato-monto'>${FormatoCantidad(montoCotizar, 2,".",",")}</div>
      </div>
      <hr className='linea-separacion' />
        <Accordion.Header>Detalle de coberturas</Accordion.Header>
        <Accordion.Body>
        <Table responsive className='tabla-detalles'>
          <thead>
            <tr>
              <th>Cobertura</th>
              <th>Deducible</th>
            </tr>
          </thead>
          <tbody>
            {
              !datosSeguro.gastos_medicos ?
              <>
                <tr>
                  <td>Daños materiales 1</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Robo total</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>Cristales</td>
                  <td>20%</td>
                </tr>
                <tr>
                  <td>Responsabilidad civil</td>
                  <td>Cubierto</td>
                </tr>
                <tr>
                  <td>Gastos médicos</td>
                  <td>Cubierto</td>
                </tr>
                <tr>
                  <td>Asistencia vía y legal</td>
                  <td>Cubierto</td>
                </tr>
              </>
              : datosSeguro.gastos_medicos ? datosSeguro.coberturas.map((cobertura, index) => {
                  return <tr key={index} >
                          <td>{cobertura.text}</td>
                          <td>{cobertura.sumaAsegurada}</td>
                        </tr>
                  
                }) : ""
            }
          </tbody>
        </Table>
        </Accordion.Body>
      </Accordion.Item>
  </Accordion>
  )
}

export default ElementoAccordion