import { type as types } from "../types/index";

const initialState = {isCodigoPostal: false, dataCodigoPostal: null};

export const CodigoPostalReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (action.type) {
        case types.getCodigoPostal:
            const result = {
                ...state, 
                isCodigoPostal: true,
                dataCodigoPostal: payload
            };
            return result;
        case types.getCodigoPostalFail:
            return {
                ...state,
                isCodigoPostal: false,
                dataCodigoPostal: null
            }
        default:
            return state;
    }
}
