import React from 'react'
import { Button, Col, Form, Modal } from 'react-bootstrap'

const ModalSucess = ({show, onHide, mensaje, onClick}) => {
  const { mensaje1, mensaje2 } = mensaje;

  const listaDatosMsj = (mensaje2 !== undefined && mensaje2.length > 0) ? mensaje2.map(( item, index1 ) => { 
    return(<p className='texto-mensaje-2'>{item.Texto}</p>);
  }) : "";
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered
        className='modal-error'
    >
        <Modal.Header closeButton onClick={onHide}></Modal.Header>
        <Modal.Body>
            <i></i>
            <p>{mensaje1}</p>
            {listaDatosMsj}
            <Form.Group as={Col} xs="6" className="mt-5 mb-3 mx-auto" >
                    <Button type="button" className="btn btn-primary w-100" onClick={onClick} >Siguiente</Button>
            </Form.Group>
        </Modal.Body>
    </Modal>
  )
}

export default ModalSucess