import { type } from "../types/index";
import CotizaSegurosService from "../services/cotiza.seguros.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { cotizadorPaso2, cotizadorPaso2SinSeguro } from "./cotizador";
import { updateDataSolicitud } from "./solicitudes";
import { MensajesAlertas } from "../models/MensajesAlertas";

export const cotizaSeguro = ( IdDescripcion, CP, anio, usuario, securitykey, dataForm ) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    return CotizaSegurosService.getCotizaSeguro( IdDescripcion, CP, anio, usuario, securitykey ).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.Codigo === 0) {
                dispatch({
                    type: type.getCotizaSeguros,
                    payload: data
                });
                dispatch(cotizadorPaso2({
                    costoAuto: dataForm.costoAuto,
                    enganche: dataForm.enganche,
                    montonFinanciar: dataForm.montonFinanciar,
                    MinEnganche: dataForm.MinEnganche
                }));
                const dataSolicitud = {
                    valorAuto: dataForm.costoAuto,
                    costoAuto: dataForm.costoAuto,
                    enganche: dataForm.enganche,
                    montonFinanciar: dataForm.montonFinanciar,
                    engancheInicial: dataForm.enganche

                }
                dispatch(updateDataSolicitud(dataSolicitud));
                UpdateSecurityKey(data.llaveSeguridad);
            } else if(data.Codigo === 1) {
                dispatch(cotizadorPaso2SinSeguro({
                    costoAuto: dataForm.costoAuto,
                    enganche: dataForm.enganche,
                    montonFinanciar: dataForm.montonFinanciar,
                    MinEnganche: dataForm.MinEnganche
                }));
                const dataSolicitud = {
                    valorAuto: dataForm.costoAuto,
                    costoAuto: dataForm.costoAuto,
                    enganche: dataForm.enganche,
                    montonFinanciar: dataForm.montonFinanciar

                }
                dispatch(updateDataSolicitud(dataSolicitud));
                UpdateSecurityKey(data.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getCotizaSegurosFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            
            dispatch({
                type: type.textMessage,
                payload: data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getCotizaSegurosFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}

