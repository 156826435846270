export const DataAssignedCommercial = {
    "assigned": false,
	"codigo": 0,
	"email": "",
	"full_name": "",
	"llaveSeguridad": "",
	"mensaje": null,
	"phone_number": "",
	"url_whatsapp": "",
	"user_name": ""
}