import { type } from "../types/index";

export const menu = (opcion) => (dispatch) => {

    const data = { menuHome: opcion }
    if(opcion === 2) {
        sessionStorage.removeItem("menuSeleccion");
    } else {
        sessionStorage.setItem("menuSeleccion", JSON.stringify(data));
    }
    
    dispatch({
        type: type.updateMenu,
        payload: data
    });
}
