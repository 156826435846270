import { type as types } from "../types/index";

const initialState = {isPutCotiza: false, putCotiza: null};

export const PutCotizaReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.getPutCotiza:
            const result = {
                ...state, 
                isPutCotiza: true,
                putCotiza: payload
            };
            return result;
        case types.getPutCotizaFail:
            return {
                ...state,
                isPutCotiza: false,
                putCotiza: null
            }
        default:
            return state;
    }
}
