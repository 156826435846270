import { type as types } from "../types/index";

const initialState = {isPurchaseDetail: false, dataPurchaseDetail: {
    poliza: "",
    contrato: "",
}};

export const PurchaseDetailReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (action.type) {
        case types.getPurchaseDetail:
            const result = {
                ...state, 
                isPurchaseDetail: true,
                dataPurchaseDetail: payload
            };
            return result;
        case types.getPurchaseDetailFail:
            return {
                ...state,
                isPurchaseDetail: false,
                dataPurchaseDetail: null
            }
        default:
            return state;
    }
}
