import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'

import BarProgressStep from './BarProgressStep'
import FormDatosAuto from './FormDatosAuto'
import FormDatosPago from './FormDatosPago';
import FormDatosPlanPago from './FormDatosPlanPago';
import FormDatosSeguros from './FormDatosSeguros';
import PlanCotizacionCliente from './PlanCotizacionCliente';

const FormCotizador = () => {

  const obtencionCotizador = useSelector((state) => state.cotizador);
  const { paso, totalPasos } = obtencionCotizador;
  const obtencionPutCotiza = useSelector((state) => state.putCotiza);
  const { isPutCotiza, putCotiza } = obtencionPutCotiza;
  
  const obtencionSolicitud = useSelector((state) => state.solicitudes);
  const FormularioMostrar = () => {
    switch (paso) {
      case 1:
        return <FormDatosAuto />
      case 2:
        return <FormDatosPlanPago />
      case 3:
          return <FormDatosSeguros />
      case 4:
        return <FormDatosPago />
      case 5:
        return <PlanCotizacionCliente />
      default:
        break;
    }
  }

  const headerSeccion = () => {

    const ligaCompartir = putCotiza ? putCotiza.LinkPDF : "";

    return paso < 5 ?
    <>
      <h2 className='texto-subtitulo'>{TitlesDescripcion[(paso-1)].title}</h2>
      <p className='texto-informacion'>{TitlesDescripcion[paso-1].descripcion}</p>
      <BarProgressStep pasoActual={paso} pasosTotal={totalPasos} />
    </>

    : <div className='header-resultado'>
        <h2 className='texto-subtitulo'>{TitlesDescripcion[(paso-1)].title}</h2>
        <a href={ligaCompartir} className="nav-link" target="_blank"><i className='icon-share'></i></a>
      </div>
  }

  return (
    <div className='col-formularios-soliciud'>
      
      {headerSeccion()}
      { FormularioMostrar() }
      { /*<FormDatosPlanPago />*/ }

    </div>
  )
}

export default FormCotizador;

const TitlesDescripcion = [
  {
    title: "Cotizaciones",
    descripcion: "Cuéntanos, ¿qué auto le interesa a tu cliente?"
  },
  {
    title: "Arma un plan de pago",
    descripcion: "Ok, ahora configuremos un plan de pago a su medida."
  },
  {
    title: "Elige el seguro",
    descripcion: "Por último, selecciona el seguro de su elección."
  },
  {
    title: "Arma un plan de pago",
    descripcion: "Por último, selecciona el seguro de su elección."
  },
  {
    title: "Plan de cotización para tu cliente",
    descripcion: " descr"
  }
]