import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import RadioBox from './RadioBox'
import { MensajesAlertas } from '../models/MensajesAlertas';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { updatePartialSolicitud } from '../actions/updatePartialSolicitud';
import { getSecurityKey } from '../helpers/SecurityKey';
import { resetSolicitud } from '../actions/solicitudes';
import { resetCotizador } from '../actions/cotizador';
import { useEffect } from 'react';
import { resetRFC } from '../actions/rfc';


const ObtencionCodigo = (props) => {
    const { closeModal, clearCotizador } = props;
    const securityKey = getSecurityKey();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    /*const obtencionSolicitud = useSelector((state) => state.saveSolicitud);
    const { telefonoMovil, distribuidor, sucursal, nombre } = obtencionSolicitud.dataSaveSolicitud;*/

    const dataSolicitud = useSelector((state) => state.solicitudes);

    const { codigoValidacion1, distribuidor, sucursal, nombre, id, telefonoMovil, seller
    } = dataSolicitud.dataSolicitud;
    //const { codigoVerificacion, idSolicitud } = dataSolicitud.dataSolicitud.dataUser; 
    const dataUser = useSelector((state) => state.auth);
    
    const validateCodigo = Yup.object({
        opcionCodigo: Yup.string().required('Debes seleccionar una opción').nullable()
    });

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validateCodigo),
        mode: "onChange"
    });
    const onSubmit = (data) => {
        const { opcionCodigo } = data;
        const usuario = dataUser.user.correo;
        const etapaProceso = "3";
        //const codigoValidacion1 = codigoValidacion1;
        const claveSucursal = "";

        dispatch(updatePartialSolicitud(usuario, securityKey, etapaProceso, id, distribuidor, codigoValidacion1, opcionCodigo, sucursal, claveSucursal, telefonoMovil, nombre, seller))
    };

    const showAlertVerification = () => {
        dispatch(showAlert(MensajesAlertas.mensajeVerificacion, () => { closeModal();}, () =>{ closeModal(); clearCotizador(); navigate("/home"); }  ));
    }
    
    const [checkRadio, setCheckRadio] = useState(1)
    const checkRadioButton = (e) => {
        setCheckRadio(e.target.value);
    }
    useEffect(() => {
        setValue("opcionCodigo", "1");
    }, [])
    
    
    return (
        <Card className='card-formulario'>
            <Card.Body className='px-0'>
                <Form className='formulario-usuario' onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col xs={6} >
                            <RadioBox 
                                id="mensajeTexto" 
                                name="opcionCodigo" 
                                value={1} 
                                classIcon="icon-chat" 
                                textoInformacion="Mensaje de texto al"  
                                numero={ telefonoMovil }
                                register={register} 
                                errors={errors}
                            />
                        </Col>
                        <Col xs={6} >
                            <RadioBox 
                                id="llamar" 
                                name="opcionCodigo" 
                                value={2} 
                                classIcon="icon-phone" 
                                textoInformacion="Llamada al"  
                                numero={ telefonoMovil }
                                register={register} 
                                errors={errors}
                            />
                        </Col>
                        <p className='texto-error text-center mt-3'>{errors.opcionCodigo?.message}</p>
                    </Row>
                    <Row className='mt-3 pt-3'>
                        <Col xs={6} className='mt-16'>
                            <Button type="button" className="btn btn-light w-100" onClick={showAlertVerification} >Verificar en otro momento</Button>
                        </Col>
                        <Col xs={6} className='mt-16'>
                            <Button type="submit" className="btn btn-primary w-100" >Enviar código</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}

//export default ObtencionCodigo
const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
            dispatch(resetRFC());
        } 
	};
};

export default connect(null, mapDispatchToProps)(ObtencionCodigo)