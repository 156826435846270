import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Button, Card, Col, Form, Nav, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { sendSMS } from '../actions/sendSMS';
import { getSecurityKey } from '../helpers/SecurityKey';
import { sendCall } from '../actions/sendCall';
import { getScoreResult } from '../actions/scoreResult';
import ReadMore from './ReadMore';
import ModalTerminosServicio from './ModalTerminosServicio';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { useNavigate } from 'react-router-dom';
import { resetSolicitud } from '../actions/solicitudes';
import { type } from "../types/index";
import { resetCotizador } from '../actions/cotizador';
import ModalPoliticaPrivacidad from './ModalPoliticaPrivacidad';
import ModalSucess from './ModalSucess';
import { continuarPreautorizada, hideModalSucess } from '../actions/modalSucess';
import ModalClausulasMedios from './ModalClausulasMedios';
import { resetRFC } from '../actions/rfc';

const VerificacionCodigo = (props) => {
    const { closeModal, clearCotizador, continuar } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showTerminos, setShowTerminos] = useState(false);
    const [showClausulasMedios, setShowClausulasMedios] = useState(false);
    const [showReportesCredito, setShowReportesCredito] = useState(false);
    const [bloqueoEnvio, setBloqueoEnvio] = useState(false);

    const modalSucces = useSelector((state) => state.modalSucces);
    const { showModalSucess, dataMensaje } = modalSucces;

    const securityKey = getSecurityKey();
    const dataUser = useSelector((state) => state.auth);

    const dataSolicitud = useSelector((state) => state.solicitudes);

    const { codigoValidacion1, sucursal, nombre, id, telefonoMovil, codigoValidacion2, codigoVerificacion1,
        terminoServicios, distribuidor
    } = dataSolicitud.dataSolicitud;
    const validateVerificaCodigo = Yup.object({
        codigoVerificacion: Yup.string().required('El campo es requerido').min(6, "Introduzca al menos ${min} caracteres"),
        terminoServicios: Yup.bool().oneOf([true], 'El campo es requerido'),
        politicaPrivacidad: Yup.bool().oneOf([true], 'El campo es requerido'),
        reportesCredito: Yup.bool().oneOf([true], 'El campo es requerido')
    });
    const { register, handleSubmit, formState: { errors }, setValue, formState } = useForm({
        resolver: yupResolver(validateVerificaCodigo),
        mode: "onChange"
    });
    //console.log(dataSolicitud);
    const onSubmit = (data) => {
        //dispatch(getScoreResult(dataUser.user.correo, securityKey, distribuidor, idSolicitud, codigoVerificacion, sucursal ));
        //dispatch(getverificacionCodigo(data, codigoValidacion1, dataSolicitud.dataSolicitud))
        bloqueoEnvio && dispatch(getScoreResult(dataUser.user.correo, securityKey, distribuidor, id, codigoValidacion1, sucursal, data, dataSolicitud.dataSolicitud, dataUser.user.pantalla_credito ))
        .then((res) =>{
            setBloqueoEnvio(false);
        })
    };

    const envioCodigoSMS = () => {
        dispatch({type: type.setLoading});
        clearTimer(getDeadTime())
        const claveSucursal = "";
        dispatch(sendSMS(dataUser.user.correo, securityKey, id, sucursal, claveSucursal, codigoValidacion1, telefonoMovil))
    }
    const envioCodigoCall = () => {
        dispatch({type: type.setLoading});
        clearTimer(getDeadTime())
        dispatch(sendCall(dataUser.user.correo, securityKey, telefonoMovil, nombre, codigoValidacion1));
    }

    const checkAviso = (tipoModal) => {
        switch (tipoModal) {
            case "terminosServcio":
                setValue("terminoServicios", true);
                setShowTerminos(false)
                break;
            case "clausulasMedios":
                setValue("terminoServicios", true);
                setShowClausulasMedios(false)
                break;
            case "politicaPrivacidad":
                setValue("politicaPrivacidad", true);
                setShowReportesCredito(false)
                break;
            default:
                break;
        }
    }

    const showAlertVerification = () => {
        dispatch(showAlert(MensajesAlertas.mensajeVerificacion, () => { closeModal();}, () =>{ closeModal(); clearCotizador(); navigate("/home"); }  ));
    }
    
    useEffect(() => {
        setValue("codigoVerificacion", codigoVerificacion1);
        setValue("terminoServicios", terminoServicios);
    }, []);

    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
    const [activeLink, setActiveLink] = useState(true)

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 * 60 * 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } 
                    = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        } else {
            clearInterval(Ref.current);
            setActiveLink(false);
        }
    }

    const clearTimer = (e) => {
        setTimer('00:03:00');
  
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        setActiveLink(true);
        let deadline = new Date();

        deadline.setSeconds(deadline.getSeconds() + 180);
        //180
        return deadline;
    }

    useEffect(() => {
        clearTimer(getDeadTime())
    }, []);

    //console.log(console.log(formState.dirty));
    return (
        <Card className='card-formulario '>
            <Card.Body className='px-0'>
                <Form  onSubmit={handleSubmit(onSubmit)} className="frm-verificacion-codigo">
                    <Row>
                        <div className='col-12 mb-3'>
                            <Card className='card-clausulas'>
                                <Card.Body>
                                    <Card.Subtitle >Autorización para solicitar Reportes de Crédito</Card.Subtitle>
                                    <ReadMore>
                                        Autorizo expresamente a ATRIALA S.A.P.I. DE C.V. y LGF Occidente, S.A. de C.V. SOFOM E.N.R, para que lleve a cabo Investigaciones sobre mi comportamiento Crediticio en las Sociedades de Información Crediticia (SIC) que estime conveniente, Conozco la naturaleza y alcance de la información que se solicitara, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio.
                                        Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy, y en su caso mientras mantengamos relación jurídica. Acepto que este documento quede bajo propiedad de ATRIALA S.A.P.I. DE C.V. y LGF Occidente, S.A. de C.V. SOFOM E.N.R para efectos de control y cumplimiento del articulo 28 de la LRSIC.
                                    </ReadMore>
                                </Card.Body>
                            </Card>
                        </div>
                        <Form.Group as={Col} xs="12" className='col-aviso-privacidad' >
                            <Form.Check className='mb-3'>
                                <Form.Check.Input name="terminoServicios" 
                                    className={` aviso ${errors.terminoServicios ? 'is-invalid' : ""}`} 
                                    {...register("terminoServicios")}
                                    
                                />
                                <Form.Check.Label>
                                    Acepto <Nav.Link onClick={() => { setShowTerminos(true)}}>TÉRMINOS Y CONDICIONES</Nav.Link> Y <Nav.Link onClick={() => { setShowClausulasMedios(true)}}>CLAUSULA DE MEDIOS ELECTRÓNICOS</Nav.Link> tales como NIP
                                </Form.Check.Label>
                            </Form.Check>

                            <Form.Check className='mb-3'>
                                <Form.Check.Input name="politicaPrivacidad" 
                                    className={` aviso ${errors.politicaPrivacidad ? 'is-invalid' : ""}`} 
                                    {...register("politicaPrivacidad")}
                                    
                                />
                                <Form.Check.Label>
                                    Acepto <Nav.Link onClick={() => { setShowReportesCredito(true)}}>POLITICA DE PRIVACIDAD</Nav.Link>
                                </Form.Check.Label>
                            </Form.Check>

                            <Form.Check>
                                <Form.Check.Input name="reportesCredito" 
                                    className={` aviso ${errors.reportesCredito ? 'is-invalid' : ""}`} 
                                    {...register("reportesCredito")}
                                    
                                />
                                <Form.Check.Label>
                                    Autorizo solicitud de <b>REPORTES DE CRÉDITO</b>
                                </Form.Check.Label>
                            </Form.Check>

                        </Form.Group>

                        <h2 className='texto-subtitulo texto-verifica-codigo text-center my-3'>Verifica el código</h2>
                        <p className='texto-informacion'>Ingresa el código que enviamos al {telefonoMovil} </p>

                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Código de verificación</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="codigoVerificacion"
                                placeholder="Ingresa el código de 6 dígitos"
                                maxLength={6}
                                autoComplete="off"
                                className={`${errors.codigoVerificacion ? 'is-invalid' : ""}`}
                                {...register("codigoVerificacion")}
                            />
                            <p className='texto-error'>{errors.codigoVerificacion?.message}</p>
                        </Form.Group>
                        <div className='col-12 col-reenvio'>
                            <p>¿No has recibido el código?</p>
                            <div className='ligas'>
                                <Nav.Link  onClick={envioCodigoSMS} disabled={activeLink} >ENVIAR NUEVAMENTE</Nav.Link>
                                <p className='timer'>{timer}</p>
                                <Nav.Link  onClick={envioCodigoCall} disabled={activeLink} >ENVIAR POR LLAMADA</Nav.Link>
                            </div>
                        </div>
                        
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={6} className='mt-16'>
                            <Button type="button" className="btn btn-light w-100" onClick={showAlertVerification} >Verificar en otro momento</Button>
                        </Col>
                        <Col xs={6} className='mt-16'>
                            <Button 
                                type="submit" 
                                className="btn btn-primary w-100"
                                disabled={!formState.isDirty || (formState.isDirty && !formState.isValid)}
                                onClick={() => setBloqueoEnvio(true)}
                            >
                                Autorizar y Continuar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
            <ModalTerminosServicio show={showTerminos} onHide={()=>{setShowTerminos(false)}} onClick={() => {checkAviso("terminosServcio")}} />
            <ModalClausulasMedios show={showClausulasMedios} onHide={()=>{setShowClausulasMedios(false)}} onClick={() => {checkAviso("clausulasMedios")}} />
            <ModalPoliticaPrivacidad show={showReportesCredito} onHide={()=>{setShowReportesCredito(false)}} onClick={() => {checkAviso("politicaPrivacidad")}} />
            <ModalSucess show={showModalSucess} onHide={closeModal} mensaje={dataMensaje} onClick={continuar} />
        </Card>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
            dispatch(resetRFC());
        },
        continuar: () => {
            dispatch(hideModalSucess());
            dispatch(continuarPreautorizada());
        },
	};
};

//export default VerificacionCodigo
export default connect(null, mapDispatchToProps)(VerificacionCodigo)
