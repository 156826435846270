import {axiosInstance} from "../helpers/axios";

const getAnios = (user, id, securitykey) => {
  return axiosInstance
    .post("getModelosv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      ID: id,
      llaveSeguridad: securitykey,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error");
      console.log(err.message);
    });
};

const getAniosSeguro = (user, id, securitykey) => {
  return axiosInstance
    .post("getModelosv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      ID: id,
      llaveSeguridad: securitykey,
      modulo: 2
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error");
      console.log(err.message);
    });
};

export default { getAnios, getAniosSeguro };
