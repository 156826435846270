import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideAlert } from '../actions/alertMessage';


const AlertaMessage = ({ show, data }) => {
    const dispatch = useDispatch();
    const { title, subtitle, textoCustom, textButtonPrimary, textButtonSecond, buttonPrimary, buttonSecond, buttonHeader} = data;
    return (
        <Modal
            show = {show}
            centered

            className='modal-alerta'
        >
            <Modal.Header closeButton onClick={() => {
                (buttonHeader !== "" && buttonHeader !== undefined) ?
                    buttonHeader()
                :
                    dispatch(hideAlert())
            }}></Modal.Header>
            <Modal.Body>
                
                {   
                    subtitle !== "" ?
                    <h2 className='texto-subtitulo'>{subtitle}</h2>
                    : ""
                }
                {textoCustom}
                
                <Row className='justify-content-center'>
                    {
                        (buttonSecond !== "" && buttonSecond !== undefined) &&
                        <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                            <Button type="button" className="btn btn-light w-100 h-100" onClick={buttonSecond.onClick} >{buttonSecond.texto}</Button>
                        </Form.Group>
                    }

                    {
                        (buttonPrimary !== "" && buttonPrimary !== undefined) &&
                        <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                            <Button type="button" className="btn btn-primary w-100 h-100" onClick={buttonPrimary.onClick} >{buttonPrimary.texto}</Button>
                        </Form.Group>
                    }
                </Row>
                
            </Modal.Body>
        </Modal>
    )
}

export default AlertaMessage