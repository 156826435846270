import { solicitud } from "../models/Solicitud";
import { type as types } from "../types/index";

//const user = JSON.parse(sessionStorage.getItem("user"));
const sesionSolicitud = JSON.parse(sessionStorage.getItem("dataSolicitud"));
//const initialState = user ? { isLoggedIn: true, user, errorMessage: null } : {isLoggedIn: false, user: null, errorMessage: null}

/*const initialState = sesionSolicitud ? 
    {sesionSolicitud} 
:
{isSolicitud: false, isLoading: false, dataSolicitudes: [], 
    dataSolicitud: solicitud,
    historial: []
}*/

const initialState = {isSolicitud: false, isLoading: false, dataSolicitudes: [], 
    dataSolicitud: solicitud,
    historial: []
};

export const SolicitudesReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.addSolicitud:
            return {
                ...state,
                isSolicitud: true,
                dataSolicitud: payload
            };
        case types.updateSolicitud:
            const data = {
                ...state,
                isSolicitud: true,
                dataSolicitud: {
                    ...state.dataSolicitud,
                    ...payload
                }
            }
            return data;

        case types.getSolicitudes:
            const result = {
                ...state, 
                isSolicitud: true,
                dataSolicitudes: payload
            };
            return result;
        case types.getSolicitudesFail:
            return {
                ...state,
                isSolicitud: false,
                dataSolicitudes: null
            }
        case types.resetSolicitud:
            return initialState
    
        /* Historial */
        case types.getHistorialSolicitud:
            return {
                ...state,
                isSolicitud: true,
                historial: payload
            }
        case types.getHistorialSolicitudFail:
            return {
                ...state,
                isSolicitud: false,
                historial: []
            }

        default:
            return state;
    }
}
