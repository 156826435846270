export const TipoVivienda = [
    {
        id: 1,
        label: "Propia",
        value: "Propia"
    }, 
    {
        id: 2,
        label: "Propia con Hipoteca",
        value: "Propia con Hipoteca"
    },
    {
        id: 3,
        label: "Rentada",
        value: "Rentada"
    },
    {
        id: 4,
        label: "Familiar",
        value: "Familiar"
    }
]