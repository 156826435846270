import { type } from "../types/index";
import ImageProfileService from "../services/profile_image";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { MensajesAlertas } from "../models/MensajesAlertas";

export const setProfileImage = (user, image, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    return ImageProfileService.setProfileImage( user, image, securityKey ).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.image !== undefined) {
                dispatch({
                    type: type.setImageProfile,
                    payload: data
                });
                dispatch({
                    type: type.updateImageProfile,
                    payload: data.image
                });
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data.response.status !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.setImageProfileFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            UpdateSecurityKey(data.llaveSeguridad);
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setImageProfileFail
            });

            return Promise.reject(error);
        }
    );
}