import { type } from "../types/index";
import PurchaseDetailService from "../services/get.purchase.detail.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const getPurchaseDetail = (user, consecutivo, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const userAgent = window.navigator.userAgent;
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        consecutivo: consecutivo,
        ip_dispositivo: "0.0.0.0",
        latitud: "19.3005223",
        longitud: "-98.9031798",
        usuario: user,
        //usuario_app: user,
        llaveSeguridad: securityKey
    }
    return PurchaseDetailService.getPurchaseDetail( dataRequest ).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.codigo === 0) {
                //console.log(data.ROOT.RESPONSE.poliza);
                const dataRes = {
                    poliza:  data.ROOT.RESPONSE.poliza !== undefined ? data.ROOT.RESPONSE.poliza : "",
                    contrato: data.ROOT.RESPONSE.contrato !== undefined ? data.ROOT.RESPONSE.contrato : ""
                }
                dispatch({
                    type: type.getPurchaseDetail,
                    payload: dataRes
                });
                
                UpdateSecurityKey(data.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getPurchaseDetailFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            
            dispatch({
                type: type.textMessage,
                payload: data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getPurchaseDetailFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
