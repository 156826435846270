import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getSecurityKey } from '../../helpers/SecurityKey';
import { resetFormSolicitud, solicitudPaso4 } from '../../actions/seguros/solicitudSeguros';
import { useNavigate } from 'react-router-dom';
import { hideAlert, showAlert } from '../../actions/alertMessage';
import { resetCotizadorSeguro } from '../../actions/seguros/cotizadorSeguros';
import { MensajesAlertas } from '../../models/MensajesAlertas';


const FormDatosDelAutoSeguro = (props) => {
    const { closeModal, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const securityKey = getSecurityKey();

    const dataUser = useSelector((state) => state.auth);
    const obtencionSolicitud = useSelector((state) => state.solicitudSeguro);
    const { VIN, numeroDeMotor, placas, color, tipoDeCarga, id } = obtencionSolicitud;
    //console.log(obtencionSolicitud);
    const catalogos = useSelector((state) => state.catalogosSeguro);
    const { isCatalogo, dataCatalogosSeguro } = catalogos;

    const [combos, setCombos] = useState({
        color: color === null ? "" : color, 
        tipoCarga: tipoDeCarga === null ? "" : tipoDeCarga
    });

    const validateDomicilio = Yup.object({
        numeroSerierVIN: Yup.string().required('El campo es requerido').nullable().test(
            "mindigitos", "Introduzca 17 caracteres", (value) => {
                if(value !== "" && value !== undefined) {
                    if(value.length < 17) {
                        return false;
                    }
                }
                return true;
            }
        ),
        color: Yup.string().required('El campo es requerido'),
        placas: Yup.string().nullable().test(
            "mindigitos", "Introduzca al menos 6 caracteres", (value) => {
                if(value !== "" && value !== undefined) {
                    if(value.length < 6) {
                        return false;
                    }
                }
                return true;
            }
        )
    });
    const { register, handleSubmit, formState: { errors }, control, getFieldState, getValues, setValue } = useForm({
        resolver: yupResolver(validateDomicilio),
        mode: "onChange" 
    });
    const onSubmit = (data) => {
        const { correo } = dataUser.user;
        dispatch(solicitudPaso4(data, correo, securityKey, id));
    };

    const llenadoCombo = (e) => {
        const { name, value } = e.target;
        const comboSelec = {
            ...combos,
            [name]:value
        };
        setCombos(comboSelec);
    }

    const salirSolicitud = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); } ));
    }

    /* Llenado de Combos */
    const optionTipoCarga = isCatalogo ? dataCatalogosSeguro.tipoDeCarga.map((carga, index) => {
        return <option key={index} value={carga.id}>{carga.name}</option>
    }) : "";
    const optionColor = isCatalogo ? dataCatalogosSeguro.color.map((color, index) => {
        return <option key={index} value={color.id}>{color.name}</option>
    }) : "";

    useEffect(() => {
        setValue("numeroSerierVIN", VIN === null ? "" : VIN );
        setValue("numeroMotor", numeroDeMotor === null ? "" : numeroDeMotor );
        setValue("placas", placas === null ? "" : placas);
        if(color === null) {
            setValue("color", "");
        } else {
            setValue("color", color);
        }
        setValue("tipoCarga", tipoDeCarga === null ? "" : tipoDeCarga);
    }, []);

    return (
        <Form className='formulario-datos-auto' onSubmit={handleSubmit(onSubmit)}>
            <Card className='card-formulario card-datos-auto'>
                <Card.Title className='my-3'><i className='icon-pin me-3'></i>Datos del auto</Card.Title>
                <Card.Text className='texto-informacion-roboto'>Los datos deben coincidir con la identificación oficial de tu cliente.</Card.Text>
                <Card.Body className='p-0'>
                    <Row>
                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Número de serie o VIN</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="numeroSerierVIN" 
                                placeholder="Escribe el número de serie o VIN"
                                maxLength={17}
                                autoComplete="off"
                                className={`${errors.numeroSerierVIN ? 'is-invalid' : ""}`}
                                {...register('numeroSerierVIN')}
                            />
                            <p className='texto-error'>{errors.numeroSerierVIN?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Número de motor (Opcional)</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="numeroMotor" 
                                placeholder="Escribe en número de motor"
                                maxLength={17}
                                autoComplete="off"
                                className={`${errors.numeroMotor ? 'is-invalid' : ""}`}
                                {...register('numeroMotor')}
                            />
                            <p className='texto-error'>{errors.numeroMotor?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Placas (Opcional)</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="placas" 
                                placeholder="Escribe las placas"
                                maxLength={7}
                                autoComplete="off"
                                className={`${errors.placas ? 'is-invalid' : ""}`}
                                {...register('placas')}
                            />
                            <p className='texto-error'>{errors.placas?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="color">Color</Form.Label>
                            <Form.Select 
                                name="color"
                                value={combos.color}
                                placeholder="Selecciona un color"
                                className={`${errors.color ? 'is-invalid' : ""} ${combos.color ? 'custom-select' : ''}`}
                                {...register('color', {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })}
                            >
                                <option value="">Selecciona el color</option>
                                {optionColor}
                            </Form.Select>
                            <p className='texto-error'>{errors.color?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" className='mb-3' >
                            <Form.Label htmlFor="tipoCarga">Tipo de carga (Sólo si es vehículo pick up)</Form.Label>
                            <Form.Select 
                                name="tipoCarga"
                                className={`${errors.tipoCarga ? 'is-invalid' : ""} ${combos.tipoCarga ? 'custom-select' : ''}`}
                                {...register('tipoCarga', {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })}
                            >
                                <option value="">Selecciona el tipo de carga</option>
                                {optionTipoCarga}
                            </Form.Select>
                            <p className='texto-error'>{errors.tipoCarga?.message}</p>
                        </Form.Group>
                    </Row>
                    <Row className='mt-5'>
                        <Col xs={6} className='px-2'>
                            <Button type="button" className="btn btn-light w-100" onClick={salirSolicitud} >Cancelar</Button>
                        </Col>
                        <Col xs={6} className='px-2'>
                            <Button type="submit" className="btn btn-primary w-100" >Siguiente</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Form>
    )
}
const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetFormSolicitud());
            dispatch(resetCotizadorSeguro());
        } 
	};
};
export default connect(null, mapDispatchToProps)(FormDatosDelAutoSeguro);