import { type } from "../types/index";
import GetJobsService from "../services/get.jobs.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const getJobs = (usuario, securitykey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const userAgent = window.navigator.userAgent;
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        llaveSeguridad: securitykey
    }
    //console.log(dataRequest);
    
    return GetJobsService.getJobs(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { codigo, llaveSeguridad } = data;
            if(codigo === 0) {
                dispatch({
                    type: type.getJobs,
                    payload: data
                });
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.updateSaveSolicitudFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getJobsFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
