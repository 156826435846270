import {axiosInstance, axiosInstanceSeguros} from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const getCatalogos = (user, securitykey) => {
    return axiosInstance.post('getCatalogs/', {
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "canal":"1",
        "version": "2.0.0",
        "usuario": user,
        "llaveSeguridad": securitykey
    }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

const getCatalogosSeguros = (user, securitykey) => {
    const headers = {
        'Authorization': `Bearer ${securitykey}`
    }
    return axiosInstanceSeguros.post('getInsuranceCatalogs/', {
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "canal":"1",
        "version": "2.0.0",
        "usuario": user
    }, { headers: headers }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
        return err.response.status;
    });
}

export default { getCatalogos, getCatalogosSeguros };