import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const ModalDocumentos = ({ show, onHide }) => {
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered

        className='modal-alerta'
    >
        <Modal.Header closeButton onClick={onHide}></Modal.Header>
        <Modal.Body>
            <h2 className='texto-subtitulo'>¿Estás seguro de continuar sin comprobar ingresos y sin historial crediticio?</h2>
            <Row className='justify-content-center'>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-primary w-100" onClick={onHide} >Entendido</Button>
                </Form.Group>
            </Row>
        </Modal.Body>
    </Modal>
  )
}

export default ModalDocumentos