import { type } from "../types";

export const continuarPreautorizada = () => (dispatch) => {
    dispatch({
        type: type.updateSolicitud,
        payload: {
            paso: 5
        }
    });
}

export const hideModalSucess = () => (dispatch) => {
    dispatch({
        type: type.hideModalSucess
    });
}