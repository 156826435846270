import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { resetCotizador } from '../actions/cotizador';
import { resetRFC } from '../actions/rfc';
import { resetSolicitud } from '../actions/solicitudes';
import { FormatoCantidad, logoImg, logoSeguro } from '../helpers/functionGlobal';
import { getSecurityKey } from '../helpers/SecurityKey';
import { MensajesAlertas } from '../models/MensajesAlertas';


const PlanAutorizado = (props) => {
    const { closeModal, clearCotizador } = props;
    const securityKey = getSecurityKey();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dataUser = useSelector((state) => state.auth);
    const dataSolicitud = useSelector((state) => state.solicitudes);
    //const { id  } = dataSolicitud.dataSolicitud; 

    const dataPlanAutorizado = useSelector((state) => state.planAutorizado);
    const { linkPDF, response_andasof } = dataPlanAutorizado.dataPlanAutorizado;
    const {  EngancheFinal, Financiamiento, MensualidadNueva, PerfilFinalSimplificado, 
        Plazo, ValorVehiculo, PMontoEnganche, MontoSeguro, Comision, TipoSeguro, Aseguradora,
        EngancheMinimo
    } = response_andasof;

    const envioFinal = () => {
        MensajesAlertas.mensajeSolicitudPreautorizada.textoCustom = (
            <>
                <div className="modal-documentos-mano">
                    <p className="texto-descripcion">Consecutivo: {}</p>
                    <p className="texto-descripcion">Para continuar con el proceso, el cliente debe compartir estos documentos:</p>
                    <ul className="lista-documentos">
                        <li><i className="icon-identificacion"></i><span>INE, pasaporte o FM2,FM3</span></li>
                        <li><i className="icon-comprobante-domicilio"></i><span>Comprobante de domicilio (no mayor a 3 meses)</span></li>
                        <li><i className="icon-comprobante-ingresos"></i><span>Comprobante de ingresos (si cuenta con uno)</span></li>
                    </ul>
                    <a href={linkPDF} className="nav-link descarga-pdf" target="_blank">Descargar PDF con el detalle de la solicitud</a>
                </div>
            </>
        );
        dispatch(showAlert(MensajesAlertas.mensajeSolicitudPreautorizada, () => { closeModal(); clearCotizador(); navigate("/home")} ))
    }
    
    
    return (
        <div className='seccion-resumen-solicitud'>
            <Card className='card-plan-cotizacion'>
                <Card.Body >
                    <Row>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Perfil Preautorización:</p>
                            <p className='texto-resultado'>{PerfilFinalSimplificado}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Plazo</p>
                            <p className='texto-resultado'>{Plazo} meses</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Valor del auto</p>
                            <p className='texto-resultado'>${FormatoCantidad(ValorVehiculo, 2, ".", ",")}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>% Enganche requerido(perfil)</p>
                            <p className='texto-resultado'>{FormatoCantidad(EngancheMinimo, 0, ".", ",")}%</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Monto Enganche</p>
                            <p className='texto-resultado'>${FormatoCantidad(EngancheFinal, 2, ".", ",")}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>% Enganche</p>
                            <p className='texto-resultado'>{PMontoEnganche}%</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Seguro: <b>{TipoSeguro}</b></p>
                            {
                                (Aseguradora !== '' && Aseguradora !== null) ?
                                <img src={logoImg(`./${logoSeguro(Aseguradora)}`)} className="ms-2 logo-seguro" alt={Aseguradora} />
                                : ""
                            }
                        </Col> 
                        
                        
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Monto Seguro</p>
                            <p className='texto-resultado'>${FormatoCantidad(MontoSeguro, 2, ".", ",")}</p>
                        </Col>

                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Monto Financiar</p>
                            <p className='texto-resultado'>${FormatoCantidad(Financiamiento, 2, ".", ",")}</p>
                        </Col>
                        <Col xs={6} className="mb-3">
                            <p className='texto-label'>Comisión</p>
                            <p className='texto-resultado'>${FormatoCantidad(Comision, 2, ".", ",")}</p>
                        </Col>

                        <Col xs={12} className="mb-3 text-center">
                            <p className='texto-label'>Mensualidad</p>
                            <p className='texto-resultado'>${FormatoCantidad(MensualidadNueva, 2, ".", ",")}</p>
                        </Col>

                    </Row>
                </Card.Body>
            </Card>
            <Row className='mt-3 pt-3 justify-content-center'>
                <Col xs={6} className='mt-16'>
                    <Button type="button" className="btn btn-primary w-100" onClick={envioFinal} >Continuar</Button>
                </Col>
            </Row>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
            dispatch(resetRFC());
        } 
	};
}

//export default PlanAutorizado
export default connect(null, mapDispatchToProps)(PlanAutorizado)