import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, connect } from "react-redux";
import { Form, Row, Col, Button, Card, Accordion } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { getSecurityKey } from '../helpers/SecurityKey';
import { FormatoCantidad } from '../helpers/functionGlobal';
import { putCotiza } from '../actions/putCotiza';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { resetCotizador } from '../actions/cotizador';

const FormDatosPago = (props) => {
    const { closeModal, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const securityKey = getSecurityKey();
    
    const obtencionPlazos = useSelector((state) => state.postCotiza);
    const { isPostCotiza, postCotiza } = obtencionPlazos;
    console.log(postCotiza);
    const { footMesage } = postCotiza;
    const obtencionCotizador = useSelector((state) => state.cotizador);
    const { isTradicional, montoFinanciamiento, cotizacion } = obtencionCotizador;
    const { SeguroFinanciado, Plazo: plazoVal } = cotizacion;
    const obtencionUser = useSelector((state) => state.auth);
    const [pagoSeguro, setPagoSeguro] = useState(SeguroFinanciado);
    const sucursalSelec = useSelector((state) => state.sucursalSelection);
    const { Clave, CveDistribuidor } = sucursalSelec.sucursalSeleccionada;

    const validatePago = Yup.object({
        tipoPago: !isTradicional ? Yup.string().required('El campo es requerido').nullable() : Yup.string().notRequired(),
        plazo: Yup.string().required('Debe seleccionar un plazo').nullable()
    });

    const { register, handleSubmit, formState: { errors, isDirty }, setValue } = useForm({
        resolver: yupResolver(validatePago),
        mode: "onChange"
        /*defaultValues: {
            tipoPago: SeguroFinanciado ? "financiado" : "de contado"
        }*/
    });

    const onSubmit = (data) => {
        let separarString = data.plazo.split('|');
        let plazoSe = parseInt(separarString[0]);
        let empresaSelect = parseInt(separarString[1]);
        const { postCotiza } = obtencionPlazos;
        const cotizaciones = postCotiza.Cotizaciones;
        const {clave_de_institucion, Enganche, Enganche_str, Nombre, Perfil, porcentajeEnganche, Cotizacion: cts, Nombre: nombreInstitucion} = postCotiza.Cotizaciones[empresaSelect];
        const { correo } = obtencionUser.user;
        const { cotizacion, IdDescripcion, Modelo, CP, seguroSelect, ValorAuto, Historial, Comprobantes, Enganche: engancheInic } = obtencionCotizador;
        const { IdCotizacionSeguro} = seguroSelect;
        let tipoPago = data.tipoPago === "financiado" ? true : false;
        if(isTradicional) {
            tipoPago = false;
        }
        let plazoSelect;
        for(let i = 0; i < cts.length; i++) {
            if(cts[i].Plazo === parseInt(plazoSe) && cts[i].SeguroFinanciado === tipoPago) {
                plazoSelect = cts[i];
            }
        }
        const { Plazo, Pagomensual, SeguroFinanciado, enable, Financiamiento, Financiamiento_str, Montos,
        Pagomensual_str, tasa_global, ValorAuto_str, } = plazoSelect;
        dispatch(putCotiza(cotizacion.Inicial, Pagomensual, Plazo, SeguroFinanciado, IdDescripcion, Modelo, CP, correo, 
        securityKey, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes, isTradicional, obtencionCotizador,
        Clave, CveDistribuidor, seguroSelect, clave_de_institucion, Enganche_str, Nombre, Perfil, porcentajeEnganche, 
        enable, Financiamiento, Financiamiento_str, Montos, Pagomensual_str, tasa_global, ValorAuto_str, nombreInstitucion, engancheInic ))
        /*.then((res)=> {
            if(res.Codigo === 0 && isTradicional) {
                dispatch(showAlert(MensajesAlertas.mensajeDocumentosMano, () => { closeModal();  navigate("/solicitud") }, () => { closeModal(); } ));
            }
        })*/
    }
    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionCotizacion, () => { closeModal(); clearCotizador(); navigate("/home"); }, () => { closeModal(); }  ));
    }


    let itemOpen = [];
    const plazos =  isPostCotiza ? postCotiza.Cotizaciones.map((plazo, index) => {
        const { Logo, mensajePrincipal, mensajeSecundario, porcentajeEnganche, Enganche_str, Nombre, clave_de_institucion, collapse, FinanciamientoFinanciado_str, FinanciamientoContado_str
        } = plazo;

        if(!collapse) {
            itemOpen.push(index);
        }
        return (
            <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>
                    <img src={Logo} alt={Nombre} className='logo-seguro' />
                </Accordion.Header>
                <Accordion.Body>
                    <p className='texto-subtitulo'><b>{mensajePrincipal}</b></p>
                    <Row className='texto-enganche'>
                        <Col xs="6" className='text-center'>
                            <p className='m-0'>Enganche</p>
                            <p className='m-0'>% Engance</p>
                            <p className='m-0'>Monto a financiar</p>
                        </Col>
                        <Col xs="6" className='text-center'>
                            <p className='m-0'>{Enganche_str}</p>
                            <p className='m-0'>{porcentajeEnganche}%</p>
                            <p className='m-0'>{pagoSeguro ? FinanciamientoFinanciado_str : FinanciamientoContado_str}</p>
                        </Col>
                        <Col xs="12" className='texto-error my-3 text-center'>
                            {mensajeSecundario}
                        </Col>
                        {
                            plazo.Cotizacion.map((cotizacion, index2) => {
                                const {Pagomensual_str, Plazo, SeguroFinanciado, enable} = cotizacion;
                                return (pagoSeguro === SeguroFinanciado || isTradicional) ?
                                    <Col xs="12" className='mb-3' key={index2}>
                                        <div className={`col-plazos ${!enable ? "col-plazo-enable" : ""}`}>
                                            <div className='d-flex align-items-center'>
                                                <Form.Check
                                                    type='radio'
                                                    name="plazo"
                                                    className='me-3'
                                                    value={Plazo + "|"+index}
                                                    disabled = {enable ? false : true}
                                                    {...register("plazo")}
                                                />
                                                <span className="monto"> { enable ?  Pagomensual_str : "No aplica" } </span>
                                            </div>
                                            
                                            <div className="plazo">{Plazo} meses </div>
                                        </div>
                                    </Col> : ""
                            })
                        }

                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        )
    }) : "";

    useEffect(() => {
        setValue("tipoPago", SeguroFinanciado ? "financiado" : "de contado" );
        //setValue("plazo", String(plazoVal));
        setValue("plazo", "");
    }, []);
  return (
    <Card className='card-formulario'>
        <Card.Body className='p-0'>
            <Form className='formulario-datos-pago' onSubmit={handleSubmit(onSubmit)} >
                {
                    !isTradicional ?
                    <Row>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <h5 className='texto-subtitulo mb-3'>¿Cómo se pagaría el seguro?</h5>
                            <Form.Check
                                inline
                                type='radio'
                                name="tipoPago"
                                value="financiado"
                                className='mb-3'
                                label="Financiado"
                                {...register("tipoPago")}
                                onChange={(e) =>{ 
                                    setPagoSeguro(true);
                                    setValue("plazo", "");
                                } }
                            />
                            <Form.Check
                                inline
                                type='radio'
                                name="tipoPago"
                                value="de contado"
                                label="De contado"
                                className='ms-4'
                                {...register("tipoPago")}
                                onChange={(e) =>{ 
                                    setPagoSeguro(false);
                                    setValue("plazo", "");
                                } }
                            />
                            <p className='texto-error'>{errors.tipoPago?.message}</p>
                        </Form.Group>
                    </Row>
                    : ""
                }
                <Row> 
                    <h5 className='texto-subtitulo mb-3'>Elige un plazo a su medida:</h5>
                    <Accordion defaultActiveKey={itemOpen} alwaysOpen className='accordio-plan-pago'>
                        {plazos}
                    </Accordion>
                    
                    <p className='texto-error'>{errors.plazo?.message}</p>
                </Row>
                <Row>
                    <Col xs={12}>
                        <p className='texto-informacion text-center m-0 texto-foot-message'>{footMesage}</p>
                    </Col>
                </Row>
                <Row>
                    <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                        <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
                    </Form.Group>
                    <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                        <Button 
                            type="submit" 
                            className="btn btn-primary w-100" 
                            disabled={ (isDirty || plazoVal) ? false : true }
                        >
                            Siguiente
                        </Button>
                    </Form.Group>
                </Row>
                
            </Form>
        </Card.Body>
    </Card>
  )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
        }
	};
};

export default connect(null, mapDispatchToProps)(FormDatosPago)