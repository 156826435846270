import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from "react-redux";

import FormCotizador from '../components/FormCotizador';
import CircleStep from '../components/CircleStep';
import Footer from '../components/Footer';
import AlertaMessage from '../components/AlertaMessage';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { useNavigate } from 'react-router-dom';
import { regresarPaso, resetCotizador } from '../actions/cotizador';
import Loading from '../components/Loading';
import ModalErrores from '../components/ModalErrores';


const CotizadorScreen = (props) => {
  const { closeModal, clearCotizador } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const obtencionCotizador = useSelector((state) => state.cotizador);
  const { paso, seguroSelect } = obtencionCotizador;

  const obtencionAlerta = useSelector((state) => state.alertMessage);
  const loading = useSelector((state) => state.loading);

  const obtencionError = useSelector((state) => state.mensajeError);
  const { isError } = obtencionError;
  const { tipoError, mensajeError, seccion } = obtencionError.dataError;

  const salirCotizacion = () => {
    dispatch(showAlert(MensajesAlertas.mensajeCancelacionCotizacion, () => { closeModal(); clearCotizador(); navigate("/home"); }, () => { closeModal(); }  ));
  }
  const regresarPantalla = () => {
    const isSeguroSelec = seguroSelect.Aseguradora;
    let nuevoPaso = (paso-1);
    if(paso === 4 && (isSeguroSelec === null || isSeguroSelec === "")) {
      nuevoPaso = 2;
    }
    dispatch(regresarPaso(nuevoPaso));
  }

  return (
    <section className='seccion-cotizador'>
      <Container>
        <Row>
          <Col xs={4} className='col-menu-lateral'>
            <div className='link-inicio'>
              {
                (paso === 1) ?
                <Nav.Link className="nav-link" onClick={() => salirCotizacion()}><i className='icon-arrow-left me-2'></i>Inicio</Nav.Link>
                :
                <Nav.Link className="nav-link" onClick={() => regresarPantalla()}><i className='icon-arrow-left me-2'></i>Regresar</Nav.Link>
              }
            </div>
            <h1 className='texto-titulo'>Nueva cotización</h1>
            <ul className='opcion-menu'>
                <li>
                    <CircleStep texto="1" estatus={ paso < 5 ? "activo" : "completo"} />
                    <h3>Cotización</h3>
                </li>
                <li>
                    <CircleStep texto="2" estatus='inactivo'  />
                    <h3>Solicitud  de crédito</h3>
                </li>
            </ul>
          </Col>
          <Col xs={8} className="col-formulario">
            <div className='text-end mt-3'>
                <img src={require('../assets/images/logos/atria-color.png')} alt="Atria" className='logo-atria-color'  />
            </div>
            
              <FormCotizador />

            <Footer />
          </Col>
        </Row>
        <AlertaMessage show={obtencionAlerta.show} data={obtencionAlerta.data} />
        <Loading show={loading} />
        <ModalErrores show={isError} tipoError={tipoError} mensajeError={mensajeError} seccion={seccion} />
      </Container>
    </section>
  )
}


const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
    clearCotizador: () => {
      dispatch(resetCotizador());
    } 
	};
};
//export default CotizadorScreen
export default connect(null, mapDispatchToProps)(CotizadorScreen)
