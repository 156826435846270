import { type } from "../types/index";
import AniosService from "../services/anios.sevice";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const anios = (user, idModelo, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    dispatch({type: type.resetAnios});
    dispatch({type: type.resetVersionVehiculo});
    return AniosService.getAnios( user, idModelo, securityKey ).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.Codigo === 0) {
                const reformateo = data.Modelos.map((item) => {
                    const container = {};
                    container["label"] = item.Modelos;
                    container["value"] = item.Modelos;
                    return container;
                });
                data.Modelos = reformateo;
                dispatch({
                    type: type.getAnios,
                    payload: data
                });
                UpdateSecurityKey(data.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getAniosFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            
            dispatch({
                type: type.textMessage,
                payload: data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getAniosFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}

export const aniosSeguro = (user, idModelo, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    dispatch({type: type.resetAnios});
    dispatch({type: type.resetVersionVehiculo});
    return AniosService.getAniosSeguro( user, idModelo, securityKey ).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.Codigo === 0) {
                const reformateo = data.Modelos.map((item) => {
                    const container = {};
                    container["label"] = item.Modelos;
                    container["value"] = item.Modelos;
                    return container;
                });
                data.Modelos = reformateo;
                dispatch({
                    type: type.getAnios,
                    payload: data
                });
                UpdateSecurityKey(data.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getAniosFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            
            dispatch({
                type: type.textMessage,
                payload: data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getAniosFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
