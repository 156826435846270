import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const ModalPoliticaPrivacidad = ({ show, onHide, onClick }) => {
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered
        className='modal-alerta'
    >
        <Modal.Header closeButton onClick={onHide}></Modal.Header>
        <Modal.Body>
            <h2 className='texto-subtitulo'>AVISO DE PRIVACIDAD PARA CLIENTES</h2>
            <p className='texto-informacion text-start'>
            1. RESPONSABLE DE LOS DATOS PERSONALES<br /><br />
                ATRIALA, S.A.P.I. de C.V. (en lo sucesivo, “ATRIALA”) con domicilio en Av Paseo de las Palmas 100, colonia Lomas de Chapultepec, Alcaldía Miguel Hidalgo, Ciudad de México, C.P. 11000, en cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento, Lineamientos y demás disposiciones relativas y aplicables (en adelante, de manera conjunta, la “Ley”), es responsable de la recolección, uso, almacenamiento, tratamiento y transferencia de los datos personales que sean recabados de manera directa o indirecta, con motivo de las finalidades que se señalan en este Aviso de Privacidad, garantizando de esta forma que el tratamiento de sus datos personales se lleva a cabo de manera legítima, controlada e informada, preservando su derecho a la privacidad y a la autodeterminación informativa.<br /><br />
                2. DATOS PERSONALES<br /><br />
                De conformidad con la Ley y para efectos de este Aviso de Privacidad, se entenderán por datos personales a aquella información concerniente a una persona física identificada o identificable (los “Datos Personales”). El tipo de Datos Personales que ATRIALA podrá solicitar a sus propietarios (en lo sucesivo, el “Titular” o los “Titulares”), incluye los siguientes:<br /><br />
                Datos de identificación:<br /><br />
                Nombre, domicilio, estado civil, Clave Única de Registro de Población (“CURP”), Registro Federal de Contribuyentes (“RFC”), lugar y fecha de nacimiento, nacionalidad y género.<br /><br />
                Datos de contacto:<br /><br />
                Correo electrónico, número de teléfono celular y número de teléfono fijo.<br /><br />
                Datos académicos:<br /><br />
                Nivel de estudios.<br /><br />
                Datos laborales:<br /><br />
                Empresa o dependencia en la que labora, antigüedad, puesto, ocupación y domicilio del lugar de trabajo.<br /><br />
                Datos patrimoniales:<br /><br />
                Ingreso mensual.<br /><br />
                Información crediticia. En caso de solicitar un financiamiento se revisará su buró de crédito.<br /><br />
                2.1. DATOS PERSONALES SENSIBLES<br /><br />
                Le informamos que ATRIALA realiza el tratamiento de los siguientes datos personales sensibles:
                Datos biométricos:<br /><br />
                Huellas dactilares y reconocimiento facial.<br /><br />
                Datos de geolocalización:<br /><br />
                ATRIALA puede recopilar, almacenar o procesar cierta información de geolocalización vehicular.<br /><br />
                Tratándose de los datos personales sensibles, para su tratamiento requerimos de su consentimiento expreso y por escrito, mediante firma autógrafa o electrónica del presente Aviso de Privacidad. Por lo anterior, a la firma de este documento, usted consiente este tratamiento en cumplimiento a lo establecido por el artículo 9 de la Ley.<br /><br />
                2.2. DATOS DE TERCEROS<br /><br />
                ATRIALA solicita la siguiente información de terceros, como referencias personales de los Titulares:<br /><br />
                Nombre<br /><br />
                Número de teléfono<br /><br />
                El Titular manifiesta que previamente a compartirlos con ATRIALA, obtuvo el consentimiento por parte de estos terceros para que ATRIALA pueda dar tratamiento de dichos Datos Personales.<br /><br />
                3. FINALIDADES DEL TRATAMIENTO DE DATOS<br /><br />
                La información que sea proporcionada por el Titular a ATRIALA, conforme al presente Aviso de Privacidad, tendrá las finalidades siguientes:<br /><br />
                3.1 FINALIDADES PRIMARIAS<br /><br />
                Identificar a la persona solicitante de alguno de los productos ofertados por ATRIALA;<br /><br />
                Verificar los datos de la credencial para votar con la se identifiquen los clientes, emitida por el Instituto Nacional Electoral, mediante una validación de información con dicha institución y/o a través de proveedores, a fin de obtener una confirmación de coincidencia de datos;<br /><br />
                Verificar y validar la información proporcionada por el Titular para la contratación de productos ofertados por ATRIALA;
                Generar y organizar expedientes para el seguimiento de clientes;<br /><br />
                Llevar a cabo gestiones relativas a otorgamiento y cobro de créditos;<br /><br />
                Implementar las actividades inherentes al cobro de adeudos o retrasos en pagos;<br /><br />
                Consultar el historial crediticio del Titular ante las sociedades de información crediticia autorizadas.<br /><br />
                Gestionar la contratación de seguros por ATRIALA y/o por el Titular que sean obligatorios de conformidad con los términos de los productos o servicios.<br /><br />
                Enviar facturas al domicilio de los Titulares que lo soliciten;<br /><br />
                Realizar actividades de soporte y aclaraciones para el Titular;<br /><br />
                Llevar a cabo auditorías internas y externas;<br /><br />
                Validar las condiciones financieras de las personas que presentan adeudos o retrasos en pagos;<br /><br />
                Prevenir y detectar fraudes u otros ilícitos;<br /><br />
                Dar cumplimiento a la normatividad en materia de prevención de lavado de dinero;<br /><br />
                Confirmar la identidad del Titular mediante el tratamiento de los datos biométricos señalados anteriormente.<br /><br />
                Tratar los datos de geolocalización, para capturar y almacenar la información geográfica;<br /><br />
                Mantener la información de los Titulares debidamente actualizada;<br /><br />
                Elaborar estadísticas de carácter interno, para identificar el comportamiento de uso del sitio web de ATRIALA, así como las preferencias de consumo de los productos y servicios.<br /><br />
                El cumplimiento de leyes, reglamentos y disposiciones legales aplicables.<br /><br />
                3.2. FINALIDADES SECUNDARIAS<br /><br />
                Realizar llamadas a los Titulares que contratan un producto o servicio;<br /><br />
                Realizar encuestas sobre la calidad del servicio prestado por ATRIALA;<br /><br />
                Realizar actividades de mercadotecnia, publicidad, promoción y telemarketing, de los bienes, productos y/o servicios que sean ofrecidos por ATRIALA, por cualquier medio material y/o electrónico.<br /><br />
                En caso de que no desee que sus datos personales sean tratados para estas finalidades secundarias, podrá solicitarlo mediante el envío de un correo electrónico a la dirección servicioalcliente@atria.la<br /><br />
                4. TRANSFERENCIAS DE DATOS PERSONALES<br /><br />
                Con el propósito de dar cumplimiento a las finalidades establecidas en el presente Aviso de Privacidad, ATRIALA le informa que los Datos Personales de los Titulares podrán ser transferidos o remitidos a otras empresas distintas a ATRIALA o, en su defecto, a subsidiarias, filiales o controladoras; en este sentido, hacemos de su conocimiento que ATRIALA transfiere sus datos personales únicamente en los casos siguientes:<br /><br />
                Al Instituto Nacional Electoral y/o proveedores, para verificar la autenticidad de los datos proporcionados por el Titular respecto de su identidad.<br /><br />
                A subsidiarias, filiales o controladoras de ATRIALA, para llevar a cabo la operación y administración de los créditos contratados, de conformidad con los términos y condiciones de dichos documentos.<br /><br />
                A las Sociedades de Información Crediticia, para obtener su reporte de crédito.<br /><br />
                Al Servicio Postal Mexicano, para realizar el envío de facturas de los Titulares que así lo soliciten.<br /><br />
                A las aseguradoras contratadas por el Titular, para la elaboración de dictámenes de siniestros.<br /><br />
                A los proveedores que presten servicios a ATRIALA; incluyendo servicios de auditoría externa.<br /><br />
                A instituciones financieras, cuando ATRIALA ceda, transmita, transfiera, afecte, grave o negocie, en cualquier forma, derechos de crédito que se registren de conformidad con los términos de los contratos celebrados por los Titulares; o en los pagarés suscritos al amparo de los mismos contratos por el Titular; o cuando ATRIALA celebre fusiones o escisiones en las que el Titular se vea involucrado.<br /><br />
                A la autoridad competente, en caso de que ésta solicite legalmente sus datos personales.<br /><br />
                En caso de una fusión o escisión, a la empresa que adquiera las obligaciones legales al llevar a cabo la unión o separación de ATRIALA.<br /><br />
                Aunado a lo anterior, de acuerdo a lo dispuesto por la Ley, ATRIALA hará remisiones de los Datos Personales del Titular para cumplir las obligaciones derivadas de la relación jurídica con proveedores de servicios, o socios comerciales, con quienes ATRIALA tiene una relación jurídica, que derivado de dicha relación jurídica necesiten conocer la información y que asumen el compromiso de mantener la información bajo un estricto orden de confidencialidad y seguridad, cumpliendo los términos y condiciones del presente Aviso de Privacidad.<br /><br />
                5. DERECHOS A.R.C.O.<br /><br />
                El Titular de los Datos Personales proporcionados a ATRIALA es responsable, en todo momento, de la veracidad, exactitud, vigencia y autenticidad de la información facilitada, y se compromete a mantenerla debidamente actualizada. Asimismo, el Titular cuenta con el derecho de acceder, rectificar, cancelar u oponerse al tratamiento de sus Datos Personales (en adelante, los “Derechos ARCO”) en los casos que así lo prevé la Ley; o bien, revocar su consentimiento. El Titular deberá considerar que para ciertos fines, la revocación del consentimiento al tratamiento de sus Datos Personales, implicará que ATRIALA no pueda seguir prestando el servicio solicitado por el Titular.<br /><br />
                Si el Titular desea ejercer alguno de los Derechos ARCO, podrá realizarlo mediante escrito presentado en las oficinas de ATRIALA, ubicadas en Palmas 100, colonia Lomas de Chapultepec, Alcaldía Miguel Hidalgo, Ciudad de México, C.P. 11000, o al correo electrónico servicioalcliente@atria.la, en un horario de atención de lunes a viernes de 9:00 a 18:00 horas.<br /><br />
                La solicitud deberá considerar los siguientes requisitos:<br /><br />
                Nombre del Titular y correo electrónico para comunicarle la respuesta a su solicitud.<br /><br />
                Los documentos con los que acredite su identidad o su personalidad.<br /><br />
                La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer el derecho de acceso, rectificación, cancelación, oposición, revocar el consentimiento otorgado para el tratamiento de sus datos, así como cualquier otro dato o documento que pueda facilitar la localización de los datos personales.<br /><br />
                ATRIALA dará respuesta a la solicitud dentro de los veinte días siguientes contados a partir de que reciba la solicitud del Titular, plazo que podrá ser ampliado de acuerdo a lo dispuesto por el artículo 32 de la Ley.<br /><br />
                La obligación de acceso a la información se dará por cumplida cuando se pongan a disposición del Titular los Datos Personales; o bien, mediante la expedición de documentos electrónicos, conforme al procedimiento correspondiente.<br /><br />
                6. USO DE COOKIES, WEB BEACONS Y OTRAS TECNOLOGÍAS<br /><br />
                El portal de Internet de ATRIALA www.atria.la (en adelante, el “Portal”), emplea cookies, web beacons y otras tecnologías, los cuales son archivos de datos que se almacenan en su disco duro al navegar en el Portal y que permiten intercambiar información del estado del Portal y el navegador del Titular. A través de las cookies, ATRIALA mide la información sobre el Titular que visita el Portal, información geográfica desde la que el Titular ingresa al Portal, así como el sistema operativo y el navegador. De igual forma, ATRIALA recaba el promedio de tiempo de navegación y las estadísticas de las páginas más visitadas del Titular. ATRIALA obtiene los datos antes referidos con la finalidad de conocer el comportamiento del Titular que son impactados mediante canales digitales externos al Portal o que entran de forma directa. El Titular podrá desactivar o bloquear el uso de cookies, web beacons y otras tecnologías, para lo cual deberá hacerlo de conformidad con las instrucciones señaladas por cada navegador o plataforma en la que el Titular acceda al Portal.<br /><br />
                7. MODIFICACIONES AL AVISO DE PRIVACIDAD<br /><br />
                Cualquier cambio o actualización en el presente Aviso de Privacidad, podrá ser consultado directamente en el Portal, en la sección “Aviso de Privacidad”.<br /><br />
                En caso de que el presente Aviso de Privacidad sea modificado respecto del tratamiento de sus datos personales sensibles, ATRIALA se pondrá en contacto con el Titular a fin de obtener nuevamente su consentimiento expreso y por escrito, en cumplimiento a la Ley.<br /><br />
                8. AUTORIDAD DE PROTECCIÓN DE DATOS<br /><br />
                Si el Titular considera que su derecho a la protección de datos personales ha sido lesionado por alguna conducta u omisión de parte de ATRIALA, podrán acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI), mediante su página oficial de Internet www.inai.org.mx.<br /><br />
                9. CONSENTIMIENTO EXPRESO<br /><br />
                El Titular de los Datos Personales proporcionados a ATRIALA, manifiesta que ha leído y entendido el presente Aviso de Privacidad, aceptando las categorías de Datos Personales recabados, incluidas las categorías relativas a datos patrimoniales y datos personales sensibles, así como las finalidades para el tratamiento de sus Datos Personales, otorgando en este momento de manera libre e informada su consentimiento expreso.<br /><br />
                Fecha de actualización: 17 de junio de 2022
            </p>
            <Row className='justify-content-center'>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-light w-100" onClick={onHide} >Cancelar</Button>
                </Form.Group>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-primary w-100" onClick={onClick} >Acepto</Button>
                </Form.Group>
            </Row>
        </Modal.Body>
    </Modal>
  )
}

export default ModalPoliticaPrivacidad