export const solicitud = {
	"Aseguradora": "",
	"Ciudad": "",
	"CiudadTrabajo": "",
	"Down_Payment": null,
	"EstadoCC": "",
	"EstadoTrabajoCC": "",
	"Financiado": true,
	"Insurance": false,
	"Insurance_Amount": null,
	"Monthly_Amount": null,
	"MontoSeguro": "",
	"RespuestaCCTipo": "",
	"SeguroFinanciado": false,
	"Term": null,
	"analista": null,
	"aniosDomicilio": "",
	"antiguedadAnios": "",
	"antiguedadMeses": 0,
	"apellidoMaterno": "",
	"apellidoPaterno": "",
	"calle": "",
	"calleEmpresa": "",
	"canal": "",
	"carrierCelular": "",
	"circuloCredito": true,
	"clave": "",
	"codigoPostal": "",
	"codigoPostalEmpresa": "",
    "codigoVerificacion": "",
	"codigoValidacion1": "",
	"codigoValidacion2": "",
	"codigoVerificacion1": "",
	"colonia": "0",
	"coloniaEmpresa": "0",
	"coloniaEmpresaStr": "0",
	"coloniaStr": "0",
	"comision_por_apertura": 0,
	"comprobanteIngresos": false,
	"consecutivo": null,
	"costoAuto": 0,
	"cuenta_con_vehiculo": "",
	"dependientes_economicos": "",
	"distribuidor": "",
	"email": "",
	"enganche": 0,
	"establecimientoFijo": "",
	"estado": "",
	"estadoEmpresa": "",
	"estado_civil": "",
	"estatus": null,
	"estatusDescripcion": null,
	"etapaProceso": 0,
	"fase": null,
	"fechaCreacion": "",
	"fechaNacimiento": "",
	"fechaRecepcion": null,
	"fechaRespuesta": null,
	"folioConsultaCC": "",
	"folioConsultaOtorganteCC": "",
	"genero": "",
	"historial": false,
	"id": 0,
	"idCotizacion": "",
	"idDescripcion": "",
	"idEstatus": null,
	"idFase": null,
	"idMarca": "",
	"independiente": false,
	"ingresos": "",
	"intormacionAdicionalPersonal": "",
	"ip_dispositivo": "0.0.0.0",
	"latitud": null,
	"longitud": null,
	"marcaVehiculo": "",
	"mensaualidad": 0,
	"mesesDomicilio": 0,
	"modelo": 0,
	"modeloAuto": "",
	"montoAutorizado": null,
	"montoSolicitado": null,
	"montonFinanciar": "",
	"motivo": null,
	"motivoDescripcion": null,
	"municipio": "",
	"municipioEmpresa": "",
	"nivel_de_estudios": "",
	"noExterior": "",
	"noExteriorEmpresa": "",
	"noInterior": "",
	"noInteriorEmpresa": null,
	"nombre": "",
	"nombreEmpresa": "",
	"nombreReferencia1": "",
	"nombreReferencia2": "",
	"nombreReferencia3": "",
	"numeroCliente": null,
	"paso": 1,
	"perfil": null,
	"plazo": 0,
	"porcentaje_comision_por_apertura": "",
	"promocion": null,
	"proveedor": "",
	"responseUpdateCC": null,
	"respuesta": null,
	"respuestaCC": null,
	"respuestaCirculoCredito": null,
	"respuestaCotizadorfinish": null,
	"resultadoImporte": "",
	"rfc": "",
	"segundoNombre": "",
	"solicitudBinarizada": null,
	"sucursal": "",
	"tablaAmortizacion": null,
	"telefonoEmpresa": "",
	"telefonoFijo": "",
	"telefonoMovil": "",
	"telefonoReferencia1": "",
	"telefonoReferencia2": "",
	"telefonoReferencia3": "",
	"terminosCondiciones": false,
	"tipoComprobanteIngresos": "",
	"tipoVehiculo": "",
	"tipoVivienda": "",
	"token": null,
	"tokenFirebase": null,
	"totalPasos": 7,
	"trabajo": null,
	"trabajoId": "",
	"userAgent": "",
	"usuario": "",
	"valorAuto": "",
	"vendedor": "",
	"version": "",
	"versionVehiculo": "",
    "llaveSeguridad": "",
	"terminoServicios": false,
	"avisoPrivacidad": false,
	"segundaActividad": false,
	"ingresosSegundaActividad":"",
	"idCotizacionSeguro": "",
	"idCotizaciones": [],
	"partner": "",
	"engancheInicial": "",
	"clave_de_institucion": ""
}