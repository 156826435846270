import { Navigate } from "react-router-dom";
import { UpdateSecurityKey } from "../../helpers/SecurityKey";
import { MensajesAlertas } from "../../models/MensajesAlertas";
import cotizaSegurosService from "../../services/cotiza.seguros.service";
import { type } from "../../types/index";
import { cotizadorPaso1, terminaCotizacionSeguros } from "./cotizadorSeguros";

export const getSeguros = ( IdDescripcion, CP, anio, usuario, securitykey, dataForm, combos ) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    console.log(combos);
    console.log(dataForm);
    const { marca, modelo} = dataForm;
    const { versionText } = combos;
    return cotizaSegurosService.getCotizaSegurosSeguro( IdDescripcion, CP, parseInt(anio), marca, modelo, usuario, securitykey, versionText ).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(isNaN(data)) {
                if(data.codigo === 0 || data.codigo === 1) {
                
                    dispatch({
                        type: type.getCotizaSeguros,
                        payload: data
                    });
                    if(data.CotizaSeguro.length > 0) {
                        dispatch(cotizadorPaso1(dataForm,combos));
                    } else {
                        dispatch(terminaCotizacionSeguros(null, IdDescripcion, null, anio, null, null, null, CP, null));
                    }
                    UpdateSecurityKey(data.llaveSeguridad);
                } else {
                    
                    dispatch({
                        type: type.getCotizaSegurosFail
                    });
    
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.Mensaje
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.getCotizaSegurosFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            

            return Promise.resolve(data);
        }
    )
}