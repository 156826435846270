import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'

import HomeScreen from '../pages/HomeScreen';
import SolicitudCreditoScreen from '../pages/SolicitudCreditoScreen';
import CotizadorScreen from '../pages/CotizadorScreen';
import DetalleSolicitudScreen from '../pages/DetalleSolicitudScreen';
import CotizadorSegurosScreen from '../pages/CotizadorSegurosScreen';
import SolicitudSeguroScreen from '../pages/SolicitudSeguroScreen';
import DetalleSeguroScreen from '../pages/DetalleSeguroScreen';

const AuthRouter = () => {
  return (
    <Routes>
        <Route end path="/home" element={<HomeScreen />} />
        <Route end path='/cotizador' element={<CotizadorScreen />} />
        <Route end path='/solicitud' element={<SolicitudCreditoScreen />} />
        <Route end path='/detalle-solicitud/:id' element={<DetalleSolicitudScreen />} />
        <Route end path='/cotizador-seguro' element={<CotizadorSegurosScreen />} />
        <Route end path='/solicitud-seguro' element={<SolicitudSeguroScreen />} />
        <Route end path='/detalle-seguro/:id' element={<DetalleSeguroScreen />} />

        <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  )
}

export default AuthRouter