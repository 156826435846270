import { type as types } from "../types/index";

const initialState = {isCatalogo: false, catalogos: {}};

export const CatalogosReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (action.type) {
        case types.getCatalagos:
            const result = {
                ...state, 
                isCatalogo: true,
                catalogos: payload
            };
            return result;
        case types.getCatalagosFail:
            return {
                ...state,
                isCatalogo: false,
                catalogos: null
            }
        default:
            return state;
    }
}
