import { type } from "../types/index";
import GetScoreResultService from "../services/get.score.result.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { updateDataSolicitud } from "./solicitudes";

export const getverificacionCodigo = (data, codigo, dataSolicitud) => (dispatch) => {
    const { terminoServicios, codigoVerificacion } = data;

    if(codigo !== codigoVerificacion ) {
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "El código de verificación es incorrecto. Revisa la información."
            }
        })
    } else {
        dataSolicitud.codigoVerificacion1 = parseInt(codigoVerificacion);
        //dataSolicitud.terminoServicios = terminoServicios;
        dataSolicitud.paso = 4;
        dispatch(updateDataSolicitud(dataSolicitud));
    }
}


export const getScoreResult = (usuario, securitykey, distribuidor, id, nip, sucursal, data, dataUser, pantallaCredito) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const { codigoVerificacion, avisoPrivacidad, terminoServicios } = data;
    const { codigoValidacion1 } = dataUser;
    const userAgent = window.navigator.userAgent;
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        distribuidor: distribuidor,
        id: id,
        nip: nip,
        sucursal: sucursal,
        llaveSeguridad: securitykey,
        pantalla_credito: pantallaCredito
    }

    dataUser.terminoServicios = terminoServicios;
    dataUser.avisoPrivacidad = avisoPrivacidad;
    dispatch(updateDataSolicitud(dataUser));

    if(codigoVerificacion !== codigoValidacion1) {
        dispatch({type: type.setLoading});
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "El código de verificación es incorrecto. Revisa la información."
            }
        })
        return false;
    } else {
        return GetScoreResultService.getScoreResult(dataRequest).then(
            (data) => {
                dispatch({type: type.setLoading});
                const { codigo, llaveSeguridad } = data;
                if(codigo === 0) {
                    dispatch({
                        type: type.getScoreResult,
                        payload: data
                    });
                    UpdateSecurityKey(llaveSeguridad);
                    if( data.response_cc === 1) {
                        
                        dispatch({
                            type: type.showModalSucess,
                            payload: {
                                mensaje1: data.respuesta,
                                mensaje2: data.respuesta_secundaria
                            }
                        });

                    } else {
                        dispatch({
                            type: type.setError,
                            payload: {
                                tipoError: -1,
                                mensajeError: data.respuesta
                            }
                        });
                    }

                } else {
                    dispatch({
                        type: type.getScoreResultFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.respuesta
                        }
                    });
                }
                dispatch({
                    type: type.textMessage,
                    payload: data
                });
                return Promise.resolve(data);
            },
            (error) => {
                const message = (error.response && error.response.data && error.response.data.message) ||
                error.message || error.toString();
                dispatch({type: type.setLoading});
                dispatch({
                    type: type.getScoreResultFail
                });
                dispatch({
                    type: type.textMessage,
                    payload: message
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 0,
                        mensajeError: message
                    }
                });
                return Promise.reject(error);
            }
        )
   }
}
