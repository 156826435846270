import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const ModalTerminosServicio = ({ show, onHide, onClick }) => {
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered
        className='modal-alerta'
    >
        <Modal.Header closeButton onClick={onHide}></Modal.Header>
        <Modal.Body>
            <h2 className='texto-subtitulo'>TÉRMINOS Y CONDICIONES</h2>
            <p className='texto-informacion text-start'>
            Por este medio electrónico autorizo por una sola ocasión a Atriala, S.A.P.I. de C.V. y LGF Occidente, S.A. de C.V. SOFOM E.N.R., para que por conducto de sus funcionarios facultados, lleve a cabo investigaciones sobre mi comportamiento crediticio en las sociedades de información crediticia que estime conveniente, lo anterior por haber proporcionado los datos que identifica al suscrito de conformidad con las Reglas generales a las que deberán sujetarse las operaciones y actividades de las sociedades de información crediticia y sus usuarios. Así mismo declaro conocer la naturaleza y alcance de la información que solicitará dicha empresa a las sociedades de Información Crediticia. Estoy consciente y acepto expresamente que este archivo electrónico y/o medio electrónico y/o mensaje de datos quede bajo propiedad de Atriala, S.A.P.I. de C.V. y/o LGF Occidente, S.A. de C.V. SOFOM E.N.R. y/o cualquier Sociedad de Información Crediticia a quien solicite el servicio de información crediticia, para efectos de control y cumplimiento de los artículos 28 y 30 de la Ley para regular a las Sociedades de Información Crediticias.
            </p>
            <Row className='justify-content-center'>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-light w-100" onClick={onHide} >Cancelar</Button>
                </Form.Group>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-primary w-100" onClick={onClick} >Acepto</Button>
                </Form.Group>
            </Row>
        </Modal.Body>
    </Modal>
  )
}

export default ModalTerminosServicio