import { type as types } from "../types/index";

const initialState = {isJobs: false, dataJobs: []};

export const JobsReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (action.type) {
        case types.getJobs:
            const result = {
                ...state, 
                isJobs: true,
                dataJobs: payload
            };
            return result;
        case types.getJobsFail:
            return {
                ...state,
                isJobs: false,
                dataJobs: null
            }
        default:
            return state;
    }
}
