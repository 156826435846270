import React, { useEffect } from 'react'
import { Card, Col, Nav, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { FormatoCantidad } from '../helpers/functionGlobal';
import logoAna from '../assets/images/logos/ana.svg';
import { getSecurityKey } from '../helpers/SecurityKey';
import { getPurchaseDetail } from '../actions/purchaseDetail';

const DetalleDelPlan = () => {

  const dispatch = useDispatch();

  const securityKey = getSecurityKey();

  const obtencionSolicitud = useSelector((state) => state.solicitudes);
  const { mensaualidad, costoAuto, enganche, resultadoImporte, plazo,
    solicitudBinarizada, tablaAmortizacion, Aseguradora, Insurance_Amount,
    comision_por_apertura, porcentaje_comision_por_apertura, Financiado,
    Insurance, consecutivo
  } = obtencionSolicitud.dataSolicitud;

  const obtencionPurchase = useSelector((state) => state.purchaseDetail);
  const { poliza, contrato } = obtencionPurchase.dataPurchaseDetail
  const dataUser = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getPurchaseDetail(dataUser.user.correo, consecutivo, securityKey));
  }, []);

  return (
    <div className='seccion-detalle-plan'>
      <Col xs={12} className="d-flex" >
        {
          poliza ? 
          <Nav.Link href={poliza} target="_blank" className='me-3'>Descargar contrato</Nav.Link> 
          : ""
        }
        {
          contrato ? 
            <Nav.Link href={contrato} target="_blank" className='ms-3'>Descargar póliza</Nav.Link>
          : ""
        }
        
      </Col>
      <Card className='card-plan-cotizacion'>
        <Card.Body >
          <p className='tipo-pago'>Mensualidad</p>
          <p className='monto'>${FormatoCantidad(mensaualidad, 2, ".", ",")}</p>
          <p className='mensualidades'>a {FormatoCantidad(plazo, 0, ".", ",")} meses</p>
          <Row>
              <Col xs={6} className="mb-3">
                  <p className='texto-label'>Costo del auto</p>
                  <p className='texto-resultado'>${FormatoCantidad(costoAuto, 2, ".", ",")}</p>
              </Col>
              <Col xs={6} className="mb-3">
                  <p className='texto-label'>Enganche</p>
                  <p className='texto-resultado'>${FormatoCantidad(enganche, 2, ".", ",")}</p>
              </Col>
              <Col xs={6} className="mb-3">
                  <p className='texto-label'>Monto a financiar</p>
                  <p className='texto-resultado'>${FormatoCantidad(resultadoImporte, 2, ".", ",")}</p>
              </Col>
              {
                Aseguradora !== null && Aseguradora!=="" &&
                <Col xs={6} className="mb-3">
                  <p className='texto-label'>Comisión por apertura {porcentaje_comision_por_apertura}</p>
                  <p className='texto-resultado'>${FormatoCantidad(comision_por_apertura, 2, ".", ",")}</p>
              </Col>
              }
              {
                Aseguradora !== null && Aseguradora!=="" &&
                <Col xs={12} >
                  <p className='texto-label'>{ Insurance ? "Seguro Financiado" : "Seguro contratado" } 
                    {
                      Aseguradora !== null && Aseguradora!=="" && <img src={logoAna} className="ms-2 logo-seguro" alt={Aseguradora}  />
                    }
                    
                  </p>
                  <p className='texto-resultado'>${ Insurance_Amount !== null ? FormatoCantidad(Insurance_Amount, 2, ".", ",") : "0.0"}</p>
                </Col>
              }
              <Col xs={12} className="d-flex mt-4" >
                {
                  solicitudBinarizada ? 
                  <Nav.Link href={solicitudBinarizada} target="_blank" className='me-3'>Descargar solicitud</Nav.Link> 
                  : ""
                }
                {
                  tablaAmortizacion ? 
                    <Nav.Link href={tablaAmortizacion} target="_blank" className='ms-3'>Descargar tabla de amortización</Nav.Link>
                  : ""
                }
              </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
    
  )
}

export default DetalleDelPlan