import { type } from "../types/index";
import SendCallService from "../services/send.call.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { updateDataSolicitud } from "./solicitudes";

export const sendCall = (usuario, securitykey, telefono, firstName, mensajeClave) => (dispatch) => {
    dispatch({type: type.clearMessage});
    const userAgent = window.navigator.userAgent;
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        telefono: telefono,
        firstName: firstName,
        mensajeClave: String(mensajeClave),
        llaveSeguridad: securitykey
    }
    return SendCallService.sendCall(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { Codigo, llaveSeguridad } = data;
            if(Codigo === 0 || Codigo === undefined) {
                dispatch({
                    type: type.sendCall,
                    payload: data
                });
                dispatch(updateDataSolicitud({paso: 3}));
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.sendCallFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.sendCallFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
    .catch((error)=>{
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "Hay un problemas con el servicio intente mas tarde"
            }
        });
    });
}
