import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getSecurityKey } from '../../helpers/SecurityKey';
import { obtencionSeguros } from '../../actions/seguros/obtencionSeguros';
import { FormatoCantidad, formatDate } from '../../helpers/functionGlobal';
import { Card, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resetFormSolicitud, retomarSolicitud } from '../../actions/seguros/solicitudSeguros';

const Seguros = ({actualizaSolicitud}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const securityKey = getSecurityKey();
  

  const dataUser = useSelector((state) => state.auth);
  const sucursalSelec = useSelector((state) => state.sucursalSelection);
  
  const obtencionSolicitudes = useSelector((state) => state.solicitudes);
  const { dataSolicitudes} = obtencionSolicitudes;

  const obtencionSolicitudesSeguros = useSelector((state) => state.obtencionSolicitudSeguros);
  const { isSolicitudesSeguros, dataSolicitudes: dataSolicitudesSeguros } = obtencionSolicitudesSeguros;

  useEffect(() => {
    const { user } = dataUser;
    const { Clave, CveDistribuidor, Vendedor } = sucursalSelec.sucursalSeleccionada;
    dispatch(obtencionSeguros(user.correo, securityKey, CveDistribuidor, Clave));
    dispatch(resetFormSolicitud());
  }, [dispatch, dataUser, sucursalSelec, actualizaSolicitud]);

  const terminarSolicitud = (solicitud) => {
    const { idFase, id } = solicitud;
    console.log("idFase: "+idFase);

    if(parseInt(idFase) === 10 || parseInt(idFase) === 11 || parseInt(idFase) === 12 || 
      parseInt(idFase) === 13 || parseInt(idFase) === 21 ||
      parseInt(idFase) === 110 || parseInt(idFase) === 111 || parseInt(idFase) === 20
    ) {
      dispatch(retomarSolicitud(solicitud));
      navigate("/solicitud-seguro");
    } else if(parseInt(idFase) === 30 || parseInt(idFase) === 40 || parseInt(idFase) === 50 ||
      parseInt(idFase) === 100 || parseInt(idFase) === 101 || parseInt(idFase) === 103 ||
      parseInt(idFase) === 112 || parseInt(idFase) === 200 || parseInt(idFase) === 210 ) {
        navigate(`/detalle-seguro/${id}`);
    }
  };

  const listBody = isSolicitudesSeguros ? dataSolicitudesSeguros.map((solicitud, index) => {
    const { nombre, created, id, idFaseStr, idFaseColor, folio, get_poliza, primerNombre, segundoNombre } = solicitud;
    return(
      <tr key={index} onClick={() => terminarSolicitud(solicitud)}>
        <td>{`${primerNombre} ${ segundoNombre !== null ? segundoNombre : "" }`}</td>
        <td>{folio}</td>
        <td>
          <span className='pill' style={{background: idFaseColor}}>{idFaseStr}</span>
          {
            /*get_poliza !== null &&
            <p className='mt-1 mb-0'><a href={get_poliza} className='texto-liga liga-poliza' >Descargar poliza</a></p>
            */
          }
          
          {/*<p className='detalle-estatus'>Validación de documentos</p>*/}
        </td>
        <td>{formatDate(created)}</td>
      </tr>
    );
  }) : <tr >
      <td colSpan={4}>No hay Solicitudes</td>
  </tr>;
  return (
    <>
      <h2 className='texto-subtitulo'>Seguros</h2>
      <p className='texto-informacion mb-3'>{ dataSolicitudesSeguros ? FormatoCantidad(dataSolicitudesSeguros.length, 0, "",",") : 0 }</p>

      {/*<div className='row'>
        <div className='col-4'>
          <div className='card-insurance aprobada'>
            <div className='d-flex justify-content-between'>
              <span className='texto-numero'>3</span>
              <i className='icon-checkmark'></i>
            </div>
            
            <p className='m-0'>Cotizaciones aprobadas</p>
          </div>
        </div>
        <div className='col-4'>
          <div className='card-insurance rechazadas'>
            <div className='d-flex justify-content-between'>
              <span className='texto-numero'>13</span>
              <i className='icon-rejected'></i>
            </div>
            <p className='m-0'>Cotizaciones rechazadas</p>
          </div>
        </div>
        <div className='col-4'>
          <div className='card-insurance'>
            <div className='d-flex justify-content-between'>
              <span className='texto-numero'>6</span>
              <i className='icon-visto-recientemente'></i>
            </div>
            <p className='m-0'>Cotizaciones en proceso</p>
          </div>
        </div>
  </div>*/}

      <Card className='card-tabla mt-4 pt-2'>
        <Card.Body>
          <Table className='tabla-seguros'>
            <thead>
              <tr>
                  <th>Nombre</th>
                  <th>Folio</th>
                  <th>Estado</th>
                  <th>Fecha</th> 
              </tr>
            </thead>
            <tbody>
              {/*<tr onClick={() => verDetalleSeguro(1)}>
                <td>Vannessa</td>
                <td>999920230300004</td>
                <td>
                  <span className='pill'>En proceso</span>
                  <p className='detalle-estatus'>Validación de documentos</p>
                </td>
                <td>06-03-2023</td>
              </tr>
              <tr className="aprobada">
                <td>Eduardo</td>
                <td>999920230300003</td>
                <td>
                  <span className='pill'>En proceso</span>
                  <p className='detalle-estatus'>Lista para biométrico</p>
                </td>
                <td>06-03-2023</td>
              </tr>
              <tr className="aprobada">
                <td>Test</td>
                <td></td>
                <td>
                  <span className='pill'>En proceso</span>
                  <p className='detalle-estatus'>Lista para biométrico</p>
                </td>
                <td>01-03-2023</td>
              </tr>
              <tr className="rechazada">
                <td>Eduardo</td>
                <td>999920230300002</td>
                <td>
                  <span className='pill'>Rechazada</span>
                </td>
                <td>01-03-2023</td>
              </tr>
              <tr >
                <td>Eduardo</td>
                <td>999920230300001</td>
                <td>
                  <span className='pill'>En proceso</span>
                  <p className='detalle-estatus'>Validación de documentos</p>
                </td>
                <td>01-03-2023</td>
  </tr>*/}
              {listBody}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      
    </>
  )
}

export default Seguros