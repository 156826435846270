import React, { useEffect } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { resetFormSolicitud, solicitudPaso3 } from '../../actions/seguros/solicitudSeguros';
import { connect, useDispatch, useSelector } from 'react-redux';
import { getSecurityKey } from '../../helpers/SecurityKey';
import { validExpresionRegular } from '../../helpers/methodValidate';
import { resetCotizadorSeguro } from '../../actions/seguros/cotizadorSeguros';
import { hideAlert, showAlert } from '../../actions/alertMessage';
import { MensajesAlertas } from '../../models/MensajesAlertas';
import { useNavigate } from 'react-router-dom';

const FormContactoSeguro = (props) => {

    const { closeModal, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const securityKey = getSecurityKey();

    const dataUser = useSelector((state) => state.auth);
    const obtencionSolicitud = useSelector((state) => state.solicitudSeguro);
    const { telefonoCelular, telefonoFijo, correo, id } = obtencionSolicitud;

    const validateDomicilio = Yup.object({
        numeroCelular: Yup.string().required('El campo es requerido').nullable().matches(validExpresionRegular.numeros, "Introduzca solo números").min(10, 'Introduzca al menos ${min} caracteres'),
        telefonoFijo: Yup.string().required('El campo es requerido').nullable().matches(validExpresionRegular.numeros, "Introduzca solo números").min(10, 'Introduzca al menos ${min} caracteres'),
        correoElectronico: Yup.string().required('El campo es requerido').nullable().email('Ingrese un email válido')
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validateDomicilio),
        mode: "onChange" 
    });
    const onSubmit = (data) => {
        const { correo } = dataUser.user;
        dispatch(solicitudPaso3(data, correo, securityKey, id));
    };

    const salirSolicitud = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); } ));
    }

    useEffect(() => {
        setValue("numeroCelular", telefonoCelular);
        setValue("telefonoFijo", telefonoFijo);
        setValue("correoElectronico", correo);
      }, []);
    return (
        <Form className='formulario-domicilio' onSubmit={handleSubmit(onSubmit)}>
            <Card className='card-formulario'>
                <Card.Title className='my-3'><i className='icon-phone me-3'></i>Contacto</Card.Title>
                <Card.Body className='p-0'>
                    <Row>
                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Número celular</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="numeroCelular" 
                                placeholder="Escribe un teléfono de 10 dígitos"
                                maxLength={10}
                                autoComplete="nope"
                                className={`${errors.numeroCelular ? 'is-invalid' : ""}`}
                                {...register('numeroCelular')}
                            />
                            <p className='texto-error'>{errors.numeroCelular?.message}</p>
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Teléfono fijo</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="telefonoFijo" 
                                placeholder="Escribe un teléfono fijo"
                                maxLength={10}
                                autoComplete="off"
                                className={`${errors.telefonoFijo ? 'is-invalid' : ""}`}
                                {...register('telefonoFijo')}
                            />
                            <p className='texto-error'>{errors.telefonoFijo?.message}</p>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Correo electrónico</Form.Label>
                            <Form.Control 
                                type="email" 
                                name="correoElectronico" 
                                placeholder="mail@correo.com"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.correoElectronico ? 'is-invalid' : ""}`}
                                {...register('correoElectronico')}
                            />
                            <p className='texto-error'>{errors.correoElectronico?.message}</p>
                        </Form.Group>
                    </Row>
                    <Row className='mt-5'>
                        <Col xs={6} className='px-2'>
                            <Button type="button" className="btn btn-light w-100" onClick={salirSolicitud}>Cancelar</Button>
                        </Col>
                        <Col xs={6} className='px-2'>
                            <Button type="submit" className="btn btn-primary w-100" >Siguiente</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Form>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetFormSolicitud());
            dispatch(resetCotizadorSeguro());
        } 
	};
};
export default connect(null, mapDispatchToProps)(FormContactoSeguro);