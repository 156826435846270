import {axiosInstance, axiosInstanceSeguros } from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const getCotizaSeguro = (IdDescripcion, CP, anio, usuario, securitykey) => {
  return axiosInstance
    .post("getCotizaSegurov2/", {
      userAgent: userAgent,
      carrierCelular: userAgent,
      Cotizacion: {
        Inicial: "",
        PagoMensual: "",
        Plazo: "",
        SeguroFinanciado: "",
      },
      Auto: {
        IdDescripcion: "",
        Modelo: "",
      },
      IdDescripcion: IdDescripcion,
      CP: CP,
      Modelo: anio,
      usuario: usuario,
      canal: "3",
      version: "2.0.0",
      llaveSeguridad: securitykey,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error");
      console.log(err.message);
    });
};

const getCotizaSegurosSeguro = (IdDescripcion, CP, anio, marca, subMarca, usuario, securitykey, Version) => {
  const headers = {
    'Authorization': `Bearer ${securitykey}`
  }
  return axiosInstanceSeguros
    .post("CotizaSeguroAuto/", {
      userAgent: userAgent,
      carrierCelular: userAgent,
      canal: "3",
      version: "1.4",
      usuario: usuario,
      idDescripcion: IdDescripcion,
      marca: marca,
      submarca: subMarca,
      codigo_postal: CP,
      MODELO: anio,
      Version: Version
    }, { headers: headers } )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err.response.status;
    });
};

export default { getCotizaSeguro, getCotizaSegurosSeguro };
