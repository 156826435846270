import {axiosInstance} from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const getPutCotiza = (Inicial, PagoMensual, Plazo, SeguroFinanciado, IdDescripcion, Modelo, CP, usuario, 
    llaveSeguridad, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes,Auto,seguroSelect,
    clave_de_institucion, Enganche_str, Nombre, Perfil, porcentajeEnganche, enable, Financiamiento, 
    Financiamiento_str, Montos, Pagomensual_str, tasa_global, ValorAuto_str, Clave, CveDistribuidor, engancheInic
    ) => {
    return axiosInstance.post('putCotiza/', {
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "Cotizacion": {
            "clave_de_institucion": clave_de_institucion,
            "enable": enable,
            "Enganche": Enganche,
            "Enganche_str": Enganche_str,
            "Financiamiento": Financiamiento,
            "Financiamiento_str": Financiamiento_str,
            "Montos": Montos,
            "Nombre": Nombre,
            "Pagomensual_str": Pagomensual_str,
            "Perfil": Perfil,
            "tasa_global": tasa_global,
            "ValorAuto": ValorAuto,
            "ValorAuto_str": ValorAuto_str,
            "Inicial": Inicial,
            "PagoMensual": PagoMensual,
            "Plazo": Plazo,
            "SeguroFinanciado": SeguroFinanciado,
            "id_aseguradora": seguroSelect.id_aseguradora,
        },
        "Auto": {
            "IdDescripcion":IdDescripcion,
            "Modelo": Modelo,
            "Marca":Auto.MarcaText,
            "Version":Auto.IdDescripcionText,
            "Submarca":Auto.ModeloText,
        },
        "Clave": Clave,
        "CveDistribuidor": CveDistribuidor,
        //"IdDescripcion": IdDescripcion,
        "CP": CP,
        "Modelo": Modelo,
        "usuario": usuario,
        "canal": "3",
        "version": "2.0.0",
        "llaveSeguridad": llaveSeguridad,
        "IdCotizacionSeguro": IdCotizacionSeguro,
        "ValorAuto": ValorAuto,
        //"Plazo": "",
        "Enganche": engancheInic,
        "Historial": Historial,
        "Comprobantes": Comprobantes
    }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

export default { getPutCotiza };