import { type as types } from "../types/index";

const initialState = {};

export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.textMessage:
            return { message: payload };

        case types.clearMessage:
            return { message: "" };
        default:
            return state;
    }
}