import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'

const ModalError = ({ show, onHide }) => {
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered

        className='modal-error'
    >
        <Modal.Header closeButton onClick={onHide}></Modal.Header>
        <Modal.Body>
            <h4 className='texto-error'>Uy, algo pasó aquí...</h4>
            <p>Código Postal inexistente. Inténte con otro Código Postal.</p>
      </Modal.Body>
    </Modal>
  )
}

export default ModalError