import { type } from "../types/index.js";


export const showAlert = (message, eventoPrimary, eventoSecundary, textoCustom, eventoHeader) => (dispatch) => {
    if(eventoPrimary !== undefined && eventoPrimary !== ""  ) {
        message.buttonPrimary.onClick = eventoPrimary;
    }
    if(eventoSecundary !== undefined && eventoSecundary !== "") {
        message.buttonSecond.onClick = eventoSecundary;
    }
    if(textoCustom !== undefined ) {
        message.textoCustom = textoCustom;
    }
    if(eventoHeader !== undefined && eventoHeader !== "") {
        message.buttonHeader = eventoHeader;
    }

    dispatch({
        type: type.showAlertMessage,
        payload: message
    });
}

export const hideAlert = () => (dispatch) => {
    dispatch({
        type: type.hideAlertMessage
    });
}
