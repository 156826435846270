import {axiosInstance} from "../helpers/axios";

const getVersionVehiculo = (user, id, modelo, idMarca, securitykey) => {
  return axiosInstance
    .post("getDescripcionesv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      ID: id,
      ID_MARCA: idMarca,
      modelo: modelo,
      llaveSeguridad: securitykey,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error");
      console.log(err.message);
    });
};

const getVersionVehiculoSeguros = (user, id, modelo, idMarca, securitykey) => {
  return axiosInstance
    .post("getDescripcionesv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      ID: id,
      ID_MARCA: idMarca,
      modelo: modelo,
      llaveSeguridad: securitykey,
      modulo: 2
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error");
      console.log(err.message);
    });
};

export default { getVersionVehiculo, getVersionVehiculoSeguros };
