import { type as types } from "../types/index";

export const LoadingReducer = (state = false, action) => {

    const {type, payload } = action;
    
    switch (action.type) {
        case types.setLoading:
            return !state;
        default:
            return state;
    }
}
