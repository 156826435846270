import {axiosInstance, axiosInstanceSeguros } from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const createInsurance = ( request, securitykey) => {
  const headers = {
    'Authorization': `Bearer ${securitykey}`
  }
  return axiosInstanceSeguros
    .post("createInsuranceRequest/", request, { headers: headers } )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err.response.status;
    });
};

const updateInsurance = ( request, securitykey) => {
  const headers = {
    'Authorization': `Bearer ${securitykey}`
  }
  return axiosInstanceSeguros.post("updateInsuranceRequest/", request, { headers: headers } )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err.response.status;
  });
};

const listInsurance = ( request, securitykey) => {
  const headers = {
    'Authorization': `Bearer ${securitykey}`
  }
  return axiosInstanceSeguros.post("listInsuranceRequest/", request, { headers: headers } )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err.response.status;
  });
};

const  uploadDocument = ( usuario, securitykey, request) => {
  const headers = {
    'Authorization': `Bearer ${securitykey}`
  }
  return axiosInstanceSeguros.post("uploadDocument/", request, { headers: headers } )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err.response.status;
  });
};

const getInsurance = ( usuario, securitykey, request) => {
  const headers = {
    'Authorization': `Bearer ${securitykey}`
  }
  return axiosInstanceSeguros.post("getInsuranceRequest/", request, { headers: headers } )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err.response.status;
  });
};

const createInsuranceDocument = ( usuario, securitykey, request) => {
  const headers = {
    'Authorization': `Bearer ${securitykey}`
  }
  return axiosInstanceSeguros.post("createInsuranceDocument/", request, { headers: headers } )
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err.response.status;
  });
};

export default { createInsurance, updateInsurance, listInsurance, uploadDocument, getInsurance, createInsuranceDocument };
