import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";

import Footer from '../components/Footer';
import { logout } from '../actions/auth';

import logoAtria from '../assets/images/logos/atria-color.png';
import logoAtriaLateral from '../assets/images/logos/logo-atria-lateral.svg';
import { updateSucursalSelection } from '../actions/sucursales';
import Solicitudes from '../components/Solicitudes';
import Seguros from '../components/seguro/Seguros';
import Loading from '../components/Loading';
import ModalErrores from '../components/ModalErrores';
import { useEffect } from 'react';
import { menu } from '../actions/menuHome';
import Dashboard from '../components/Dashboard';
import BarraPerfil from '../components/BarraPerfil';
import AlertaMessage from '../components/AlertaMessage';
import { resetCotizadorSeguro } from '../actions/seguros/cotizadorSeguros';


const HomeScreen = () => {

    const [vistaMostrar, setVistaMostrar] = useState();
    const [updateSolicitud, setUpdateSolicitud] = useState(false)
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.auth);
    const { isLoggedIn, user } = currentUser;
    const sucursalSelec = useSelector((state) => state.sucursalSelection);
    const { Clave } = sucursalSelec.sucursalSeleccionada;

    const loading = useSelector((state) => state.loading);
    const obtencionError = useSelector((state) => state.mensajeError);
    const { isError } = obtencionError;
    const { tipoError, mensajeError } = obtencionError.dataError;

    const obtencionMenu = useSelector((state) => state.menu);
    const { menuHome } = obtencionMenu;
    const [sucursal, setSucursal] = useState(Clave);

    const obtencionDashboard = useSelector((state) => state.dashboard);
    const { fullName, profilePicture } = obtencionDashboard.dataDashboard;

    const obtencionAlerta = useSelector((state) => state.alertMessage);
    
    const handleLogout = () => {
        dispatch(logout());
    }
    
    const optionSucursales = isLoggedIn ? user.Sucursales.map((sucursal, index) =>{
        return <option key={index} value={sucursal.Clave} >{sucursal.Proveedor}</option>
    }) : "";

    const cambioSucursal = (e) => {
        const sucursalSelect = e.target.value;
        const sucursales = user.Sucursales;
        const dataSucursal = sucursales.find(element => element.Clave === sucursalSelect);
        dispatch(updateSucursalSelection(dataSucursal));
        setSucursal(sucursalSelect);
    }

    const mostrarSolicidutes = (e) => {
        setVistaMostrar(e);
        if(e === 1) {
            setUpdateSolicitud(!updateSolicitud);
        }
        dispatch(menu(e));
    }

    useEffect(() => {
        mostrarSolicidutes(menuHome);
        dispatch(resetCotizadorSeguro());
    }, []);

    const componenteMostrar = () => {
        switch (menuHome) {
            case 1:
                return <Solicitudes actualizaSolicitud={updateSolicitud} />
            case 3:
                return <Dashboard />
            case 5:
                return <Seguros actualizaSolicitud={updateSolicitud} />
        }
    }
    
    return (
        <section className='seccion-home'>
            <Container >
                <Row>
                    <Col xs={4} className='col-menu-lateral'>
                        <img src={logoAtria} className='logo-atria-color' alt="Atria" />
                        <ul className='opcion-menu'>
                            <li>
                                <div className="form-floating select-sucursal">
                                    <select className="form-select" id="floatingSelect" value={sucursal}  onChange={cambioSucursal} >
                                        {optionSucursales}
                                    </select>
                                    <label htmlFor="floatingSelect"  className='label-sucursal'><i className='icon-pin-sucursal'></i>Sucursal</label>
                                </div>
                            </li>
                            <li className={`${vistaMostrar === 3 && "list-active"}`} >
                                <i className='icon-trophy me-3'></i>
                                <span className="nav-link" onClick={() => mostrarSolicidutes(3) } >Dashboard</span>
                                <span className='circle'></span>
                            </li>
                            <li className={`${vistaMostrar === 1 && "list-active"}`} >
                                <i className='icon-solicitud'></i>
                                <span className="nav-link" onClick={() => mostrarSolicidutes(1) } >Solicitudes de Crédito</span>
                                <span className='circle'></span>
                            </li>
                            <li>
                                <i className='icon-add'></i>
                                <NavLink aria-current="page" to="/cotizador" onClick={() => mostrarSolicidutes(2) }  className="nav-link" >Nueva solicitud de Crédito</NavLink>
                            </li> 
                            
                            <li className={`${vistaMostrar === 5 && "list-active"}`}>
                                <i className='icon-seguros'></i>
                                <span className="nav-link" onClick={() => mostrarSolicidutes(5) } >Solicitudes de Seguros</span>
                                <span className='circle'></span>
                            </li> 
                            <li className={`${vistaMostrar === 4 && "list-active"}`} >
                                <i className='icon-add'></i>
                                <NavLink aria-current="page" to="/cotizador-seguro" onClick={() => mostrarSolicidutes(4) }  className="nav-link" >Nuevo seguro de Contado</NavLink>
                            </li>
                        </ul>
                        <img src={logoAtriaLateral} className='logo-atria-lateral' alt="Atria" />
                        <NavLink to={'/login'} onClick={handleLogout} className="cerrar-sesion" >Cerrar Sesión</NavLink>
                    </Col>
                    <Col xs={8} className="col-contenido" >
                        {
                            (vistaMostrar === 1 || vistaMostrar === 5)  ?
                            <div className='text-end mt-3'>
                                <img src={require('../assets/images/logos/atria-color.png')} alt="Atria" className='logo-atria-color'  />
                            </div>
                            :
                            <BarraPerfil nombre={fullName} imgProfile={profilePicture}  />
                        }
                        
                        {
                            /*vistaMostrar === 1 ? <Solicitudes actualizaSolicitud={updateSolicitud} />
                            : <Dashboard />*/
                            componenteMostrar()
                        }   
                        
                        <Footer />

                    </Col>
                </Row>
                <Loading show={loading} />
                <AlertaMessage show={obtencionAlerta.show} data={obtencionAlerta.data} />
                <ModalErrores show={isError} tipoError={tipoError} mensajeError={mensajeError} />
            </Container>
        </section>
    )
}

export default HomeScreen