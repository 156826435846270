export const dataCotizadorSeguro = {
    paso: 1,
    totalPasos: 3,
    cotizacion: {
      Inicial: "",
      PagoMensual: "",
      Plazo: "",
      SeguroFinanciado: true,
    },
    Auto: {
      IdDescripcion: "",
      Modelo: "",
      Marca: "",
      ModeloText: "",
    },
    IdDescripcion: "",
    CP: "",
    Modelo: "",
    montoFinanciamiento: "",
    ValorAuto: 0,
    Enganche: 0,
    MinEnganche: "",
    Historial: false,
    Comprobantes: false,
    seguroSelect: {
      IdCotizacionSeguro: "",
      Aseguradora: "",
      PrimaTotal: "",
      IVA: "",
      Inicial: "",
      id_aseguradora: 0,
      PrimaNeta: "",
      idDescripcion: "",
      codigo_postal: "",
      MODELO: "",
      CP: ""
    }
}