import { type } from "../../types/index";

import { UpdateSecurityKey } from "../../helpers/SecurityKey";
import SolicitudServices from "../../services/seguros.service";

export const cotizadorPaso1 = (data,combos) => (dispatch) => {

    const { anio, codigoPostal, marca, modelo, version } = data;
    const {marcaText, modeloText, versionText} = combos;

    const dataPaso1 = {
        paso: 2,
        IdDescripcion: version,
        IdDescripcionText: versionText,
        Modelo: parseInt(anio),
        CP: codigoPostal,
        Auto: {
            IdDescripcion: version,
            IdDescripcionText: versionText,
            Modelo: modelo,
            Marca: marca,
            MarcaText: marcaText,
            ModeloText: modeloText
        }
    };

    dispatch({
        type: type.addDataCotizadorSeguro,
        payload: dataPaso1
    });
}

export const cotizadorPaso2 = (CveDistribuidor, Clave, Inicial, IdDescripcion, CP, Modelo, usuario, llaveSeguridad, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes, seguroSeleccionado) => (dispatch) => {
    dispatch({
        type: type.addDataCotizadorSeguro,
        payload: {
            paso: 3,
            seguroSelect: seguroSeleccionado
        }
    });
}

export const terminaCotizacionSeguros = (idAseguradora, idDescripcion, idCotizacionSeguro, modelo, inicial, primaNeta, primaTotal, codigoPostal, aseguradora, idCotizacion, marca, submarca, version) => (dispatch) => {
    const data = {
        id_aseguradora: idAseguradora,
        MODELO: modelo,
        codigo_postal: codigoPostal,
        idDescripcion: idDescripcion,
        IdCotizacionSeguro: idCotizacionSeguro,
        PrimaTotal: primaTotal,
        PrimaNeta: primaNeta,
        Inicial: inicial,
        Aseguradora: aseguradora,
        id_cotizacion_seguro: idCotizacion,
        marca: marca,
        submarca: submarca,
        Version: version
    }
    dispatch({
        type: type.addDataSolicitudSeguro,
        payload: data
    });

};

export const regresarPasoCotizadorSeguro = (paso) => (dispatch) => {

    const data = {
        paso: paso
    }
    dispatch({
        type: type.addDataCotizadorSeguro,
        payload: data
    });
};

export const resetCotizadorSeguro = () => (dispatch) => {

    dispatch({
        type: type.resetCotizadorSeguro
    });
}


export const createInsuranceDocument = (usuario, llaveSeguridad, id, marca, submarca, version) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const request = {
        marca: marca,
        subMarca: submarca,
        Version: version,
        id: id
    }
    return SolicitudServices.createInsuranceDocument(usuario, llaveSeguridad, request).then(
        (data) => {
            dispatch({type: type.setLoading});
            console.log(data);
            if(data.codigo === 0) {

                UpdateSecurityKey(data.llaveSeguridad);
                
            } else {
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.message
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            /*dispatch({
                type: type.getPostCotizaFail
            });*/
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    );
}