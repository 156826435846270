import React from 'react'
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import LoginScreen from '../pages/LoginScreen';
import AuthRouter from './AuthRouter';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';

const AppRouter = () => {

  return (
    <>
      <Router>
        <Routes>
          <Route path='/login' element={<PublicRouter ><LoginScreen /></PublicRouter>} />
          {<Route path='*' element={<PrivateRouter><AuthRouter /></PrivateRouter>} />}
        </Routes>
      </Router>
    </>
  )
}

export default AppRouter