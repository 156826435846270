export const dataSolicitudSeguro = {
	"paso": 1,
	"totalPasos": 5,
	"id": 0,
	"idFase": 0,
	"vendedorLote": "",
	"primerNombre": "",
	"segundoNombre": "",
	"apellidoPaterno": "",
	"apellidoMaterno": "",
	"RFC": "",
	"fechaDeNacimiento": "",
	"CURP": "",
	"genero": 0,
	"nacionalidad": 0,
	"tipoDeIdentificacion": 0,
	"Aseguradora": "",
	"id_aseguradora": 0,
	"regimenFiscal": 0,
	"MODELO": "",
	"codigo_postal": "",
	"idDescripcion": "",
	"IdCotizacionSeguro": null,
	"ocupacion": 0,
	"proveedor": "",
	"distribuidor": "",
	"sucursal": "",
	"marca": "",
	"submarca": "",
	"Version": "",


	"id_cotizacion_seguro": 0,

	"calle": "",
	"numeroExterior": "",
	"numeroInterior": "",
	"CP": "",
	"colonia": "0",
	"coloniaStr": "0",
	"municipio": "",
	"estado": "",

	"telefonoCelular": "",
	"telefonoFijo": null,
	"correo": "",
    "tipoDeCarga": null,

	"VIN": "",
	"numeroDeMotor": null,
	"placas": null,
	"color": 0,

	"Inicial": "",
	"IVA": null,
	"list_documents": null,
	"PrimaNeta": "",
	"PrimaTotal": "",
	"user": ""
}