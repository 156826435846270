import { type } from "../types/index";
import RFCService from "../services/rfc.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
//import { RFC } from "../models/RFC";

export const getRFC = (nombres, apellidoPaterno, apellidoMaterno, usuario, fechaNacimiento, securitykey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    return RFCService.getRFC(nombres, apellidoPaterno, apellidoMaterno, usuario, fechaNacimiento, securitykey).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.Codigo === 0) {
                const dataRes = {
                    Codigo: data.Codigo,
                    Mensaje: null,
                    RFC_10_D: data.RFC_10_D,
                    RFC_COMPLETO: data.RFC_COMPLETO,
                    homoclave: data.RFC_COMPLETO.substring(data.RFC_COMPLETO.length - 3)
                }
                /*RFC.Codigo = data.Codigo;
                RFC.Mensaje = null;
                RFC.RFC_10_D = data.RFC_10_D;
                RFC.RFC_COMPLETO = data.RFC_COMPLETO;
                RFC.homoclave = data.RFC_COMPLETO.substring(data.RFC_COMPLETO.length - 3);*/
                dispatch({
                    type: type.getRFC,
                    payload: dataRes
                });
                UpdateSecurityKey(data.llaveSeguridad);

            } else {
                dispatch({
                    type: type.getRFCFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getRFCFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}


export const resetRFC = () => (dispatch) => {
    dispatch({
        type: type.resetRFC
    });
}