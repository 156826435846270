import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'

const Loading = ({ show, onHide }) => {
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered
    >
        <Spinner animation="border" role="status" variant="primary">
            <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>Espera un momento...</p>
    </Modal>
  )
}

export default Loading