import { type } from "../types/index";
import SaveSolicitudService from "../services/save.solicitud.service";
import SolicitudFinishService from "../services/solicitud.finish.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { formatDate } from "../helpers/functionGlobal";
import { updateDataSolicitud } from "./solicitudes";

export const updateSaveSolicitud = (data, usuario, dataSucursal, llaveSeguridad, obtencionSolicitud) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const cuentaConVehiculo = data.vehiculosCuenta,
        dependientesEconomicos = data.dependientesEconomicos,
        estadoCivil = data.estadoCivil,
        nivelEStudios = data.nivelEstudios,
        genero = data.genero,
        seller = parseInt(data.vendedor);

    const intormacionAdicionalPersonal = {
        cuenta_con_vehiculo: data.vehiculosCuenta,
        dependientes_economicos: data.dependientesEconomicos,
        estado_civil: data.estadoCivil,
        nivel_de_estudios: data.nivelEstudios,
        genero: data.genero
    }
    const { Aseguradora, versionVehiculo, mensaualidad, modelo, idCotizacion,
        plazo, resultadoImporte, Financiado, marcaVehiculo, tipoVehiculo, costoAuto,
        enganche, idDescripcion, MontoSeguro, modeloCotizador, idCotizacionSeguro,
        idCotizaciones, engancheInicial, partner, clave_de_institucion
    } = obtencionSolicitud.dataSolicitud;

    const { Proveedor, CveDistribuidor, Vendedor, Clave } = dataSucursal.sucursalSeleccionada;

    const userAgent = window.navigator.userAgent;

    const dataRequest = {
        canal: "3",
        version: "1.0",
        userAgent: userAgent,
        carrierCelular: userAgent,
        nombre: data.primerNombre,
        segundoNombre: data.segundoNombre,
        apellidoPaterno: data.primerApellido,
        apellidoMaterno: data.segundoApellido,
        intormacionAdicionalPersonal: JSON.stringify(intormacionAdicionalPersonal),
        fechaNacimiento: formatDate(data.fechaNacimiento),
        rfc: data.rfc+data.homoclave,
        calle: data.calle,
        noExterior: data.noExterior,
        noInterior: data.noInterior,
        codigoPostal: data.codigoPostal,
        tipoVivienda: data.vivienda,
        colonia: data.existeColonia ? "-1" : "0",
        coloniaStr: !data.existeColonia ? data.colonia : data.anadeColonia,
        municipio: data.alcaldia,
        estado: data.estado,
        aniosDomicilio: data.aniosDomicilio,
        mesesDomicilio: data.mesesDomicilio,
        telefonoMovil: data.numeroCelular,
        telefonoFijo: data.telefonoFijo,
        email: data.correoElectronico,
        vendedor: Vendedor,
        sucursal: Clave,
        clave: Clave,
        Aseguradora: Aseguradora,
        Financiado: Financiado,
        proveedor: Proveedor,
        distribuidor: CveDistribuidor,
        versionVehiculo: versionVehiculo,
        costoAuto: costoAuto,
        enganche: enganche,
        plazo: plazo,
        idDescripcion: idDescripcion,
        mensaualidad: mensaualidad,
        modelo: modelo,
        idCotizacion: idCotizacion,
        resultadoImporte: resultadoImporte,
        telefonoEmpresa: "",
        establecimientoFijo: "",
        marcaVehiculo: marcaVehiculo,
        tipoVehiculo: tipoVehiculo,
        seller: seller,
        modeloCotizador: modeloCotizador,
        idCotizacionSeguro: idCotizacionSeguro,
        idCotizaciones: idCotizaciones,
	    engancheInicial: engancheInicial,
        partner: partner,
        clave_de_institucion: clave_de_institucion,

        usuario: usuario,

        fechaCreacion: new Date(),
        MontoSeguro: MontoSeguro,
        etapaProceso: 2,
        
        llaveSeguridad: llaveSeguridad
    }

    return SaveSolicitudService.saveSolicitud(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { codigo, llaveSeguridad } = data;
            const codigoValid = String(Math.floor(Math.random()*900000) + 100000);
            if(codigo === 0) {
                dataRequest.id = data.idSolicitud;
                dataRequest.codigoValidacion1 = codigoValid;
                dataRequest.genero = genero;
                dataRequest.paso = 2;
                dataRequest.cuenta_con_vehiculo = cuentaConVehiculo;
                dataRequest.dependientes_economicos = dependientesEconomicos;
                dataRequest.estado_civil = estadoCivil;
                dataRequest.nivel_de_estudios = nivelEStudios;
                dispatch({
                    type: type.updateSaveSolicitud,
                    payload: dataRequest
                });
                dispatch(updateDataSolicitud(dataRequest));
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.updateSaveSolicitudFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.respuesta
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.updateSaveSolicitudFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}


export const saveSolicitudFinish = (usuario, securitykey, id, distribuidor, seller ) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const userAgent = window.navigator.userAgent;

    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        llaveSeguridad: securitykey,
        id: id,
        etapaProceso: 7,
        latitud: "19.357481511946663",
        longitud: "-99.07453116389432",
        ip_dispositivo: "0.0.0.0",
        distribuidor: distribuidor,
        seller: seller
    }
    
    return SolicitudFinishService.saveSolicitudFinish(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { codigo, llaveSeguridad } = data;
            if(codigo === 0) {
                dispatch({
                    type: type.updateSaveSolicitud,
                    payload: dataRequest
                });
                dispatch({
                    type: type.getPlanAutorizado,
                    payload: data
                });

                dispatch(updateDataSolicitud({
                    paso: 8
                }));
                /*dispatch({
                    type: type.updateSolicitud,
                    payload: {
                        paso: 
                    }
                });*/
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.updateSaveSolicitudFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.respuesta
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.updateSaveSolicitudFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
