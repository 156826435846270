import { type } from "../types/index";
import CodigoPostalService from "../services/codigo.postal.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const codigoPostal = (user, codigoPostal, securitykey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    return CodigoPostalService.getCodigoPostal( user, codigoPostal, securitykey ).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.Codigo === 0) {
                dispatch({
                    type: type.getCodigoPostal,
                    payload: data
                });
                UpdateSecurityKey(data.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getCodigoPostalFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje,
                        seccion: "cp"
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getCodigoPostalFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
