import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector, connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { getSecurityKey } from '../../helpers/SecurityKey';
import { getRFC, resetRFC } from '../../actions/rfc';
import { formatDate, revertDate } from '../../helpers/functionGlobal';
import { getCatalogos } from '../../actions/catalogos';
import { resetFormSolicitud, solicitudPaso1 } from '../../actions/seguros/solicitudSeguros';
import { hideAlert, showAlert } from '../../actions/alertMessage';
import { resetCotizadorSeguro } from '../../actions/seguros/cotizadorSeguros';
import { MensajesAlertas } from '../../models/MensajesAlertas';


registerLocale('es', es);

const FormDatosClientesSeguro = (props) => {

    const { closeModal, clearCotizador } = props;
    
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);

    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 69);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(date);
    const [switchDate, setSwitchDate] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const securityKey = getSecurityKey();

    const obtenerRFC = useSelector((state) => state.rfc);
    const { RFC_10_D, homoclave, RFC_COMPLETO } = obtenerRFC.dataRFC;
    const obtencionSolicitud = useSelector((state) => state.solicitudSeguro);
    console.log(obtencionSolicitud); 
    const { vendedorLote, primerNombre,segundoNombre, apellidoPaterno, apellidoMaterno, RFC, fechaDeNacimiento, 
        CURP, genero, nacionalidad, tipoDeIdentificacion, regimenFiscal,ocupacion, id,
        id_aseguradora, idDescripcion, IdCotizacionSeguro, MODELO, Inicial, PrimaNeta, PrimaTotal, codigo_postal, Aseguradora,
        Version, marca, submarca, id_cotizacion_seguro
    } = obtencionSolicitud;
    const sucursalSelec = useSelector((state) => state.sucursalSelection);
    const { Proveedor, CveDistribuidor, Clave, Vendedores } = sucursalSelec.sucursalSeleccionada;
    const dataUser = useSelector((state) => state.auth);
   
    const catalogos = useSelector((state) => state.catalogosSeguro);
    const { isCatalogo, dataCatalogosSeguro } = catalogos;

    const [combos, setCombos] = useState({
        genero: genero, nacionalidad: nacionalidad, tipoIdentificacion: tipoDeIdentificacion,
        ocupacion: ocupacion, regimenFiscal: regimenFiscal, vendedor: ""
    });

    useEffect(() => {
        dispatch(getCatalogos(dataUser.user.correo, securityKey)).
        then((res) => {
            if(res.codigo === 0) {
                
                if(sucursalSelec.sucursalSeleccionada.Vendedores.length === 1) {
                    setValue("vendedor", sucursalSelec.sucursalSeleccionada.Vendedores[0].id);
                    const comboSelec = {
                        ...combos,
                        ["vendedor"]: sucursalSelec.sucursalSeleccionada.Vendedores[0].id
                    };
                    setCombos(comboSelec);
                } else {
                    if(combos.vendedor !== null && combos.vendedor !== "") {
                        setValue("vendedor", combos.vendedor);
                    }
                }

                if(primerNombre !== "" && primerNombre !== null) {
                    setValue("primerNombre", primerNombre);
                    setValue("segundoNombre", segundoNombre);
                    setValue("apellidoPaterno", apellidoPaterno);
                    setValue("apellidoMaterno", apellidoMaterno);
                    setValue("fechaNacimiento", revertDate(fechaDeNacimiento));
                    setValue("rfc", RFC);
                    setValue("homoclave", RFC.slice(-3));
                    setValue("curp", CURP);
                    setValue("genero", genero);
                    setValue("nacionalidad", nacionalidad);
                    setValue("tipoIdentificacion", tipoDeIdentificacion);
                    setValue("ocupacion", ocupacion);
                    setValue("regimenFiscal", regimenFiscal);
                    const vendedorSelect = sucursalSelec.sucursalSeleccionada.Vendedores.find(vendedor => vendedor.email=== vendedorLote );
                    setValue("vendedor", vendedorSelect.id);
                    const comboSelec = {
                        ...combos,
                        ["vendedor"]:vendedorSelect.id
                    };
                    setCombos(comboSelec);
                }
            }
        })

    }, []);

    /* Llenado de combos */
    const optionGenero = isCatalogo ? dataCatalogosSeguro.genero.map((genero, index) => {
        return <option key={index} value={genero.id}>{genero.name}</option>
    }) : "";
    const optionNacionalidad = isCatalogo ? dataCatalogosSeguro.nacionalidad.map((nacionalidad, index) => {
        return <option key={index} value={nacionalidad.id}>{nacionalidad.name}</option>
    }) : "";
    const optionTipoIdentificacion = isCatalogo ? dataCatalogosSeguro.tipoDeIdentificacion.map((tipoDeIdentificacion, index) => {
        return <option key={index} value={tipoDeIdentificacion.id}>{tipoDeIdentificacion.name}</option>
    }) : "";
    const optionOcupacion = isCatalogo ? dataCatalogosSeguro.ocupacion.map((ocupacion, index) => {
        return <option key={index} value={ocupacion.id}>{ocupacion.name}</option>
    }) : "";
    const optionRegimenFiscal = isCatalogo ? dataCatalogosSeguro.regimenFiscal.map((regimenFiscal, index) => {
        return <option key={index} value={regimenFiscal.id}>{regimenFiscal.description}</option>
    }) : "";

    const optionVendedores =  sucursalSelec ? sucursalSelec.sucursalSeleccionada.Vendedores.map((vendedor, index) => {
        return <option key={index} value={vendedor.id}>{vendedor.nomberCompleto}</option>
    }) : "";

    const validateAuto = Yup.object({
        vendedor: Yup.string().required('El campo es requerido'),
        primerNombre: Yup.string().required('El campo es requerido'),
        apellidoPaterno: Yup.string().required('El campo es requerido'),
        //apellidoMaterno: Yup.string().required('El campo es requerido'),
        fechaNacimiento: Yup.string().required('El campo es requerido'),
        rfc: Yup.string().required('El campo es requerido').min(10, 'Introduzca al menos ${min} caracteres'),
        homoclave: Yup.string().required('El campo es requerido'),
        curp: Yup.string().required('El campo es requerido').min(18, 'Introduzca al menos ${min} caracteres'),
        genero: Yup.string().required('El campo es requerido'),
        nacionalidad: Yup.string().required('El campo es requerido'),
        tipoIdentificacion: Yup.string().required('El campo es requerido'),
        ocupacion: Yup.string().required('El campo es requerido'),
        regimenFiscal: Yup.string().required('El campo es requerido')
    });

    const { register, handleSubmit, formState: { errors }, control, getFieldState, getValues, setValue } = useForm({
        resolver: yupResolver(validateAuto),
        mode: "onChange" 
    });
    const onSubmit = (data) => {
        const { correo } = dataUser.user;
        dispatch(solicitudPaso1(data, correo, securityKey, Proveedor, CveDistribuidor, Clave, id_aseguradora, idDescripcion, IdCotizacionSeguro, MODELO, Inicial, PrimaNeta, PrimaTotal, codigo_postal, Aseguradora, Vendedores, id, Version, marca, submarca, id_cotizacion_seguro));
    };

    
    
    const llenadoCombo = (e) => {
        const { name, value } = e.target;
        const comboSelec = {
            ...combos,
            [name]:value
        };
        setCombos(comboSelec);
    }

    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); } ));
    }

    const obtencionRFC = () => {
        const primerNombre = getFieldState("primerNombre").error,
        segundoNombre = getFieldState("segundoNombre").error,
        apellidoPaterno = getFieldState("apellidoPaterno").error,
        genero = getFieldState("genero").error,
        estadoCivil = getFieldState("estadoCivil").error;

        const primerNombreVal = getValues("primerNombre"),
        segundoNombreVal = getValues("segundoNombre"),
        apellidoPaternoVal = getValues("apellidoPaterno"),
        apellidoMaternoVal = getValues("apellidoMaterno"),
        generoVal = getValues("genero"),
        fechaNacimientoVal = getValues("fechaNacimiento");
        var fullName = primerNombreVal;
        if (segundoNombreVal != undefined && segundoNombreVal != ""){
            fullName = primerNombreVal + " " + segundoNombreVal
        }

        if(primerNombre === undefined && primerNombreVal!=="" && apellidoPaterno === undefined &&  apellidoPaternoVal !=="" &&
            fechaNacimientoVal!=="" && fechaNacimientoVal !== undefined && fechaNacimientoVal !== "Invalid Date"
        ) {
            dispatch(getRFC(fullName, apellidoPaternoVal, apellidoMaternoVal, dataUser.user.correo, formatDate(fechaNacimientoVal), securityKey))
        }
    }

    useEffect(() => {
        setValue("rfc", RFC_COMPLETO);
        setValue("homoclave", homoclave);
    }, [RFC_COMPLETO, homoclave]);
    return (
        <Form className='formulario-usuario' onSubmit={handleSubmit(onSubmit)}>

            <Card className='card-vendedor'>
                <Card.Body>
                    <Card.Title><i className='icon-documentos me-3'></i>Información del vendedor</Card.Title>
                    <Row>
                        <Form.Group as={Col} xs="12" >
                            <Form.Label htmlFor="vendedor" className='m-0'>Vendedor</Form.Label>
                            <Form.Select 
                                name="vendedor"
                                className={`${errors.vendedor ? 'is-invalid' : ""} ${combos.vendedor ? 'custom-select' : ''}`}
                                {...register("vendedor", {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })} 
                            >
                                <option value="">Selecciona un vendedor</option>
                                {optionVendedores}
                            </Form.Select>
                            <p className='texto-error'>{errors.vendedor?.message}</p>
                        </Form.Group>
                    </Row>
                </Card.Body>
            </Card>
            <hr />
            <Card className='card-formulario'>
                <Card.Title><i className='icon-user me-3'></i>Datos personales</Card.Title>
                <Card.Body className='p-0'>
                    <Row>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Primer nombre</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="primerNombre" 
                                placeholder="Escribe el primer nombre"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.primerNombre ? 'is-invalid' : ""}`}
                                {...register("primerNombre")}
                                onBlur={() => { obtencionRFC() }}
                            />
                            <p className='texto-error'>{errors.primerNombre?.message}</p>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Segundo nombre (opcional)</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="segundoNombre" 
                                placeholder="Escribe el segundo nombre"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.segundoNombre ? 'is-invalid' : ""}`}
                                {...register("segundoNombre")}
                                onBlur={() => { obtencionRFC() }}
                            />
                            <p className='texto-error'>{errors.segundoNombre?.message}</p>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Apellido paterno</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="apellidoPaterno" 
                                placeholder="Escribe el apellido paterno"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.apellidoPaterno ? 'is-invalid' : ""}`}
                                {...register("apellidoPaterno")}
                                onBlur={() => { obtencionRFC() }}
                            />
                            <p className='texto-error'>{errors.apellidoPaterno?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Apellido materno (opcional)</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="apellidoMaterno" 
                                placeholder="Apellido materno"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.apellidoMaterno ? 'is-invalid' : ""}`}
                                {...register("apellidoMaterno")}
                                onBlur={() => { obtencionRFC() }}
                            />
                            <p className='texto-error'>{errors.apellidoMaterno?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Fecha de nacimiento</Form.Label>
                            <Controller
                                control={control}
                                name='fechaNacimiento'
                                rules={{ required: true }}
                                render={({ field }) => (
                                <div className='input-calendario'>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={(fechaDeNacimiento !== "" && !switchDate) ? revertDate(fechaDeNacimiento) : startDate}
                                        maxDate={endDate}
                                        minDate={minDate}
                                        locale="es"
                                        name = {field.name}
                                        value= {startDate}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        autoComplete="off"
                                        placeholderText={formatDate(date)}
                                        className={`form-control ${errors.fechaNacimiento ? 'is-invalid' : ""}`}
                                        onChange={(date) => {
                                            field.onChange(date); 
                                            setStartDate(date);
                                            setSwitchDate(true);
                                            obtencionRFC() ;
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                    <i className='icon-calendario'></i>
                                </div>
                            )}
                            />
                            <p className='texto-error'>{errors.fechaNacimiento?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>RFC</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="rfc" 
                                placeholder="Escribe los 10 dígitos de tu RFC"
                                maxLength={10}
                                autoComplete="off"
                                className={`${errors.rfc ? 'is-invalid' : ""}`}
                                {...register("rfc")}
                            />
                            <p className='texto-error'>{errors.rfc?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Homoclave</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="homoclave" 
                                placeholder="3 últimos dígitos"
                                maxLength={3}
                                autoComplete="off"
                                className={`${errors.homoclave ? 'is-invalid' : ""}`}
                                {...register("homoclave")}
                            />
                            <p className='texto-error'>{errors.homoclave?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>CURP</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="curp" 
                                placeholder="Escribe los 18 dígitos de tu CURP"
                                maxLength={18}
                                autoComplete="off"
                                className={`${errors.curp ? 'is-invalid' : ""}`}
                                {...register("curp")}
                            />
                            <p className='texto-error'>{errors.curp?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="genero">Género</Form.Label>
                            <Form.Select 
                                name="genero"
                                value={combos.genero}
                                className={`${errors.genero ? 'is-invalid' : ""} ${combos.genero ? 'custom-select' : ''}`}
                                {...register("genero", {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })} 
                            >
                                <option value="">Selecciona un género</option>
                                {optionGenero}
                            </Form.Select>
                            <p className='texto-error'>{errors.genero?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="nacionalidad">Nacionalidad</Form.Label>
                            <Form.Select 
                                name="nacionalidad"
                                value={combos.nacionalidad}
                                className={`${errors.nacionalidad ? 'is-invalid' : ""} ${combos.nacionalidad ? 'custom-select' : ''} `}
                                {...register("nacionalidad", {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })} 
                            >
                                <option value="">Selecciona una nacionalidad</option>
                                {optionNacionalidad}
                            </Form.Select>
                            <p className='texto-error'>{errors.nacionalidad?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="tipoIdentificacion">Tipo de identificación</Form.Label>
                            <Form.Select 
                                name="tipoIdentificacion"
                                value={combos.tipoIdentificacion}
                                className={`${errors.tipoIdentificacion ? 'is-invalid' : ""} ${combos.tipoIdentificacion ? 'custom-select' : ''} `}
                                {...register("tipoIdentificacion", {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })} 
                            >
                                <option value="">Selecciona tipo de identificación</option>
                                {optionTipoIdentificacion}
                            </Form.Select>
                            <p className='texto-error'>{errors.tipoIdentificacion?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="ocupacion">Ocupación</Form.Label>
                            <Form.Select 
                                name="ocupacion"
                                value={combos.ocupacion}
                                className={`${errors.ocupacion ? 'is-invalid' : ""} ${combos.ocupacion ? 'custom-select' : ''} `}
                                {...register("ocupacion", {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })} 
                            >
                                <option value="">Selecciona una ocupación</option>
                                {optionOcupacion}
                            </Form.Select>
                            <p className='texto-error'>{errors.ocupacion?.message}</p>
                        </Form.Group>

                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="regimenFiscal">Regimen fiscal</Form.Label>
                            <Form.Select 
                                name="regimenFiscal"
                                value={combos.regimenFiscal}
                                className={`${errors.regimenFiscal ? 'is-invalid' : ""} ${combos.regimenFiscal ? 'custom-select' : ''} `}
                                {...register("regimenFiscal", {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })} 
                            >
                                <option value="">Selecciona un regimen fiscal</option>
                                {optionRegimenFiscal}
                            </Form.Select>
                            <p className='texto-error'>{errors.regimenFiscal?.message}</p>
                        </Form.Group>
                       
                    </Row>
                </Card.Body>
            </Card>

            <Row className='mt-5'>
                <Col xs={6} className='px-2'>
                    <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
                </Col>
                <Col xs={6} className='px-2'>
                    <Button type="submit" className="btn btn-primary w-100" >Siguiente</Button>
                </Col>
            </Row>
        </Form>
    )
}

//export default FormDatosClientesSeguro
const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetRFC());
            dispatch(resetFormSolicitud());
            dispatch(resetCotizadorSeguro());
        } 
	};
};
export default connect(null, mapDispatchToProps)(FormDatosClientesSeguro);