import {axiosInstance2} from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const setProfileImage = (usuario, image, llaveSeguridad) => {
    const headers = {
        'Authorization': `Bearer ${llaveSeguridad}`
    }
    return axiosInstance2.post('dashboard/set_profile_image/', {
        "image": image,
        "usuario": usuario,
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "canal": "3",
        "version": "2.0.0",
        "llaveSeguridad": llaveSeguridad
    }, { headers: headers }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
        return err;
    });
}

export default { setProfileImage };