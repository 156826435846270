import React from 'react'
import { Navigate } from "react-router";
import { useSelector } from 'react-redux';


const PublicRouter = ({children}) => {

  const currentUser = useSelector((state) => state.auth);
  return !currentUser.isLoggedIn ? children : <Navigate to="/home" />;
  //return children;
}

export default PublicRouter