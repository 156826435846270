import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Button, Card, Col, Form, Nav, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { sendSMS } from '../actions/sendSMS';
import { getSecurityKey } from '../helpers/SecurityKey';
import { sendCall } from '../actions/sendCall';
import { getverificacionCodigo } from '../actions/scoreResult';
import ReadMore from './ReadMore';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { useNavigate } from 'react-router-dom';
import { resetSolicitud } from '../actions/solicitudes';
import { type } from "../types/index";
import { resetCotizador } from '../actions/cotizador';

const VerificacionCodigoDos = (props) => {
    const { closeModal, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const securityKey = getSecurityKey();
    const dataUser = useSelector((state) => state.auth);

    const dataSolicitud = useSelector((state) => state.solicitudes);
    //console.log(dataSolicitud);
    const { codigoValidacion1, sucursal, nombre, id, telefonoMovil, codigoVerificacion1 } = dataSolicitud.dataSolicitud;
    const validateVerificaCodigo = Yup.object({
        codigoVerificacion: Yup.string().required('El campo es requerido').min(6, "Introduzca al menos ${min} caracteres")
    });
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validateVerificaCodigo),
        mode: "onChange"
    });
    const onSubmit = (data) => {
        //dispatch(getScoreResult(dataUser.user.correo, securityKey, distribuidor, idSolicitud, codigoVerificacion, sucursal ));
        dispatch(getverificacionCodigo(data, codigoValidacion1, dataSolicitud.dataSolicitud))
    };

    const envioCodigoSMS = () => {
        dispatch({type: type.setLoading});
        clearTimer(getDeadTime())
        const claveSucursal = "";
        dispatch(sendSMS(dataUser.user.correo, securityKey, id, sucursal, claveSucursal, codigoValidacion1, telefonoMovil))
    }
    const envioCodigoCall = () => {
        dispatch({type: type.setLoading});
        clearTimer(getDeadTime())
        dispatch(sendCall(dataUser.user.correo, securityKey, telefonoMovil, nombre, codigoValidacion1));
    }

    const showAlertVerification = () => {
        dispatch(showAlert(MensajesAlertas.mensajeVerificacion, () => { closeModal();}, () =>{ closeModal(); clearCotizador(); navigate("/home"); }  ));
    }
    
    useEffect(() => {
        setValue("codigoVerificacion", codigoVerificacion1);
    }, []);

    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
    const [activeLink, setActiveLink] = useState(true)

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 * 60 * 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } 
                    = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        } else {
            clearInterval(Ref.current);
            setActiveLink(false);
        }
    }

    const clearTimer = (e) => {
        setTimer('00:03:00');
  
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        setActiveLink(true);
        let deadline = new Date();

        deadline.setSeconds(deadline.getSeconds() + 180);
        //180
        return deadline;
    }

    useEffect(() => {
        clearTimer(getDeadTime())
    }, []);


    return (
        <Card className='card-formulario '>
            <Card.Body className='px-0'>
                <Form  onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Código de verificación</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="codigoVerificacion"
                                placeholder="Ingresa el código de 6 dígitos"
                                maxLength={6}
                                autoComplete="off"
                                className={`${errors.codigoVerificacion ? 'is-invalid' : ""}`}
                                {...register("codigoVerificacion")}
                            />
                            <p className='texto-error'>{errors.codigoVerificacion?.message}</p>
                        </Form.Group>
                        <div className='col-12 col-reenvio'>
                            <p>¿No has recibido el código?</p>
                            <div className='ligas'>
                                <Nav.Link  onClick={envioCodigoSMS} disabled={activeLink} >ENVIAR NUEVAMENTE</Nav.Link>
                                <p className='timer'>{timer}</p>
                                <Nav.Link  onClick={envioCodigoCall} disabled={activeLink} >ENVIAR POR LLAMADA</Nav.Link>
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <Card className='card-clausulas'>
                                <Card.Body>
                                    <Card.Subtitle >Autorización para solicitar Reportes de Crédito</Card.Subtitle>
                                    <ReadMore>
                                        Autorizo expresamente a Atriala, S.A.P.I. de C.V. y LGF Occidente, S.A. de C.V. SOFOM E.N.R., para que lleve a cabo Investigaciones sobre mi comportamiento Crediticio en las Sociedades de Información Crediticia (SIC) que estime conveniente.
                                        Conozco la naturaleza y alcance de la Información que se solicitará, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio. Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy, y en su caso mientras mantengamos relación jurídica. Acepto que este documento quede bajo propiedad de Atriala, S.A.P.I. de C.V. y LGF Occidente, S.A. de C.V. SOFOM E.N.R. para efectos de control y cumplimiento del artículo 28 de la LRSIC.                                    
                                    </ReadMore>
                                </Card.Body>
                            </Card>
                        </div>
                        
                        
                    </Row>
                    <Row className='mt-3 pt-3'>
                        <Col xs={6} className='mt-16'>
                            <Button type="button" className="btn btn-light w-100" onClick={showAlertVerification} >Verificar en otro momento</Button>
                        </Col>
                        <Col xs={6} className='mt-16'>
                            <Button type="submit" className="btn btn-primary w-100">Continuar</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
        } 
	};
};

//export default VerificacionCodigo
export default connect(null, mapDispatchToProps)(VerificacionCodigoDos)
