import {axiosInstance} from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const getRFC = (nombres, apellidoPaterno, apellidoMaterno, usuario, fechaNacimiento, securitykey) => {
    return axiosInstance.post('getRFC/', {
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "version": "2.0.0",
        "canal": "2",
        "names": nombres,
        "firstName": apellidoPaterno,
        "lastName": apellidoMaterno,
        "usuario": usuario,
        "birthdate":fechaNacimiento,
        "llaveSeguridad": securitykey
    }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

export default { getRFC };