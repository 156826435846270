import { type as types } from "../types/index";

const initialState = {isSendCall: false, isLoading: false, dataCall: null};

export const SendCallReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.sendCall:
            return {
                ...state,
                isSendCall: true,
                dataCall: payload
            };
        case types.sendCallFail:
            return {
                ...state,
                isSendCall: false,
                dataCall: null
            }
        default:
            return state;
    }
}
