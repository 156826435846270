import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';

const FormDatosContacto = ({ register, errors, control }) => {
  return (
    <Card className='card-formulario'>
      <Card.Title className='my-3'><i className='icon-phone me-3'></i>Contacto</Card.Title>
      <Card.Body className='p-0'>
        <Row>
          <Form.Group as={Col} xs="6" className='mb-3'>
              <Form.Label>Número celular</Form.Label>
              <Form.Control 
                  type="password" 
                  name="numeroCelular" 
                  placeholder="Escribe un teléfono de 10 dígitos"
                  maxLength={10}
                  autoComplete="nope"
                  className={`${errors.numeroCelular ? 'is-invalid' : ""}`}
                  {...register('numeroCelular')}
              />
              <p className='texto-error'>{errors.numeroCelular?.message}</p>
          </Form.Group>
          <Form.Group as={Col} xs="6" className='mb-3'>
              <Form.Label>Confirmar celular</Form.Label>
              <Form.Control 
                  type="text" 
                  name="confirmarCelular" 
                  placeholder="Escribe un teléfono de 10 dígitos"
                  maxLength={10}
                  autoComplete="off"
                  className={`${errors.confirmarCelular ? 'is-invalid' : ""}`}
                  {...register('confirmarCelular')}
              />
              <p className='texto-error'>{errors.confirmarCelular?.message}</p>
          </Form.Group>
          <Form.Group as={Col} xs="6" className='mb-3'>
              <Form.Label>Teléfono fijo</Form.Label>
              <Form.Control 
                  type="text" 
                  name="telefonoFijo" 
                  placeholder="Escribe un teléfono fijo"
                  maxLength={10}
                  autoComplete="off"
                  className={`${errors.telefonoFijo ? 'is-invalid' : ""}`}
                  {...register('telefonoFijo')}
              />
              <p className='texto-error'>{errors.telefonoFijo?.message}</p>
          </Form.Group>
          <Form.Group as={Col} xs="6" className='mb-3'>
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control 
                  type="email" 
                  name="correoElectronico" 
                  placeholder="mail@correo.com"
                  maxLength={100}
                  autoComplete="off"
                  className={`${errors.correoElectronico ? 'is-invalid' : ""}`}
                  {...register('correoElectronico')}
              />
              <p className='texto-error'>{errors.correoElectronico?.message}</p>
          </Form.Group>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default FormDatosContacto