import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { codigoPostal } from '../actions/codigoPostal';
import { getSecurityKey } from '../helpers/SecurityKey';
import { TipoVivienda } from '../models/TipoVivienda';
import ModalError from './ModalError';

const dataDireccion = {
    ciudad: "",
    estado: "",
    municipio: "",
    colonias: []
};

const FormDatosDomicilio = ({ register, errors, setValue  }) => {
    const [ModalShow, setModalShow] = useState(false);
    const dispatch = useDispatch();
    const securityKey = getSecurityKey();

    const [direccionState, setDireccionState] = useState(dataDireccion)
    const { ciudad, estado, municipio, colonias } = direccionState;
    const obtencionSolicitud = useSelector((state) => state.solicitudes);
    const { codigoPostal: cp, coloniaStr, colonia, tipoVivienda, aniosDomicilio, mesesDomicilio } = obtencionSolicitud.dataSolicitud;
    //console.log(obtencionSolicitud);
    const dataUser = useSelector((state) => state.auth);
    const [showColonia, setShowColonia] = useState(colonia);

    const optionTipoVivienda = TipoVivienda.map((tipoVivienda, index) => {
        return <option key={index} value={tipoVivienda.value}>{tipoVivienda.label}</option>
    });

    const [combos, setCombos] = useState({
        vivienda: tipoVivienda, colonia: aniosDomicilio, mesesDomicilio: mesesDomicilio
    });

    const llenadoCombo = (e) => {
        const { name, value } = e.target;
        const comboSelec = {
            ...combos,
            [name]:value
        };
        setCombos(comboSelec);
    }
    
    useEffect(() => {
      cp && getCodigoPostal(cp)
    }, []);


    const getCodigoPostal = (cp) => {
        //const cp = e.target.value;
        if(cp !== "") {
            dispatch(codigoPostal(dataUser.user.correo, cp, securityKey))
            .then((res)=>{
                if(res.Codigo === 0) {
                    const dataResponse = {
                        ciudad: res.ciudad,
                        estado: res.estado,
                        municipio: res.municipio,
                        colonias: res.colonias
                    }
                    setDireccionState(dataResponse);
                    setValue("estado", res.estado);
                    setValue("alcaldia", res.municipio);
                    setValue("colonia", coloniaStr);
                } /*else {
                    setDireccionState(dataDireccion);
                    setModalShow(true);
                }*/
            })
            .catch((error)=>{
                /*setDireccionState(dataDireccion);
                setModalShow(true);*/
            });
        }
    }

    const optionMunicipios = colonias ? colonias.map((optionColonia, index) =>{
        return <option key={index} value={optionColonia} >{optionColonia}</option>
    }) : "";
    

    return (
        <Card className='card-formulario card-domicilio'>
            <Card.Title className='my-3'><i className='icon-pin me-3'></i>Domicilio</Card.Title>
            <Card.Text className='texto-informacion-roboto'>Los datos deben coincidir con la identificación oficial de tu cliente.</Card.Text>
            <Card.Body className='p-0'>
                <Row>
                    <Form.Group as={Col} xs="12" className='mb-3'>
                        <Form.Label>Calle</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="calle" 
                            placeholder="Escribe la calle"
                            maxLength={100}
                            autoComplete="off"
                            className={`${errors.calle ? 'is-invalid' : ""}`}
                            {...register('calle')}
                        />
                        <p className='texto-error'>{errors.calle?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="4" className='mb-3'>
                        <Form.Label>No. exterior</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="noExterior" 
                            placeholder="No. exterior"
                            maxLength={16}
                            autoComplete="off"
                            className={`${errors.noExterior ? 'is-invalid' : ""}`}
                            {...register('noExterior')}
                        />
                        <p className='texto-error'>{errors.noExterior?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="4" className='mb-3'>
                        <Form.Label>No. interior(opcional)</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="noInterior" 
                            placeholder="No. interior"
                            maxLength={16}
                            autoComplete="off"
                            className={`${errors.noInterior ? 'is-invalid' : ""}`}
                            {...register('noInterior')}
                        />
                        <p className='texto-error'>{errors.noInterior?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="4" className='mb-3'>
                        <Form.Label>Código postal</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="codigoPostal" 
                            placeholder="Código postal"
                            maxLength={5}
                            autoComplete="off"
                            className={`${errors.codigoPostal ? 'is-invalid' : ""}`}
                            {...register('codigoPostal')}
                            onBlur={ (e) => {
                                const cp = e.target.value;
                                getCodigoPostal(cp)
                            }}
                        />
                        <p className='texto-error'>{errors.codigoPostal?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="6" className='mb-3' >
                        <Form.Label htmlFor="vivienda">Tipo de vivienda</Form.Label>
                        <Form.Select 
                            name="vivienda"
                            className={`${errors.vivienda ? 'is-invalid' : ""} ${combos.vivienda ? 'custom-select' : ''}`}
                            {...register('vivienda', {
                                onChange: (e) => {
                                    llenadoCombo(e);
                                }
                            })}
                        >
                            <option value="">Selecciona el tipo de vivienda</option>
                            {optionTipoVivienda}
                        </Form.Select>
                        <p className='texto-error'>{errors.vivienda?.message}</p>
                    </Form.Group>

                    {
                        showColonia === "0" &&
                        <Form.Group as={Col} xs="6" className='mb-3' >
                            <Form.Label htmlFor="colonia">Colonia</Form.Label>
                            <Form.Select 
                                name="colonia"
                                className={`${errors.colonia ? 'is-invalid' : ""} ${combos.colonia ? 'custom-select' : ''}`}
                                {...register('colonia', {
                                    onChange: (e) => {
                                        llenadoCombo(e);
                                    }
                                })}
                            >
                                <option value="">Selecciona una colonia</option>
                                {optionMunicipios}
                            </Form.Select>
                            <p className='texto-error'>{errors.colonia?.message}</p>
                        </Form.Group>
                    }

                    <Form.Group as={Col} xs="12" className='existe-colonia' >
                        <Form.Check
                            name='existeColonia'
                            label="La colonia no está en el listado"
                            {...register("existeColonia")}
                            onChange={(e) => {
                                let check = e.target.checked;
                                check ? setShowColonia("-1") : setShowColonia("0");
                                
                            }}
                        />
                    </Form.Group>

                    {
                        showColonia === "-1" &&
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Colonia</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="anadeColonia" 
                                placeholder="Añade la colonia"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.anadeColonia ? 'is-invalid' : ""}`}
                                {...register('anadeColonia')}
                            />
                            <p className='texto-error'>{errors.anadeColonia?.message}</p>
                        </Form.Group>
                    }

                    <Form.Group as={Col} xs="6" className='mb-3'>
                        <Form.Label>Alcaldía o municipio</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="alcaldia"
                            placeholder=""
                            maxLength={100}
                            autoComplete="off"
                            className={`${errors.alcaldia ? 'is-invalid' : ""}`}
                            disabled={true}
                            {...register('alcaldia')}
                        />
                        <p className='texto-error'>{errors.alcaldia?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="6" className='mb-3'>
                        <Form.Label>Estado</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="estado" 
                            //value={estado}
                            placeholder=""
                            maxLength={100}
                            autoComplete="off"
                            className={`${errors.estado ? 'is-invalid' : ""}`}
                            disabled={true}
                            {...register('estado')}
                        />
                        <p className='texto-error'>{errors.estado?.message}</p>
                    </Form.Group>

                    <h5 className='texto-subtitulo'>Tiempo de residencia en el domicilio</h5>

                    <Form.Group as={Col} xs="6" className='mb-3'>
                        <Form.Label>Años</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="aniosDomicilio" 
                            placeholder="Años de antigüedad"
                            maxLength={100}
                            autoComplete="nope"
                            className={`${errors.aniosDomicilio ? 'is-invalid' : ""} ${combos.aniosDomicilio ? 'custom-select' : ''}`}
                            {...register('aniosDomicilio')}
                        />
                        <p className='texto-error'>{errors.aniosDomicilio?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="6" className='mb-3' >
                        <Form.Label htmlFor="mesesDomicilio">Meses</Form.Label>
                        <Form.Select 
                            name="mesesDomicilio"
                            className={`${errors.mesesDomicilio ? 'is-invalid' : ""} ${combos.mesesDomicilio ? 'custom-select' : ''}`}
                            {...register('mesesDomicilio', {
                                onChange: (e) => {
                                    llenadoCombo(e);
                                }
                            })}
                        >
                            <option value="">Meses de antigüedad</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                        </Form.Select>
                        <p className='texto-error'>{errors.mesesAntiguedad?.message}</p>
                    </Form.Group>

                </Row>
            </Card.Body>
            <ModalError show={ModalShow} onHide={() => setModalShow(false)}   />
        </Card>
    )
}

export default FormDatosDomicilio