import React from 'react'
import { Card, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { detalleSolicitud, retomarSolicitud } from '../actions/solicitudes';

const CardTabla = ({ items, estatusSelec, listaEstatus }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const terminarSolicitud = (solicitud) => {
        const { estatus, id, estatusDescripcion } = solicitud;
        if(estatus === null && estatusDescripcion === null) {
            dispatch(retomarSolicitud(solicitud));
            navigate("/solicitud");
        } else {
            //const estatusSelect = listaEstatus.find(element => element.value === estatus );
            dispatch(detalleSolicitud(solicitud));
            navigate(`/detalle-solicitud/${id}`);
        }
        //if(estatus === "CONDICIONADO" || estatus === "RECHAZO") {
        /*if(estatus !== null && estatus !== "") {
            dispatch(detalleSolicitud(solicitud));
            navigate(`/detalle-solicitud/${id}`);
        }*/
        /*dispatch(retomarSolicitud(solicitud));
        navigate("/solicitud");*/
    }

    const descripcionEstatus = (estatus, descripcionEstatus) => {
        if(estatus === null) {
            return "En proceso" 
        } else if(estatus === "") {
            return descripcionEstatus
        } else {
            return estatus;
        }
    }

    const listBody = items.map((solicitud, index) => {

        const estatusSelect = listaEstatus.find(element => element.value === solicitud.estatus );
        //console.log(estatusSelec+" === "+solicitud.estatus);
        let claseEstatus = estatusSelect ? estatusSelect.clase : "caso-alterno";
        return ( (estatusSelec === solicitud.estatus && (solicitud.estatusDescripcion === null || solicitud.estatusDescripcion === "FACTURA ILEGIBLE") || 
                estatusSelec === "" && solicitud.estatus === null && (solicitud.estatusDescripcion === null)) || 
                estatusSelec === "Todos" || (estatusSelec === "APROBADO" && (solicitud.estatus !== "RECHAZO" &&
                solicitud.estatus !== "CONDICIONADO" && solicitud.estatus !== "RECHAZO" &&
                solicitud.estatus !== "CANCELADO" && solicitud.estatus !== ""  && solicitud.estatus !== null ||
                (solicitud.estatus === "" && solicitud.estatusDescripcion !== "")
                ))
                )  
                && 
                <tr className={claseEstatus} key={index} onClick={() => terminarSolicitud(solicitud)} >
                    <td>{solicitud.nombre}</td>
                    <td>{solicitud.consecutivo}</td>
                    <td>{ descripcionEstatus(solicitud.estatus, solicitud.estatusDescripcion) }</td>
                    <td>
                        {
                            format(new Date(solicitud.fechaCreacion), 'dd-MM-yyyy')
                        }
                    </td>
                </tr>
        });

    return (
        <Card className='card-tabla'>
            <Card.Body>
                <Table className='table-solicitudes'>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Folio</th>
                            <th>Estado</th>
                            <th>Fecha</th> 
                        </tr>
                    </thead>
                    <tbody>
                        {listBody}
                    </tbody>

                </Table>
            </Card.Body>
        </Card>
    )
}

export default CardTabla