import { type as types } from "../../types";

const initialState = {isSolicitudSeguro: false, dataSolicitud: null};

export const ObtencionSolicitudSeguroReducer = (state = initialState, action) => {

    const {type, payload } = action;

    switch (type) {
        
        case types.getSolicitudSeguro:
            return {
                isSolicitudSeguro: true,
                dataSolicitud: payload
            };
        case types.getSolicitudSeguroFail:
            return initialState;
        default:
            return state;
    }
}