import React from 'react'
import { ProgressBar } from 'react-bootstrap'

const BarProgressStep = ({ pasoActual, pasosTotal }) => {

    const porcentaje = (pasoActual * 100) / pasosTotal;
    
    return (
        <div className='barra-progreso'>
            <ProgressBar variant="primary" now={porcentaje}  />
        </div>
    )
}

export default BarProgressStep