import { type } from "../../types/index";
import SolicitudServices from "../../services/seguros.service";
import { MensajesAlertas } from "../../models/MensajesAlertas";
import { UpdateSecurityKey } from "../../helpers/SecurityKey";


export const obtencionSeguros = (usuario, llaveSeguridad, distribuidor, sucursal) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    
    const request = {
        distribuidor: distribuidor,
        sucursal: sucursal
    } 
    return SolicitudServices.listInsurance(request, llaveSeguridad).then(
        (data) => {
            if(isNaN(data)) {
                if(data.codigo === 0) {
                    dispatch({
                        type: type.getListInsurance,
                        payload: data.result
                    });
                    //dispatch(resetCotizadorSeguro());
                } else {
                    dispatch({
                        type: type.addDataSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.message
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                /*dispatch({
                    type: type.addDataSolicitudSeguroFail
                });*/
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({type: type.setLoading});
            UpdateSecurityKey(data.llaveSeguridad);

            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            return Promise.reject(error);
        }
    );
}

export const obtencionSeguro = (usuario, llaveSeguridad, id, distribuidor, sucursal) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    
    const request = {
        id: id,
        distribuidor: distribuidor,
        sucursal: sucursal
    }
    return SolicitudServices.getInsurance(usuario, llaveSeguridad, request).then(
        (data) => {
            if(isNaN(data)) {
                if(data.codigo === 0) {
                    dispatch({
                        type: type.getSolicitudSeguro,
                        payload: data
                    });
                } else {
                    dispatch({
                        type: type.getSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.message
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.getSolicitudSeguroFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({type: type.setLoading});
            UpdateSecurityKey(data.llaveSeguridad);

            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            return Promise.reject(error);
        }
    );
}