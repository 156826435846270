import { type as types } from "../types/index";

const menu = JSON.parse(sessionStorage.getItem("menuSeleccion"));

//const initialState = user ? { isLoggedIn: true, user, errorMessage: null } : {isLoggedIn: false, user: null, errorMessage: null}

const initialState = menu ? { menuHome: parseInt(menu.menuHome) } : { menuHome: 3 };

export const MenuReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.updateMenu:
            return payload
        default:
            return state;
    }
}
