export const EstatusSolicitudes = [
    {
        id: "1",
        title: "Todas",
        value: "Todos",
        clase: ""
    },
    {
        id: "2",
        title: "En proceso",
        value: null,
        clase: "en-proceso"
    },
    {
        id: "3",
        title: "Aprobadas",
        value: "APROBADO",
        clase: "aprobada-success"
    },
    {
        id: "4",
        title: "Condicionadas",
        value: "CONDICIONADO",
        clase: "condicionado"
    },
    {
        id: "5",
        title: "Rechazadas",
        value: "RECHAZO",
        clase: "rechazada"
    },
    {
        id: "6",
        title: "Canceladas",
        value: "CANCELADO",
        clase: "rechazada"
    },
    {
        id: "7",
        title: "Caso alterno",
        value: "CASOALTERNO",
        clase: "caso-alterno"
    }
]