import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import AlertaMessage from '../components/AlertaMessage';
import CircleStep from '../components/CircleStep';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import ModalErrores from '../components/ModalErrores';
import FormSolicitudSeguro from '../components/seguro/FormSolicitudSeguro';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { regresarPasoSolicitudSeguro } from '../actions/seguros/solicitudSeguros';

const SolicitudSeguroScreen = (props) => {

    const { closeModal, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const obtencionSolicitud = useSelector((state) => state.solicitudSeguro);
    const { paso, idFase} = obtencionSolicitud;
    const obtencionAlerta = useSelector((state) => state.alertMessage);
    const loading = useSelector((state) => state.loading);
    const obtencionError = useSelector((state) => state.mensajeError);
    const { isError } = obtencionError;
    const { tipoError, mensajeError } = obtencionError.dataError;

    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); } ));
    }

    const regresarPantalla = () => {
        let nuevoPaso = (paso-1);
        dispatch(regresarPasoSolicitudSeguro(nuevoPaso));
    }

    return (
        <section className='seccion-solicitud-seguro'>
            <Container>
                <Row>
                    <Col xs={4} className='col-menu-lateral'>
                        <div className='link-inicio'>
                            {
                                (paso === 1) ?
                                    <Nav.Link className="nav-link" onClick={() => salirCotizacion()}><i className='icon-arrow-left me-2'></i>Inicio</Nav.Link>
                                :
                                    (idFase !== 110 && idFase !== 111 && idFase !== 20 && idFase !== 21) ? 
                                        <Nav.Link className="nav-link" onClick={() => regresarPantalla()}><i className='icon-arrow-left me-2'></i>Regresar</Nav.Link>
                                    : ""
                            }
                        </div>
                        <h1 className='texto-titulo'>Nueva cotización</h1>
                        <ul className='opcion-menu'>
                            <li>
                                <CircleStep texto="1" estatus={"completo"} />
                                <h3>Cotización</h3>
                            </li>
                            <li>
                                <CircleStep texto="2"  />
                                <h3>Solicitud  de seguro de contado</h3>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={8} className="col-formulario">
                        
                        <div className='text-end mt-3'>
                            <img src={require('../assets/images/logos/atria-color.png')} alt="Atria" className='logo-atria-color'  />
                        </div>
                        <FormSolicitudSeguro />
                        <Footer />
                    </Col>
                </Row>
                <AlertaMessage show={obtencionAlerta.show} data={obtencionAlerta.data} />
                <Loading show={loading} />
                <ModalErrores show={isError} tipoError={tipoError} mensajeError={mensajeError} />
            </Container>
        </section>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {} 
	};
};
//export default SolicitudSeguroScreen
export default connect(null, mapDispatchToProps)(SolicitudSeguroScreen)