import { type } from "../types/index";
import MarcasService from "../services/marcas.services";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const marcas = (user, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    dispatch({type: type.resetModelos});
    dispatch({type: type.resetAnios});
    dispatch({type: type.resetVersionVehiculo});
    return MarcasService.getMarcas( user, securityKey ).then(
        (data) => {
            dispatch({type: type.setLoading});
            const dataMarcas = data.getMarcasResult;
            if(dataMarcas.Codigo === 0) {
                const reformateo = dataMarcas.Marca.map(item => {
                    const container = {};
                    container["label"] = item.Marca;
                    container["value"] = item.IdMarca;
                    return container;
                });
                dataMarcas.Marca = reformateo;
                dispatch({
                    type: type.getMarcas,
                    payload: dataMarcas.Marca
                });
                UpdateSecurityKey(dataMarcas.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getMarcasFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: dataMarcas.Codigo,
                        mensajeError: dataMarcas.Mensaje
                    }
                });
            }
            
            dispatch({
                type: type.textMessage,
                payload: data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({type: type.setLoading});
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({
                type: type.getMarcasFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
    .catch((error)=>{
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "Hay un problemas con el servicio intente mas tarde"
            }
        });
    });
}


export const marcasSeguros = (user, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    dispatch({type: type.resetModelos});
    dispatch({type: type.resetAnios});
    dispatch({type: type.resetVersionVehiculo});
    return MarcasService.getMarcasSeguros( user, securityKey ).then(
        (data) => {
            dispatch({type: type.setLoading});
            const dataMarcas = data.getMarcasResult;
            if(dataMarcas.Codigo === 0) {
                const reformateo = dataMarcas.Marca.map(item => {
                    const container = {};
                    container["label"] = item.Marca;
                    container["value"] = item.IdMarca;
                    return container;
                });
                dataMarcas.Marca = reformateo;
                dispatch({
                    type: type.getMarcas,
                    payload: dataMarcas.Marca
                });
                UpdateSecurityKey(dataMarcas.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getMarcasFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: dataMarcas.Codigo,
                        mensajeError: dataMarcas.Mensaje
                    }
                });
            }
            
            dispatch({
                type: type.textMessage,
                payload: data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({type: type.setLoading});
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({
                type: type.getMarcasFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
    .catch((error)=>{
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "Hay un problemas con el servicio intente mas tarde"
            }
        });
    });
}

