import axios from "axios";

const baseURL = "https://compras.atria.la/api/v1/app/";
const baseURL2 = "https://compras.atria.la/api/v1/";

const baseURLSeguros = "https://compras.atria.la/api/v1/app/insurance/";

export const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 55000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

/* Instacia para Dashboard */
export const axiosInstance2 = axios.create({
  baseURL: baseURL2,
  timeout: 55000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

/* Instacia para Seguros */
export const axiosInstanceSeguros = axios.create({
  baseURL: baseURLSeguros,
  timeout: 55000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
