import React, {useEffect, useState }  from 'react'
import { Form, Row, Col, Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector, connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { marcas } from '../actions/marcas';
import { getSecurityKey } from '../helpers/SecurityKey';
import { modelos } from '../actions/modelos';
import { anios } from '../actions/anios';
import { versionVehiculo } from '../actions/versionVehiculo';
import { cotizadorPaso1 } from '../actions/cotizador';
import { codigoPostal } from '../actions/codigoPostal';
import { resetCotizador } from '../actions/cotizador';
import ModalDocumentos from './ModalDocumentos';
import { validExpresionRegular } from '../helpers/methodValidate';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { hideAlert, showAlert } from '../actions/alertMessage';

const FormDatosAuto = (props) => {
    const { closeModal, clearCotizador } = props;
    const [documentosState, setDocumentos] = useState({isActive: false, showAlert: false });
    const {isActive, showAlert: showDocument } = documentosState;
    const [showTipoComprobante, setShowTipoComprobante] = useState(false);
    
    const [textoVersionVehiculo, setTextoVersionVehiculo] = useState("")

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dataUser = useSelector((state) => state.auth);
    const securityKey = getSecurityKey();

    const obtencionMarcas = useSelector((state) => state.marcas);
    const { marcas: getMarcas, isMarcas} = obtencionMarcas;

    const obtencionModelos = useSelector((state) => state.modelos);
    const obtencionAnios = useSelector((state) => state.anios);
    const {isAnio, anios: allAnios } = obtencionAnios;
    const obtencionVersionVehiculo = useSelector((state) => state.versionVehiculo);
    const { isVersionVehiculo, version: allVersionVehiculo } = obtencionVersionVehiculo;

    const obtencionCodigoPostal = useSelector((state) => state.codigoPostal);
    const { isCodigoPostal, dataCodigoPostal } = obtencionCodigoPostal;

    const obtencionCotizador = useSelector((state) => state.cotizador);
    const { CP, Auto, Modelo, IdDescripcion,IdDescripcionText, Comprobantes, Historial } = obtencionCotizador;

    const [combos, setCombos] = useState({
        marca: Auto.Marca,
        marcaText: Auto.Marca,
        modelo: Auto.Modelo,
        modeloText: Auto.Modelo,
        anio: Modelo,
        anioText: Modelo,
        version: IdDescripcion,
        versionText: IdDescripcionText,
        tipoComprobante: Comprobantes
    });
    
    useEffect(() => {
        const securityKey = sessionStorage.getItem("securityKey");
        let tipoComprobanteVal = "";
        obtencionCotizador.CP === "" ?
            dispatch(marcas(dataUser.user.correo, securityKey))
        :
            setValue("marca", Auto.Marca);
            setValue("marcaText", Auto.MarcaText);
            setValue("modelo", Auto.Modelo);
            setValue("modeloText", Auto.ModeloText);
            setValue("anio", Modelo);
            setValue("anioText", Modelo);
            setValue("version", IdDescripcion);
            setValue("versionText", IdDescripcionText);
            setValue("codigoPostal", CP);
            setValue("historialCrediticio", Historial);
            if(parseInt(Comprobantes) > 0 || Comprobantes === true ) {
                setValue("clienteIngresos", true);
                setShowTipoComprobante(true);
                tipoComprobanteVal = Comprobantes;
            }
            setValue("tipoComprobante", tipoComprobanteVal);
            
    }, [dispatch, dataUser]);


    const getModelos = (e) => {
        const idMarca = e.target.value;
        const securityKey = sessionStorage.getItem("securityKey");
        dispatch(modelos(dataUser.user.correo, idMarca, securityKey));
    }

    const [stateModelo, setModelo] = useState(Auto.Modelo);
    const getAnios = (e) => {
        const idModelo = e.target.value;
        setModelo(idModelo);
        dispatch(anios(dataUser.user.correo, idModelo, securityKey));
    }

    const getVersionVehiculo = (e) => {
        const anio = parseInt(e.target.value);
        dispatch(versionVehiculo(dataUser.user.correo, stateModelo, anio, combos.marca, securityKey));
    }

    const getCodigoPostal = (e) => {
        const cp = e.target.value;
        if(cp !== "") {
            dispatch(codigoPostal(dataUser.user.correo, String(cp), securityKey));
        }
    }
    
    const optionMarcas = isMarcas ? getMarcas.map((marca, index) =>{
        return <option key={index} value={marca.value} >{marca.label}</option>
    }) : "";

    const optionModelos = obtencionModelos.isModelo ? obtencionModelos.modelos.Tipo.map((modelo, index) =>{
        return <option key={index} value={modelo.value} >{modelo.label}</option>
    }) : "";


    const optionAnios = isAnio ? allAnios.Modelos.map((modelo, index) =>{
        return <option key={index} value={modelo.value} >{modelo.label}</option>
    }) : "";

    const optionVersionVehiculo = isVersionVehiculo ? allVersionVehiculo.Descripcion.map((versionVehiculo, index) =>{
        return <option key={index} value={versionVehiculo.value} >{versionVehiculo.label}</option>
    }) : "";

    const validateAuto = Yup.object({
        marca: Yup.string().required('El campo es requerido'),
        modelo: Yup.string().required('El campo es requerido'),
        anio: Yup.string().required('El campo es requerido'),
        version: Yup.string().required('El campo es requerido'),
        codigoPostal: Yup.string().required('El campo es requerido').matches(validExpresionRegular.numeros, "Introduzca solo números").min(5, "Introduzca al menos ${min} caracteres"),
        tipoComprobante: Yup.string().when('clienteIngresos', {
            is: true,
            then: Yup
                .string()
                .required('El campo es requerido')
        })
    });

    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validateAuto),
        mode: "onChange"
    });

    const onSubmit = (data) => {
        //console.log("onSubmit formDatosAuto",data)
        const { clienteIngresos, historialCrediticio} = data;
        (!clienteIngresos && !historialCrediticio) && !isActive ? setDocumentos({isActive: true, showAlert: true})
        : dispatch(cotizadorPaso1(data,combos));
    }

    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionCotizacion, () => { closeModal(); clearCotizador(); navigate("/home"); }, () => { closeModal(); }  ));
    }

    const llenadoCombo = (e,index) => {
        const { name, value } = e.target;
        const text = e.target.options[index].text;
        const comboSelec = {
            ...combos,
            [name]:value,
            [name+"Text"]:text
        };
        //console.log("comboSelec",comboSelec)
        setCombos(comboSelec);
    }
  return (
    <Card className='card-formulario'>
        <Card.Body className='p-0'>
            <Form className='formulario-datos-auto' onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Form.Group as={Col} xs="4" className='mb-3' >
                        <Form.Label htmlFor="Marca">Marca</Form.Label>
                        <Form.Select 
                            name="marca" 
                            className={`${errors.marca ? 'is-invalid' : ""} ${combos.marca ? 'custom-select' : ''} `} 
                            {...register("marca", {
                                onChange: (e) => {
                                    getModelos(e);
                                    let index = e.target.selectedIndex;
                                    llenadoCombo(e,index);
                                },
                            })}
                        >
                            <option value="">Selecciona la marca del auto</option>
                            {optionMarcas}
                        </Form.Select>
                        <p className='texto-error'>{errors.marca?.message}</p>
                    </Form.Group>
                    <Form.Group as={Col} xs="4" className='mb-3' >
                        <Form.Label htmlFor="modelo">Modelo</Form.Label>
                        <Form.Select 
                            name="modelo" 
                            className={`${errors.modelo ? 'is-invalid' : ""} ${combos.modelo ? 'custom-select' : ''}`} 
                            {...register("modelo", {
                                onChange: (e) => {
                                    let index = e.target.selectedIndex;
                                    getAnios(e);
                                    llenadoCombo(e,index);
                                },
                            })}
                            >
                            <option value="">Modelo del auto</option>
                            {optionModelos}
                        </Form.Select>
                        <p className='texto-error'>{errors.modelo?.message}</p>
                    </Form.Group>
                    <Form.Group as={Col} xs="4" className='mb-3' >
                        <Form.Label htmlFor="anio">Año</Form.Label>
                        <Form.Select 
                            name="anio" 
                            className={`${errors.anio ? 'is-invalid' : ""} ${combos.anio ? 'custom-select' : ''}`}
                            {...register("anio", {
                                onChange: (e) => {
                                    let index = e.target.selectedIndex;
                                    getVersionVehiculo(e);
                                    llenadoCombo(e,index);
                                },
                            })} 
                        >
                            <option value="">Año del auto</option>
                            {optionAnios}
                        </Form.Select>
                        <p className='texto-error'>{errors.anio?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="6" className='mb-3'>
                        <Form.Label htmlFor="version">Versión del vehículo</Form.Label>
                        <Form.Select 
                            name="version" 
                            className={`${errors.version ? 'is-invalid' : ""} ${combos.version ? 'custom-select' : ''}`} 
                            {...register("version", {
                                onChange: (e) => {
                                    let index = e.target.selectedIndex;
                                    setTextoVersionVehiculo(e.target.options[index].text);
                                    llenadoCombo(e,index);
                                }
                            })} 
                        >
                            <option value="">Selecciona la versión del vehículo</option>
                            {optionVersionVehiculo}
                        </Form.Select>
                        <p className='texto-error'>{errors.version?.message}</p>
                    </Form.Group>

                    <Form.Group as={Col} xs="6" className='mb-3'>
                        <Form.Label>Código postal</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="codigoPostal" 
                            placeholder="Escribe el Código postal"
                            maxLength={5}
                            autoComplete="off"
                            className={`${errors.codigoPostal ? 'is-invalid' : ""}`}
                            {...register("codigoPostal")}
                            onBlur={getCodigoPostal}
                        />
                        <p className='texto-error'>{errors.codigoPostal?.message}</p>
                    </Form.Group>
                    <Form.Group as={Col} xs="12" className='mb-3 ps-3 d-flex align-items-center'>
                        <Form.Check
                            name='historialCrediticio'
                            label="El cliente tiene historial crediticio"
                            className='d-inline-block'
                            {...register("historialCrediticio")}
                        />
                        <OverlayTrigger
                            overlay={<Tooltip id="button-tooltip-2">Persona que tiene tarjeta de crédito o préstamo financiero con al menos seis meses de manejo vigente. 
                            </Tooltip>}
                        >
                            <i className='ms-2 icon-info-gris'></i>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group as={Col} xs="12" className='ps-3 mb-3 d-flex align-items-center'>
                        <Form.Check
                            name='clienteIngresos'
                            label="El cliente comprueba ingresos"
                            className='d-inline-block'
                            {...register("clienteIngresos")}
                            onChange={(e) => {
                                let check = e.target.checked;
                                setShowTipoComprobante(check);
                                //check ? showTipoComprobante() : showTipoComprobante("0");
                                
                            }}
                        />
                        <OverlayTrigger
                            overlay={<Tooltip id="button-tooltip-2">
                                Persona comprueba ingresos vía nómina o estados de cuenta, o no tiene cómo comprobar con alguna de estas dos opciones.
                            </Tooltip>}
                        >
                            <i className='ms-2 icon-info-gris'></i>
                        </OverlayTrigger>
                    </Form.Group>
                    
                    {
                        showTipoComprobante &&
                        <Form.Group as={Col} xs="7" >
                            <Form.Label htmlFor="tipoComprobante">Tipo de comprobante</Form.Label>
                            <Form.Select 
                                name="tipoComprobante" 
                                className={`${errors.tipoComprobante ? 'is-invalid' : ""} ${combos.tipoComprobante ? 'custom-select' : ''}`}
                                {...register("tipoComprobante", {
                                    onChange: (e) => {
                                        let index = e.target.selectedIndex;
                                        llenadoCombo(e,index);
                                    }
                                })} 
                                
                            >
                                <option value="">Selecciona el tipo de comprobante</option>
                                <option value="1">Estado de cuenta</option>
                                <option value="2">Comprobante de nómina</option>
                            </Form.Select>
                            <p className='texto-error'>{errors.tipoComprobante?.message}</p>
                        </Form.Group>
                    }

                    <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                        <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
                    </Form.Group>

                    <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                        <Button type="submit" className="btn btn-primary w-100" disabled={ (isCodigoPostal) ? false : true } >Siguiente</Button>
                    </Form.Group>

                </Row>
            </Form>
        </Card.Body>
        
        <ModalDocumentos show={showDocument} onHide={() => setDocumentos({isActive: true, showAlert: false})} />
    </Card>
    
  )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
        dispatch(resetCotizador());
        } 
	};
};
//exp
//export default FormDatosAuto
export default connect(null, mapDispatchToProps)(FormDatosAuto)