import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { hideModalError } from '../actions/mensajesErrores';
import { logout } from '../actions/auth';
import { regresarPaso, resetCotizador } from '../actions/cotizador';
import { resetSolicitud } from '../actions/solicitudes';

const ModalErrores = ({ show, tipoError, mensajeError, seccion, ...props } ) => {
    const { closeModal, closeModalSesion, closeModalRerturn, regresarPasoCotiz } = props;
    const navigate = useNavigate();
    const btnEvento = (tipoError) => {
        if(tipoError === 2 && seccion === "planesPago") {
            regresarPasoCotiz(2);
        } else if(tipoError === 3 || tipoError === 1 || tipoError === 4) {
            closeModalSesion();
        } else if(tipoError === -1 || tipoError === 2 || tipoError === 200) {
            closeModalRerturn();
            if(tipoError === -1 && seccion === "cp" || tipoError === -1 && mensajeError === "Código Postal inexistente. Inténte con otro Código Postal.") {
            } else {
                navigate("/home");
            }
        } else {
            closeModal();
        }
    }

    return (
        <Modal
            show = {show}
            onExit={ tipoError === 3 ? closeModalSesion : closeModal }
            centered
            className='modal-error'
        >
            <Modal.Header closeButton onClick={() => btnEvento(tipoError)}></Modal.Header>
            <Modal.Body>
                <h4 className='texto-error'>Uy, algo pasó aquí...</h4>
                <p>{mensajeError}</p>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3 mx-auto" >
                        <Button type="button" className="btn btn-primary w-100" onClick={() => btnEvento(tipoError)} >Aceptar</Button>
                </Form.Group>
        </Modal.Body>
        </Modal>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideModalError()),
        closeModalSesion: () => {
            dispatch(hideModalError());
            dispatch(logout());
        },
        closeModalRerturn: () => {
            dispatch(hideModalError());
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
        },
        regresarPasoCotiz: (paso) => {
            dispatch(hideModalError());
            dispatch(regresarPaso(paso));
        }
	};
};

//export default ModalErrores
export default connect(null, mapDispatchToProps)(ModalErrores)