export const type = {
    login: "[Auth] login",
    loginSucces: "LOGIN_SUCCESS",
    loginFail: "LOGIN_FAIL",
    logout: "[Auth] logout",

    olvidoContrasenia: "OLVIDO_CONTRASENIA",
    olvidoContraseniaFail: "OLVIDO_CONTRASENIA_FAIL",

    updateKey: "UPDATE_KEY",
    getKey: "GET_KEY",

    setLoading: "SET_LOADING",
    setError: "SET_ERROR",
    closetError: "CLOSET_ERROR",

    textMessage: "SET_MESSAGE",
    clearMessage: "CLEAR_MESSAGE",

    cotizadorUpdate: "UPDATE_DATA",

    getMarcas: "GET_MARCAS",
    getMarcasFail: "GET_MARCAS_FAIL",

    getModelos: "GET_MODELOS",
    getModelosFail: "GET_MODELOS_FAIL",
    resetModelos: "RESET_MODELOS",

    getAnios: "GET_Anios",
    getAniosFail: "GET_Anios_FAIL",
    resetAnios: "RESET_ANIOS",

    getVersionVehiculo: "GET_VERSION_VEHICULO",
    getVersionVehiculoFail: "GET_VERSION_VEHICULO_FAIL",
    resetVersionVehiculo: "RESTE_VERSION_VEHICULO",

    addCotizador: "ADD_VERSION_VEHICULO",
    resetCotizador: "RESET_COTIZADOR",
    backStepCotizador: "BACK_STEP_COTIZADOR",

    getCotizaSeguros: "GET_COTIZA_SEGUROS",
    getCotizaSegurosFail: "GET_COTIZA_SEGUROS_FAIL",
    
    getCodigoPostal: "GET_CODIGO_POSTAL",
    getCodigoPostalFail: "GET_CODIGO_POSTAL_FAIL",

    getPostCotiza: "GET_POST_COTIZA",
    getPostCotizaFail: "GET_POST_COTIZA_FAIL",
    resetPostCotiza: "RESET_POST_COTIZA",

    getSucursalSelection: "GET_SUCURSAL_SELECTION",
    updateSucursalSelection: "UPDATE_SUCURSAL_SELECTION",

    getPutCotiza: "GET_PUT_COTIZA",
    getPutCotizaFail: "GET_PUT_COTIZA_FAIL",

    getSolicitudes: "GET_SOLICITUDES",
    getSolicitudesLoading: "GET_SOLICITUDES_LOADING",
    getSolicitudesFail: "GET_SOLICITUDES_FAIL",
    getPlanAutorizado: "GET_PLAN_AUTORIZADO",
    getPlanAutorizadoFail: "GET_PLAN_AUTORIZADO_FAIL",

    showAlertMessage: "SHOW_ALERT_MESSAGE",
    hideAlertMessage: "HIDE_ALERT_MESSAGE",
    showModalSucess: "SHOW_MODAL_SUCESS",
    hideModalSucess: "HIDE_MODAL_SUCCES",

    addSolicitud: "ADD_SOLICITUD",
    updateSolicitud: "UPDATE_SOLICITUD",
    failSolicitud: "FAIL_SOLICITUD",
    resetSolicitud: "RESET_SOLICITUD",

    getCatalagos: "GET_CATALOGOS",
    getCatalagosFail: "GET_CATALOGOS_FAIL",

    getRFC: "GET_RFC",
    getRFCFail: "GET_RFC_FAIL",
    resetRFC: "RESET_RFC",

    updateSaveSolicitud: "UPDATE_SAVE_SOLICITUD",
    updateSaveSolicitudFail: "UPDATE_SAVE_SOLICITUD_FAIL",

    updatePartialSolicitud: "UPDATE_PARTIAL_SOLICITUD",
    updatePartialSolicitudFail: "UPDATE_PARTIAL_SOLICITUD_FAIL",

    sendSMS: "SEND_SMS",
    sendSMSFail: "SEND_SMS_FAIL",
    sendCall: "SEND_CALL",
    sendCallFail: "SEND_CALL_FAIL",

    getScoreResult: "GET_SCORE_RESULT",
    getScoreResultFail: "GET_SCORE_RESULT_FAIL",

    getJobs: "GET_JOBS",
    getJobsFail: "GET_JOBS_FAIL",

    addDataLaboral: "ADD_DATA_LABORAL",
    addDataLaboralFail: "ADD_DATA_LABORAL_FAIL",
    
    saveSolicitud: "SAVE_SOLICITUD",
    saveSolicitudFail: "SAVE_SOLICITUD_FAIL",
    getHistorialSolicitud: "GET_HITORIAL_SOLICITUD",
    getHistorialSolicitudFail: "GET_HITORIAL_SOLICITUD_FAIL",

    updateMenu: "UPDATE_MENU",

    sendDoctoAdicional: "SEND_DOCTO_ADICIONAL",
    sendDoctoAdicionalFail: "SEND_DOCTO_ADICIONAL_FAIL",

    getAssignedCommercial: "GET_ASSIGNED_COMMERCIAL",
    getAssignedCommercialFail: "GET_ASSIGNED_COMMERCIAL_FAIL",

    getPurchaseDetail: "GET_PURCHASE_DETAIL",
    getPurchaseDetailFail: "GET_PURCHASE_DETAIL_FAIL",

    getDashboard: "GET_DASHBOARD",
    getDashboardFail: "GET_DASHBOARD_FAIL",
    updateImageProfile: "UPDATE_IMAGE_PROFILE",

    setImageProfile: "SET_IMAGE_PROFILE",
    setImageProfileFail: "SET_IMAGE_PROFILE_FAIL",

    // Seccion Seguros
    addDataCotizadorSeguro: "ADD_DATA_COTIZADOR_SEGURO",
    addDataCotizadorSeguroFail: "ADD_DATA_COTIZADOR_SEGURO_FAIL",
    resetCotizadorSeguro: "RESET_COTIZADOR_SEGURO",
    addDataSolicitudSeguro: "ADD_DATA_SOLICITUD_SEGURO",
    addDataSolicitudSeguroFail: "ADD_DATA_SOLICITUD_SEGURO_FAIL",
    resetSolicitudSeguro: "RESET_SOLICITUD_SEGURO",
    getCalogosSeguros: "GET_CATALOGOS_SEGUROS",
    getCalogosSegurosFail: "GET_CATALOGOS_SEGUROS_FAIL",
    getListInsurance: "GET_LIST_INSURANCE",
    getListInsuranceFail: "GET_LIST_INSURANCE_FAIL",
    uploadDocument: "UPLOAD_DOCUMENT",
    uploadDocumentFail: "UPLOAD_DOCUMENT_FAIL",
    getSolicitudSeguro: "GET_SOLICITUD_SEGURO",
    getSolicitudSeguroFail: "GET_SOLICITUD_SEGURO_FAIL"
}