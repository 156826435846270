import { type as types } from "../types/index";

const initialState = {isError: false, dataError: {
    tipoError: 0,
    mensajeError: "",
    seccion: ""
}};

export const MensajesErroresReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.setError:
            return {
                isError: true,
                dataError: payload
            };
        case types.closetError: 
            return {
                isError: false,
                dataError: initialState
            }
        default:
            return state;
    }
}
