import { type } from "../types/index";
import AuthService from "../services/auth.services";

export const login = (user, password) => (dispatch) => {
    dispatch({type: type.clearMessage});
    return AuthService.login( user, password ).then(
        (data) => {
            //console.log(data);
            if(data.Codigo === 0) {
                dispatch({
                    type: type.loginSucces,
                    payload: data
                });
                sessionStorage.setItem("sucursalSeleccionada", JSON.stringify(data.Sucursales[0]));
                dispatch({
                    type: type.updateSucursalSelection,
                    payload: data.Sucursales[0]
                });
            } else if(data.Codigo !== 0) {
                dispatch({
                    type: type.loginFail,
                    payload: {
                        errorMessage: data.Mensaje
                    }
                });
            } else {
                dispatch({
                    type: type.loginFail,
                    payload: {
                        errorMessage: data
                    }
                });
            }
            return Promise.resolve();
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            //console.log(message);
            dispatch({
                type: type.showAlertMessage
            });
            return Promise.reject();
        }
    )
}

export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
        type: type.clearMessage
    });
    dispatch({
        type: type.logout
    });
    dispatch({
        type: type.resetCotizador
    });
    dispatch({
        type: type.resetSolicitud
    });
}