import { type as types } from "../types/index";

const initialState = { showModalSucess: false, dataMensaje: { mensaje1: "", mensaje2: [] }};

export const ModalSucessReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.showModalSucess:
            const result = {
                ...state, 
                showModalSucess: true,
                dataMensaje: payload
            };
            return result;

        case types.hideModalSucess:
            return {
                ...state, 
                showModalSucess: false,
                dataMensaje: initialState
            };
        default:
            return state;
    }
}
