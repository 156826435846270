import React from 'react'
import { Container, Row, Col, ListGroup} from 'react-bootstrap';

import logo_atria from "../assets/images/logos/atria-white.png";

const Footer = () => {
  return (
    <footer className='footer'>
        <Container>
            <Row>
                <Col md={7} className="px-0">
                    <ListGroup horizontal className='datos-footer'>
                        <ListGroup.Item>Aviso de Privacidad</ListGroup.Item>
                        <ListGroup.Item>Términos y condiciones</ListGroup.Item>
                        <ListGroup.Item>+52 55 5418 9435</ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col md={5}>
                    <ListGroup horizontal className='datos-footer justify-content-end'>
                        <ListGroup.Item>Atria 2022 © Todos los derechos reservados</ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    </footer>
  )
}

export default Footer