import React from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { resetCotizador } from '../actions/cotizador';
import { resetRFC } from '../actions/rfc';
import { regresarPaso, resetSolicitud } from '../actions/solicitudes';
import AlertaMessage from '../components/AlertaMessage';
import CircleStep from '../components/CircleStep'
import Footer from '../components/Footer'
import FormSolicitudCredito from '../components/FormSolicitudCredito'
import Loading from '../components/Loading';
import ModalErrores from '../components/ModalErrores';
import { MensajesAlertas } from '../models/MensajesAlertas';


const SolicitudCreditoScreen = (props) => {
    const { closeModal, clearCotizador } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const obtencionAlerta = useSelector((state) => state.alertMessage);

    const loading = useSelector((state) => state.loading);

    const obtencionError = useSelector((state) => state.mensajeError);
    const { isError } = obtencionError;
    const { tipoError, mensajeError } = obtencionError.dataError;

    const obtencionSolicitud = useSelector((state) => state.solicitudes);
    const { paso } = obtencionSolicitud.dataSolicitud;
    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); } ));
    }

    const regresarPantalla = () => {
        const nuevoPaso = (paso-1);
        dispatch(regresarPaso(nuevoPaso));
    }

    const botonRetroceso = () => {
        if(paso === 1 || paso === 5 || paso === 7) {
            return <Nav.Link className="nav-link" onClick={() => salirCotizacion()}><i className='icon-arrow-left me-2'></i>Inicio</Nav.Link>
        } else if(paso === 8) {
            return <span className="nav-link-espacio">&nbsp;</span>;
            //return <p className='mb-0'>&nbsp;</p>
            //return <Nav.Link className="nav-link" onClick={()=>{}}>&nbsp;</Nav.Link>
        } else {
            return <Nav.Link className="nav-link" onClick={() => regresarPantalla()}><i className='icon-arrow-left me-2'></i>Regresar</Nav.Link>
        }
    }

    return (
        <section className='seccion-solicitud-credito'>
            <Container>
                <Row>
                    <Col xs={4} className='col-menu-lateral'>
                        <div className='link-inicio'>
                            {botonRetroceso()}
                        </div>
                        <h1 className='texto-titulo'>Nueva cotización</h1>
                        <ul className='opcion-menu'>
                            <li>
                                <CircleStep texto="1" estatus={"completo"} />
                                <h3>Cotización</h3>
                            </li>
                            <li>
                                <CircleStep texto="2"  />
                                <h3>Solicitud  de crédito</h3>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={8} className="col-formulario">

                            <div className='text-end mt-3'>
                                <img src={require('../assets/images/logos/atria-color.png')} alt="Atria" className='logo-atria-color'  />
                            </div>
                            <FormSolicitudCredito />

                        <Footer />
                    </Col>
                </Row>
                <AlertaMessage show={obtencionAlerta.show} data={obtencionAlerta.data} />
                <Loading show={loading} />
                <ModalErrores show={isError} tipoError={tipoError} mensajeError={mensajeError} />
            </Container>
        </section>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
            dispatch(resetRFC());
        } 
	};
};
//export default SolicitudCreditoScreen
export default connect(null, mapDispatchToProps)(SolicitudCreditoScreen)