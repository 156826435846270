import { SaveSolicitud } from "../models/SaveSolicitud";
import { type as types } from "../types/index";

const initialState = {isSave: false, dataSaveSolicitud: SaveSolicitud};

export const SaveSolicitudReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.updateSaveSolicitud:
            const result = {
                ...state, 
                isSave: true,
                dataSaveSolicitud: payload
            };
            //console.log(result);
            return result;
        case types.updateSaveSolicitudFail:
            return {
                ...state,
                isSave: false,
                dataSaveSolicitud: initialState
            }
        default:
            return state;
    }
}
