import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector, connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { updatePartialReferencias } from '../actions/updatePartialSolicitud';
import { getSecurityKey } from '../helpers/SecurityKey';
import { validExpresionRegular } from '../helpers/methodValidate';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { resetSolicitud } from '../actions/solicitudes';
import { resetCotizador } from '../actions/cotizador';
import { resetRFC } from '../actions/rfc';

const FormReferencias = (props) => {
    const { closeModal, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const securityKey = getSecurityKey();

    const dataUser = useSelector((state) => state.auth);
    const dataSolicitud = useSelector((state) => state.solicitudes);
    const validateReferencias = Yup.object({
        nombreReferencia1: Yup.string().required('El campo es requerido'),
        telefonoReferencia1: Yup.string().required('El campo es requerido')
            .matches(validExpresionRegular.numeros, "Introduzca solo números")
            .min(10, 'Introduzca al menos ${min} caracteres')
            .notOneOf([Yup.ref('telefonoReferencia2'), Yup.ref('telefonoReferencia3')], "Los números telefónicos de las referencias o puenden coincidir"),
        nombreReferencia2: Yup.string().required('El campo es requerido'),
        telefonoReferencia2: Yup.string().required('El campo es requerido')
            .matches(validExpresionRegular.numeros, "Introduzca solo números")
            .min(10, 'Introduzca al menos ${min} caracteres')
            .notOneOf([Yup.ref('telefonoReferencia1'), Yup.ref('telefonoReferencia3')], "Los números telefónicos de las referencias o puenden coincidir"),
        nombreReferencia3: Yup.string().required('El campo es requerido'),
        telefonoReferencia3: Yup.string().required('El campo es requerido')
            .matches(validExpresionRegular.numeros, "Introduzca solo números")
            .min(10, 'Introduzca al menos ${min} caracteres')
            .notOneOf([Yup.ref('telefonoReferencia2'), Yup.ref('telefonoReferencia1')], "Los números telefónicos de las referencias o puenden coincidir")
    });

    const { register, handleSubmit, formState: { errors}, setValue } = useForm({
        resolver: yupResolver(validateReferencias),
        mode: "onChange"
    });
    
    const onSubmit = (data) => {
        dispatch(updatePartialReferencias(data, dataSolicitud, dataUser.user.correo, securityKey))
    };

    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); }  ));
    }

    useEffect(() => {
        const { telefonoReferencia1, telefonoReferencia2, telefonoReferencia3,
            nombreReferencia1, nombreReferencia2, nombreReferencia3
        } = dataSolicitud.dataSolicitud;
        setValue("nombreReferencia1", nombreReferencia1);
        setValue("telefonoReferencia1", telefonoReferencia1);
        setValue("nombreReferencia2", nombreReferencia2);
        setValue("telefonoReferencia2", telefonoReferencia2);
        setValue("nombreReferencia3", nombreReferencia3);
        setValue("telefonoReferencia3", telefonoReferencia3);

    }, []);
    

    return (
        <Card className='card-formulario '>
            <Card.Body className='px-0'>
                <Form className='form-referencias' onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Card.Title className='my-3'><i className='icon-user me-3'></i>Referencia 1</Card.Title>

                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Nombre completo</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="nombreReferencia1"
                                placeholder="Escribe el nombre completo"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.nombreReferencia1 ? 'is-invalid' : ""}`}
                                {...register("nombreReferencia1")}
                            />
                            <p className='texto-error'>{errors.nombreReferencia1?.message}</p>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="telefonoReferencia1"
                                placeholder="Escribe un teléfono de 10 dígitos"
                                maxLength={10}
                                autoComplete="off"
                                className={`${errors.telefonoReferencia1 ? 'is-invalid' : ""}`}
                                {...register("telefonoReferencia1")}
                            />
                            <p className='texto-error'>{errors.telefonoReferencia1?.message}</p>
                        </Form.Group>

                        <Card.Title className='my-3'><i className='icon-user-duo me-3'></i>Referencia 2</Card.Title>

                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Nombre completo</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="nombreReferencia2"
                                placeholder="Escribe el nombre completo"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.nombreReferencia2 ? 'is-invalid' : ""}`}
                                {...register("nombreReferencia2")}
                            />
                            <p className='texto-error'>{errors.nombreReferencia2?.message}</p>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="telefonoReferencia2"
                                placeholder="Escribe un teléfono de 10 dígitos"
                                maxLength={10}
                                autoComplete="off"
                                className={`${errors.telefonoReferencia2 ? 'is-invalid' : ""}`}
                                {...register("telefonoReferencia2")}
                            />
                            <p className='texto-error'>{errors.telefonoReferencia2?.message}</p>
                        </Form.Group>

                        <Card.Title className='my-3'><i className='icon-user-triple me-3'></i>Referencia 3</Card.Title>

                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Nombre completo</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="nombreReferencia3"
                                placeholder="Escribe el nombre completo"
                                maxLength={100}
                                autoComplete="off"
                                className={`${errors.nombreReferencia3 ? 'is-invalid' : ""}`}
                                {...register("nombreReferencia3")}
                            />
                            <p className='texto-error'>{errors.nombreReferencia3?.message}</p>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="telefonoReferencia3"
                                placeholder="Escribe un teléfono de 10 dígitos"
                                maxLength={10}
                                autoComplete="off"
                                className={`${errors.telefonoReferencia3 ? 'is-invalid' : ""}`}
                                {...register("telefonoReferencia3")}
                            />
                            <p className='texto-error'>{errors.telefonoReferencia3?.message}</p>
                        </Form.Group>
                    </Row>
                    <Row className='mt-3 pt-3'>
                        <Col xs={6} className='mt-16'>
                            <Button type="button" className="btn btn-light w-100 h-100" onClick={salirCotizacion}>Cancelar</Button>
                        </Col>
                        <Col xs={6} className='mt-16'>
                            <Button type="submit" className="btn btn-primary w-100 h-100" >Continuar con el resumen de la solicitud</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    )
}
const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
            dispatch(resetSolicitud());
            dispatch(resetRFC());
        } 
	};
};
//export default FormReferencias
export default connect(null, mapDispatchToProps)(FormReferencias)