import React from 'react'
import { Form } from 'react-bootstrap'

const RadioBox = ({id, name, value, classIcon, textoInformacion, numero, register, errors, }) => {
  return (
    <Form.Check type="radio" id={id} className='radio-box'>
        <Form.Check.Input
            id={id}
            name={name}
            type="radio"
            value={value}
            {...register(name) }
        />
            <Form.Check.Label>
            <i className={`mb-3 ${classIcon}`}></i>
            <p className='texto-informacion'>{textoInformacion}</p>
            <p className='numero'>{numero}</p>
        </Form.Check.Label>
    </Form.Check>
  )
}

export default RadioBox