import {axiosInstance} from "../helpers/axios";

const sendDoctoAdicional = (data) => {
    return axiosInstance.post('sendDoctoAdicional/', data ).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

const sendPurchaseDocumentsCIKD = (data) => {
    return axiosInstance.post('sendPurchaseDocumentsCIKD/', data ).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

export default { sendDoctoAdicional, sendPurchaseDocumentsCIKD };