import { format } from 'date-fns';
import { es } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux';
import { Container, Navbar } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';

import {  readFile } from '../helpers/functionGlobal';
import { getSecurityKey } from '../helpers/SecurityKey';
import { setProfileImage } from '../actions/imageProfile';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { SIZE_IMAGE, TYPES_FILES_IMAGE } from '../helpers/constants';

const BarraPerfil = (props) => {
    const { closeModal, nombre, imgProfile } = props;
    const date = new Date();
    const [error, setError] = useState(false);
    //const fileTypes = ["JPEG", "PNG", "JPG", "BMP"];
    
    const dispatch = useDispatch();
    const securityKey = getSecurityKey();

    const dataUser = useSelector((state) => state.auth);
    const { user } = dataUser;

    const handleChange = async (file, nameInput) => {
        let archivo = await readFile(file);
        dispatch(setProfileImage(user.correo, archivo, securityKey));
    }
    useEffect(() => {
        if(error) {
            dispatch(showAlert(MensajesAlertas.mensajeImagePerfilMaxmLength, () => { closeModal(); } ));
            setError(false);
        }
    }, [error]);

    return (
        <Navbar className='barra-perfil'>
            <Container>
                <div>
                    <p className='perfil'>Hola {nombre}</p>
                    <p className='fecha-actual'>{format(new Date(),'dd')} de {format(new Date(),'MMMM', { locale: es })}</p>
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                    <span className='numero-notificaciones'>99+</span>
                    <i className='icon-notificacion'></i>
                    
                    <FileUploader
                        name="imageProfile"
                        maxSize={SIZE_IMAGE}
                        types={TYPES_FILES_IMAGE}
                        onSizeError = {(file) => {
                            setError(true);
                        }}
                        handleChange={handleChange}
                    >
                        <img
                            alt="Foto de perfil"
                            src={imgProfile}
                            className="foto-perfil"
                        />
                    </FileUploader>
                    <p className='texto-error'>{error}</p>
                </div>
            </Container>
        </Navbar>
    )
}

const mapDispatchToProps1 = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
	};
};
//export default BarraPerfil
export default connect(null, mapDispatchToProps1)(BarraPerfil)