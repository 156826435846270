export const MenuSolicitudes = [
    {
        id: "1",
        title: "Todas",
        value: "Todos"
    },
    {
        id: "2",
        title: "En proceso",
        value: ""
    },
    {
        id: "3",
        title: "Aprobadas",
        value: "APROBADO"
    },
    {
        id: "4",
        title: "Condicionadas",
        value: "CONDICIONADO"
    },
    {
        id: "5",
        title: "Rechazadas",
        value: "RECHAZO"
    },
    {
        id: "6",
        title: "Canceladas",
        value: "CANCELADO"
    }
]