import React from 'react';
import { Card } from 'react-bootstrap';

const CardVersusCard = ({dataCard}) => {
    const { title, body, description, subtitleColor, vsColor, data } = dataCard;
    const dataTotal = data.length;

    return (
        <Card className="card-gris">
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Text style={{color: subtitleColor }}>
                    {
                        dataTotal > 0 && data[0].is_best_seller ? description : body

                    }
                </Card.Text>
                <div className='row position-relative'>
                    {
                        dataTotal > 0 && !data[0].is_best_seller ?
                        <>
                            <div className='circle' style={{backgroundColor: vsColor }} >VS</div>
                            <div className='col-6'>
                                <Card className='card-perfiles'>
                                    <Card.Body>
                                        <img src={data[0].picture} className="foto-perfil" alt={data[0].title} />
                                        <Card.Text className='pronombre'>{data[0].label}</Card.Text>
                                        <Card.Text className='nombre mb-1'>{data[0].title}</Card.Text>
                                        <Card.Text className='cantidad'>{data[0].subtitle}</Card.Text>
                                        <small className="card-text-mini">{data[0].textMuted}</small>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='col-6'>
                                <Card className='card-perfiles'>
                                    <Card.Body>
                                        <img src={data[1].picture} className="foto-perfil" alt={data[1].title} />
                                        <Card.Text className='pronombre'>{data[1].label}</Card.Text>
                                        <Card.Text className='nombre mb-1'>{data[1].title}</Card.Text>
                                        <Card.Text className='cantidad'>{data[1].subtitle}</Card.Text>
                                        <small className="card-text-mini">{data[1].textMuted}</small>
                                    </Card.Body>
                                </Card>
                            </div>
                        </>
                        :
                        <>
                            {
                                dataTotal > 0 && data[0] &&
                                <div className='col-12'>
                                    <Card className='card-perfiles'>
                                        <Card.Body>
                                            <img src={data[0].picture} className="foto-perfil" alt='Imagen de perfil' />
                                            <Card.Text className='pronombre'>{data[0].label}</Card.Text>
                                            <Card.Text className='nombre mb-1'>{data[0].title}</Card.Text>
                                            <Card.Text className='cantidad'>{data[0].subtitle}</Card.Text>
                                            <small className="card-text-mini">{data[0].textMuted}</small>
                                        </Card.Body>
                                    </Card>
                                </div>
                            }
                        </>
                    }
                </div>
            </Card.Body>
        </Card>
    )
}

export default CardVersusCard