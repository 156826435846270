import DashboardService from "../services/dashboard.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { type } from "../types/index";
import { DataDashboard } from "../models/DataDashboard";
import { MensajesAlertas } from "../models/MensajesAlertas";

export const getDashboard = (sucursal, distribuidor, securitykey) => (dispatch) => {
    //console.log(securitykey);
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    return DashboardService.getDashBoard(sucursal, distribuidor, securitykey).then(
        (data) => {
            if(isNaN(data)) {
                dispatch({
                    type: type.getDashboard,
                    payload: data
                });
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.getDashboardFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({type: type.setLoading});
            UpdateSecurityKey(data.llaveSeguridad);

            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.textMessage,
                payload: message
            });

            dispatch({
                type: type.getDashboardFail
            });
        }
    )
}