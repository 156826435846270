import { type } from "../types/index";
import AssignedCommercialService from "../services/assigned.commercial.support.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const getAssignedCommercial = (user, consecutivo, securitykey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const userAgent = window.navigator.userAgent;
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: user,
        consecutivo: consecutivo,
        llaveSeguridad: securitykey
    }
    return AssignedCommercialService.getAssignedCommercialSupport(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.codigo === 0) {
                dispatch({
                    type: type.getAssignedCommercial,
                    payload: data
                });
                UpdateSecurityKey(data.llaveSeguridad);
            } else {
                dispatch({
                    type: type.getAssignedCommercialFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            
            dispatch({
                type: type.textMessage,
                payload: data,
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getAssignedCommercialFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
