import { type as types } from "../../types";

const initialState = {isSolicitudesSeguros: false, dataSolicitudes: null};

export const ObtencionSolicitudesSegurosReducer = (state = initialState, action) => {

    const {type, payload } = action;

    switch (type) {
        
        case types.getListInsurance:
            return {
                isSolicitudesSeguros: true,
                dataSolicitudes: payload
            };
        case types.getListInsuranceFail:
            return initialState;
        default:
            return state;
    }
}