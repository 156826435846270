export const DataDashboard = {
	"username": "",
	"fullName": "",
	"profilePicture": "",
	"firstCard": {
		"title": "",
		"strokeColor": "",
		"trackStrokeColor": "",
		"tooltipText": "",
		"percentage": 0,
		"dataProgressBar": {
			"label": "",
			"value1": "",
			"label2": "",
			"value2": ""
		},
		"textMuted": ""
	},
	"secondCard": {
		"title": "",
		"body": "",
		"description": "",
		"subtitleColor": "",
		"vsColor": "",
		"data": []
	},
	"thirdCard": {
		"title": "",
		"subtitle": "",
		"columns": [],
		"rows": []
	},
	"llaveSeguridad": ""
}