export function formatoMonto(e) {
  const monto = e.target.value;
  const cantidad = monto.replace(/[^0-9\\.]+/g, "");
  /*return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cantidad);*/
  return Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(cantidad);
}

export function MotoSinFormato(monto) {
  return monto.replace(/[^0-9\\.]+/g, "");
}

export function FormatoCantidad(
  numero,
  decimales,
  separador_decimal,
  separador_miles
) {
  numero = parseFloat(numero);
  if (isNaN(numero)) {
    return "";
  }
  if (decimales !== undefined) {
    // Redondeamos
    numero = numero.toFixed(decimales);
    //console.log(numero);
  }
  // Convertimos el punto en separador_decimal
  numero = numero
    .toString()
    .replace(".", separador_decimal !== undefined ? separador_decimal : ",");
  if (separador_miles) {
    // Añadimos los separadores de miles
    var miles = new RegExp("(-?[0-9]+)([0-9]{3})");
    while (miles.test(numero)) {
      numero = numero.replace(miles, "$1" + separador_miles + "$2");
    }
  }
  return numero;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  //return [year, month, day].join('-');
  return [day, month, year].join("/");
}

export function revertDate(date) {
  let dia = date.substr(0, 2),
    mes = date.substr(3, 2),
    anio = date.substr(6, 4);
  let newDate = new Date(anio + "/" + mes + "/" + dia);
  return newDate;
}

export function readFile(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (x) => {
      let archivo = reader.result;
      let validExtensions = [
        "data:image/jpeg;base64,",
        "data:image/png;base64,",
        "data:image/bmp;base64,",
        "application/pdf;base64,",
      ];
      let separacionArchivo = Array;
      for (let i = 0; i < validExtensions.length; i++) {
        separacionArchivo = archivo.split(validExtensions[i]);

        if (separacionArchivo[1] !== undefined) {
          break;
        }
      }
      resolve(separacionArchivo[1]);
    };
    reader.onerror = (error) => reject(error);
  });
}

export function extensionArchivo(type) {
  let tipoArchivo = "";
  if (type === "image/png") {
    tipoArchivo = "PNG";
  } else if (type === "application/pdf") {
    tipoArchivo = "PDF";
  } else if (type === "image/jpeg") {
    tipoArchivo = "JPG";
  }
  return tipoArchivo;
}

export function etiquetaDoc(nameFile) {
  let etiquetaDocumento = "";
  if (nameFile === "frenteINE") {
    etiquetaDocumento = "FIOKD";
  } else if (nameFile === "reversoIne") {
    etiquetaDocumento = "RIOKD";
  } else if (nameFile === "comprobanteDomicilio") {
    etiquetaDocumento = "CIKD";
  } else if (nameFile === "documentosAdicionales") {
    etiquetaDocumento = "DOCADKD";
  }
  return etiquetaDocumento;
}

export const logoImg = require.context("../assets/images/logos", true);

export function logoSeguro(aseguradora) {
  switch (aseguradora) {
    case "ANA":
      return "ana.svg";
    case "QUALITAS":
      return "QUALITAS.png";
    case "GDS":
      return "GDS.svg";
    case "GNP":
      return "GNP.png";
    default:
      return "";
  }
}

export function iconEstatus(estatus) {
    let iconClass = "icon-info";
    if(estatus === "cancel") {
        iconClass = "icon-error";
    } else if( estatus === "ok") {
        iconClass = "icon-success";
    } else if( estatus === "warning") {
        iconClass = "icon-warning";
    }
    return iconClass;
}