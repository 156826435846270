import { type as types } from "../types/index";

const initialState = {isMarcas: false, marcas: null};

export const MarcasReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (action.type) {
        case types.getMarcas:
            const result = {
                ...state, 
                isMarcas: true,
                marcas: payload
            };
            return result;
        case types.getMarcasFail:
            return {
                ...state,
                isMarcas: false,
                marcas: null
            }
        default:
            return state;
    }
}
