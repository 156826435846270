import { type } from "../../types/index";
import SolicitudServices from "../../services/seguros.service";
import { formatDate } from "../../helpers/functionGlobal";
import { resetCotizadorSeguro } from "./cotizadorSeguros";
import { MensajesAlertas } from "../../models/MensajesAlertas";
import { UpdateSecurityKey } from "../../helpers/SecurityKey";

export const solicitudPaso1 = (data, usuario, llaveSeguridad, proveedor, distribuidor, sucursal, idAseguradora, idDescripcion, idCotizacionSeguro, modelo, inicial, primaNeta, primaTotal, codigoPostal, aseguradora, vendedores, id, version, marca, submarca, id_cotizacion_seguro) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const userAgent = window.navigator.userAgent;
    const vendedorSelect = vendedores.find(vendedor => vendedor.id === parseInt(data.vendedor) );
    const { email } = vendedorSelect;
    //console.log(data);
    const dataRequest = {
        id: id,
        paso: 2,
        vendedorLote: email,
        primerNombre: data.primerNombre,
        segundoNombre: data.segundoNombre,
        apellidoPaterno: data.apellidoPaterno,
        apellidoMaterno: data.apellidoMaterno,
        RFC: data.rfc+data.homoclave,
        fechaDeNacimiento: formatDate(data.fechaNacimiento),
        CURP: data.curp,
        genero: data.genero,
        nacionalidad: data.nacionalidad,
        tipoDeIdentificacion: data.tipoIdentificacion,
        id_aseguradora: idAseguradora,
        regimenFiscal: data.regimenFiscal,
        MODELO: modelo,
        idDescripcion: idDescripcion,
        IdCotizacionSeguro: idCotizacionSeguro,
        ocupacion: data.ocupacion,
        proveedor: proveedor,
        distribuidor: distribuidor,
        sucursal: sucursal,
        marca: marca,
        submarca: submarca,
        Version: version,
        id_cotizacion_seguro: id_cotizacion_seguro,
        idFase: 10,

    }
    //console.log(dataRequest);
    const request = {
        vendedorLote: email,
        primerNombre: data.primerNombre,
        segundoNombre: data.segundoNombre,
        apellidoPaterno: data.apellidoPaterno,
        apellidoMaterno: data.apellidoMaterno,
        RFC: data.rfc,
        fechaDeNacimiento: formatDate(data.fechaNacimiento),
        CURP: data.curp,
        genero: parseInt(data.genero),
        nacionalidad: parseInt(data.nacionalidad),
        tipoDeIdentificacion: parseInt(data.tipoIdentificacion),
        id_aseguradora: idAseguradora,
        regimenFiscal: parseInt(data.regimenFiscal),
        MODELO: modelo,
        codigo_postal: codigoPostal,
        idDescripcion: idDescripcion,
        ocupacion: parseInt(data.ocupacion),
        proveedor: proveedor,
        distribuidor: distribuidor,
        sucursal: sucursal,
        IdCotizacionSeguro: idCotizacionSeguro,
        //IdCotizacionSeguro: null,
        PrimaTotal: primaTotal,
        PrimaNeta: primaNeta,
        Inicial: inicial,
        Aseguradora: aseguradora,
        idFase: 10,
        marca: marca,
        submarca: submarca,
        Version: version,
        id_cotizacion_seguro: id_cotizacion_seguro
    }
    //primaNeta, primaTotal, codigoPosta
    //console.log(request);
    if(id > 0 ) {
        request.id = id;
        return SolicitudServices.updateInsurance(request, llaveSeguridad).then(
            (data) => {
                if(isNaN(data)) {
                    if(data.codigo === 0) {
                        dispatch({
                            type: type.addDataSolicitudSeguro,
                            payload: dataRequest
                        });
                        //dispatch(resetCotizadorSeguro());
                    } else {
                        dispatch({
                            type: type.addDataSolicitudSeguroFail
                        });
                        dispatch({
                            type: type.setError,
                            payload: {
                                tipoError: data.codigo,
                                mensajeError: data.message
                            }
                        });
                    }
                } else {
                    let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                    if(data !== 200) {
                        errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                    }
                    dispatch({
                        type: type.addDataSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: 3,
                            mensajeError: errorMensaje
                        }
                    });
                }
                dispatch({type: type.setLoading});
                UpdateSecurityKey(data.llaveSeguridad);
    
                return Promise.resolve(data);
            },
            (error) => {
                const message = (error.response && error.response.data && error.response.data.message) ||
                error.message || error.toString();
                dispatch({type: type.setLoading});
                return Promise.reject(error);
            }
        );

    } else {
        console.log(request);
        return SolicitudServices.createInsurance(request, llaveSeguridad).then(
            (data) => {
                if(isNaN(data)) {
                    if(data.codigo === 0) {
                        dataRequest.id = data.id;
                        console.log(dataRequest);
                        dispatch({
                            type: type.addDataSolicitudSeguro,
                            payload: dataRequest
                        });
                        //dispatch(resetCotizadorSeguro());
                    } else {
                        dispatch({
                            type: type.addDataSolicitudSeguroFail
                        });
                        dispatch({
                            type: type.setError,
                            payload: {
                                tipoError: data.codigo,
                                mensajeError: data.message
                            }
                        });
                    }
                } else {
                    let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                    if(data !== 200) {
                        errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                    }
                    dispatch({
                        type: type.addDataSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: 3,
                            mensajeError: errorMensaje
                        }
                    });
                }
                dispatch({type: type.setLoading});
                UpdateSecurityKey(data.llaveSeguridad);
    
                return Promise.resolve(data);
            },
            (error) => {
                const message = (error.response && error.response.data && error.response.data.message) ||
                error.message || error.toString();
                dispatch({type: type.setLoading});
                return Promise.reject(error);
            }
        );

    }
    
    //const dataRequest  = {}
    /*dispatch({
        type: type.addDataSolicitudSeguro,
        payload: dataRequest
    });
    dispatch(resetCotizadorSeguro());*/

};

export const solicitudPaso2 = (data, usuario, llaveSeguridad, idSolicitud) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const { calle, noExterior, noInterior, codigoPostal, existeColonia, colonia, anadeColonia, alcaldia, estado} = data;
    const dataRequest = {
        paso: 3,
        usuario: usuario,
        llaveSeguridad: llaveSeguridad,
        calle: data.calle,
        numeroExterior: data.noExterior,
        numeroInterior: data.noInterior,
        CP: data.codigoPostal,
        colonia: colonia,
        coloniaStr: !data.existeColonia ? data.colonia : data.anadeColonia,
        municipio: data.alcaldia,
        estado: data.estado
    }

    const request = {
        id: idSolicitud,
        calle: calle,
        numeroExterior: noExterior,
        numeroInterior: noInterior === "" ? null : noInterior,
        CP: codigoPostal,
        colonia: colonia,
        municipio: alcaldia,
        estado: estado,
        idFase: 11
    }

    return SolicitudServices.updateInsurance(request, llaveSeguridad).then(
        (data) => {
            if(isNaN(data)) {
                if(data.codigo === 0) {
                    dispatch({
                        type: type.addDataSolicitudSeguro,
                        payload: dataRequest
                    });
                    //dispatch(resetCotizadorSeguro());
                } else {
                    dispatch({
                        type: type.addDataSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.message
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.addDataSolicitudSeguroFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({type: type.setLoading});
            UpdateSecurityKey(data.llaveSeguridad);

            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            return Promise.reject(error);
        }
    );
    
    /*dispatch({
        type: type.addDataSolicitudSeguro,
        payload: dataRequest
    });*/
}


export const solicitudPaso3 = (data, usuario, llaveSeguridad, idSolicitud) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const { numeroCelular, telefonoFijo, correoElectronico } = data;
    const dataRequest = {
        paso: 4,
        usuario: usuario,
        llaveSeguridad: llaveSeguridad,
        telefonoCelular: data.numeroCelular,
        telefonoFijo: data.telefonoFijo,
        correo: data.correoElectronico
    }

    const request = {
        id: idSolicitud,
        telefonoCelular: numeroCelular,
        telefonoFijo: telefonoFijo !== "" ? telefonoFijo : null,
        correo: correoElectronico,
        idFase: 12
    }
    
    return SolicitudServices.updateInsurance(request, llaveSeguridad).then(
        (data) => {
            if(isNaN(data)) {
                if(data.codigo === 0) {
                    dispatch({
                        type: type.addDataSolicitudSeguro,
                        payload: dataRequest
                    });
                    //dispatch(resetCotizadorSeguro());
                } else {
                    dispatch({
                        type: type.addDataSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.message
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.addDataSolicitudSeguroFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({type: type.setLoading});
            UpdateSecurityKey(data.llaveSeguridad);

            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            return Promise.reject(error);
        }
    );
    /*dispatch({
        type: type.addDataSolicitudSeguro,
        payload: dataRequest
    });*/
}

export const solicitudPaso4 = (data, usuario, llaveSeguridad, idSolicitud) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const { numeroSerierVIN, numeroMotor, placas, color, tipoCarga} = data;
    const dataRequest = {
        paso: 5,
        usuario: usuario,
        VIN: data.numeroSerierVIN,
        numeroDeMotor: data.numeroMotor,
        placas: data.placas,
        color:parseInt(data.color),
        tipoCarga: data.tipoCarga
    }
    const request = {
        id: idSolicitud,
        VIN: numeroSerierVIN,
        numeroDeMotor: numeroMotor !== "" ? numeroMotor : null,
        placas: placas !== "" ? placas : null,
        color: parseInt(color),
        tipoDeCarga: tipoCarga,
        idFase: 13
    }
    
    return SolicitudServices.updateInsurance(request, llaveSeguridad).then(
        (data) => {
            if(isNaN(data)) {
                if(data.codigo === 0) {
                    dataRequest.list_documents = data.list_documents;
                    dispatch({
                        type: type.addDataSolicitudSeguro,
                        payload: dataRequest
                    });
                    //dispatch(resetCotizadorSeguro());
                } else {
                    dispatch({
                        type: type.addDataSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.message
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.addDataSolicitudSeguroFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({type: type.setLoading});
            UpdateSecurityKey(data.llaveSeguridad);

            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            return Promise.reject(error);
        }
    );
    /*dispatch({
        type: type.addDataSolicitudSeguro,
        payload: dataRequest
    });*/
}

export const solicitudPaso5 = (usuario, llaveSeguridad, idSolicitud, idFase) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    
    const dataRequest = {
        id: idSolicitud
    }
    const request = {
        id: idSolicitud
    }

    dispatch({
        type: type.addDataSolicitudSeguro,
        payload: dataRequest
    });

    /*return SolicitudServices.updateInsurance(request, llaveSeguridad).then(
        (data) => {
            if(isNaN(data)) {
                if(data.codigo === 0) {
                    dispatch({
                        type: type.addDataSolicitudSeguro,
                        payload: dataRequest
                    });
                    //dispatch(resetCotizadorSeguro());
                } else {
                    dispatch({
                        type: type.addDataSolicitudSeguroFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            tipoError: data.codigo,
                            mensajeError: data.message
                        }
                    });
                }
            } else {
                let errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                if(data !== 200) {
                    errorMensaje = MensajesAlertas.errorConexion.textoCustom;
                }
                dispatch({
                    type: type.addDataSolicitudSeguroFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: 3,
                        mensajeError: errorMensaje
                    }
                });
            }
            dispatch({type: type.setLoading});
            UpdateSecurityKey(data.llaveSeguridad);

            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            return Promise.reject(error);
        }
    );*/
}

export const regresarPasoSolicitudSeguro = (paso) => (dispatch) => {

    const data = {
        paso: paso
    }
    dispatch({
        type: type.addDataSolicitudSeguro,
        payload: data
    });
};

export const resetFormSolicitud = () => (dispatch) => {
    dispatch({
        type: type.resetSolicitudSeguro
    });
};

export const retomarSolicitud = (data) => (dispatch) => {
    const { idFase } = data;
    let paso = 0;
    if(parseInt(idFase) === 10){
        paso = 1;
    } else if(parseInt(idFase) === 11) {
        paso = 2;
    } else if(parseInt(idFase) === 12) {
        paso = 3;
    } else if(parseInt(idFase) === 13) {
        paso = 4;
    } else if(parseInt(idFase) === 20 || parseInt(idFase) === 21 || parseInt(idFase) === 110 || parseInt(idFase) === 111 ) {
        paso = 5;
    } 
    data.paso = paso;
    
    dispatch({
        type: type.addDataSolicitudSeguro,
        payload: data
    });
}

