import { DataAssignedCommercial } from "../models/DataAssignedCommercial";
import { type as types } from "../types/index";

const initialState = {isAssigned: false, dataAssignedComer: DataAssignedCommercial};

export const  AssignedCommercialReducer= (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.getAssignedCommercial:
            const result = {
                ...state, 
                isAssigned: true,
                dataAssignedComer: payload
            };
            return result;
        case types.getAssignedCommercialFail:
            return {
                ...state,
                isAssigned: false,
                dataAssignedComer: null
            }
        default:
            return state;
    }
}
