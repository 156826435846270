import { type } from "../types/index";

/*export const updateSucursalSelection = (sucursal) =>({
    type: type.updateSucursalSelection,
    payload: sucursal
}
);*/

export const updateSucursalSelection = (sucursal) => (dispatch) => {
    sessionStorage.setItem("sucursalSeleccionada", JSON.stringify(sucursal));
    dispatch({
        type: type.updateSucursalSelection,
        payload: sucursal
    });
}


