import { type as types } from "../types/index";

const initialState = {show: false, 
    data: { title: "", subtitle: "", textoCustom: "", textButtonPrimary: "", textButtonSecond: "", 
    buttonPrimary: { 
        texto: "",
        onClick: () => { }
    },
    buttonSecond: {
        texto: "",
        onClick: () => { }
    }
}};

export const AlertMessageReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.showAlertMessage:
            const result = {
                ...state, 
                show: true,
                data: payload
            };
            return result;

        case types.hideAlertMessage:
            return {
                ...state, 
                show: false,
                data: initialState
            };
        default:
            return state;
    }
}
