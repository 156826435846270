import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FormDatosClientesSeguro from './FormDatosClientesSeguro';
import FormDocumentosSeguro from './FormDocumentosSeguro';
import FormDatosDomicilioSeguro from './FormDatosDomicilioSeguro';
import FormContactoSeguro from './FormContactoSeguro';
import FormDatosDelAutoSeguro from './FormDatosDelAutoSeguro';
import BarProgressStep from '../BarProgressStep';
import { getCatalogosSeguro } from '../../actions/seguros/getCatalogosSeguro';
import { getSecurityKey } from '../../helpers/SecurityKey';
import { resetRFC } from '../../actions/rfc';

const FormSolicitudSeguro = () => {

  const dispatch = useDispatch();
  const securityKey = getSecurityKey();

  const obtencionSolicitudSeguro = useSelector((state) => state.solicitudSeguro);
  //console.log(obtencionSolicitudSeguro);
  const { paso, totalPasos } = obtencionSolicitudSeguro;
  const dataUser = useSelector((state) => state.auth);
  const { user } = dataUser;

  const obtencionCatalogosSeguro = useSelector((state) => state.catalogosSeguro);
  useEffect(() => {
    dispatch(getCatalogosSeguro(user.correo, securityKey));
    dispatch(resetRFC());
  }, []);
  //const paso =2;
  const FormularioMostrar = () => {
    switch (paso) {
      case 1:
        return <FormDatosClientesSeguro />;
      case 2:
        return <FormDatosDomicilioSeguro />
      case 3:
        return <FormContactoSeguro />
      case 4:
        return <FormDatosDelAutoSeguro />
      case 5:
        return <FormDocumentosSeguro />
      default:
        <></>
        //return <FormDocumentosSeguro />
    }
  }

  const headerSeccion = () => {
      return <>
          <div className='header-resultado flex-column'>
              <h2 className='texto-subtitulo w-100'>{TitlesDescripcion[(paso-1)].title}</h2>
              <p className='texto-informacion'>{TitlesDescripcion[paso-1].descripcion}</p>
          </div>
          <BarProgressStep pasoActual={paso} pasosTotal={totalPasos} />
      </>
      
  }    

  return (
      <div className='col-formularios-soliciud'>
          {headerSeccion()}
          {FormularioMostrar()}
      </div>
  )
}

export default FormSolicitudSeguro;

const TitlesDescripcion = [
    {
      title: "Información de la solicitud",
      descripcion: "Asegúrate de completar todos los campos para continuar con la solicitud del seguro."
    },
    {
      title: "Información de la solicitud",
      descripcion: "Asegúrate de completar todos los campos para continuar con la solicitud del seguro."
    },
    {
      title: "Información de la solicitud",
      descripcion: "Asegúrate de completar todos los campos para continuar con la solicitud del seguro."
    },
    {
      title: "Información de la solicitud",
      descripcion: "Asegúrate de completar todos los campos para continuar con la solicitud del seguro."
    },
    {
      title: "Documentos para seguro",
      descripcion: "Carga los documentos indicados, cuidando que todos los datos sean legibles."
    }
  ]