import React from 'react'
import { ProgressBar } from 'react-bootstrap';

const BarsProgress = ({ disableMonto= true, montoMin, montoMax }) => {
  const now = (montoMin * 100) / montoMax;
  return (
    <div className='barra-progreso-montos'>
        <ProgressBar variant="primary" now={now} />
        {
          disableMonto && <p className='label-bar'><span>Monto mínimo $50,000.00</span> <span>Monto máximo $999,999,999.00</span></p>
        }
    </div>
  )
}

export default BarsProgress