const userAgent = window.navigator.userAgent;

export const SaveSolicitud = {
	"canal": "2",
	"version": "2.0.0",
	"userAgent": userAgent,
	"carrierCelular": userAgent,
	"distribuidor": "",
	"sucursal": "",
	"id": 0,
	"nombre": "",
	"segundoNombre": "",
	"apellidoMaterno": "",
	"apellidoPaterno": "",
	"fechaNacimiento": "",
	"rfc": "",
	"intormacionAdicionalPersonal": "",
	"tipoVivienda": "",
	"telefonoEmpresa": "",
	"establecimientoFijo": "",
	"tipoComprobanteIngresos": "",
	"telefonoMovil": "",
	"telefonoFijo": "",
	"email": "",
	"circuloCredito": false,
	"terminosCondiciones": false,
	"codigoValidacion1": "",
	"codigoValidacion2": "",
	"calle": "",
	"noExterior": "",
	"noInterior": "",
	"codigoPostal": "",
	"colonia": "0",
	"municipio": "",
	"estado": "",
	"independiente": false,
	"trabajo": "",
	"nombreEmpresa": "",
	"ingresos": "",
	"antiguedadAnios": "",
	"antiguedadMeses": 0,
	"calleEmpresa": "",
	"noExteriorEmpresa": "",
	"noInteriorEmpresa": "",
	"codigoPostalEmpresa": "",
	"coloniaEmpresa": "0",
	"municipioEmpresa": "",
	"estadoEmpresa": "",
	"nombreReferencia1": "",
	"telefonoReferencia1": "",
	"nombreReferencia2": "",
	"telefonoReferencia2": "",
	"nombreReferencia3": "",
	"telefonoReferencia3": "",
	"consecutivo": "",
	"proveedor": "",
	"clave": "",
	"vendedor": "",
	"tokenFirebase": "",
	"etapaProceso": "2",
	"documentoINE": "",
	"documentoINEReverso": "",
	"documentoDomicilio": "",
	"documentoIngresos1": "",
	"documentoIngresos2": "",
	"documentoIngresos3": "",
	"idProcessPDF": "",
	"fase": "",
	"aniosDomicilio": "",
	"mesesDomicilio": "0",
	"respuestaCirculoCredito": "false",
	"fechaCreacion": "",
	"costoAuto": 0,
	"enganche": 0,
	"modelo": 0,
	"plazo": 0,
	"mensaualidad": 0,
	"marcaVehiculo": "",
	"versionVehiculo": 0,
	"tasa": "",
	"resultadoImporte": "",
	"coloniaStr": "",
	"coloniaEmpresaStr": "",
	"trabajoId": "",
	"solicitudBinarizada": "",
	"tokenService": "",
	"appianResponse": "",
	"respuestaCCTipo": "",
	"modeloCotizador": "",
	"respuestaCotizadorfinish": "",
	"idDescripcion": 0,
	"idCotizacion": 0,
	"Aseguradora": "",
	"Financiado": true,
	"MontoSeguro": 0,
	"tipoVehiculo": "",
	"compruebaIngresos": true,
	"historialCrediticio": true,
	"anioVehiculo": 0,
	"linkPDF": "",
	"latitud": "",
	"longitud": "",
	"ip_dispositivo": "",
	"estatus": "",
	"estatusDescripcion": "",
	"idEstatus": "",
	"idFase": "",
	"motivo": "",
	"Down_Payment": "",
	"Monthly_Amount": "",
	"Insurance": "",
	"Insurance_Amount": "",
	"Term": "",
	"perfil": "",
	"usuario": "",
	"seller": ""
}