import { type as types } from "../../types";
import { dataCotizadorSeguro } from "../../models/DataCotizadorSeguro";

const initialState = dataCotizadorSeguro;

export const CotizadorSeguroReducer = (state = initialState, action) => {

    const {type, payload } = action;

    switch (type) {
        
        case types.addDataCotizadorSeguro:
            return {
                ...state, 
                ...payload
            };
        case types.resetCotizadorSeguro:
            return {
                ...initialState
            }
        default:
            return state;
    }
}