import { type as types } from "../types";

const initialState = {
    paso: 1,
    totalPasos: 4,
    isTradicional: false,
    cotizacion: {
      Inicial: "",
      PagoMensual: "",
      Plazo: "",
      SeguroFinanciado: true,
    },
    Auto: {
      IdDescripcion: "",
      Modelo: "",
      Marca: "",
      ModeloText: "",
      ID_MARCA: "",
    },
    IdDescripcion: "",
    CP: "",
    Modelo: "",
    montoFinanciamiento: "",
    ValorAuto: "",
    Enganche: "",
    MinEnganche: "",
    Historial: false,
    Comprobantes: false,
    seguroSelect: []
}

export const CotizadorReducer = (state = initialState, action) => {

  const {type, payload } = action;
  switch (type) {
      
      case types.addCotizador:
        const result = {
          ...state, 
          ...payload
        };
        return result;
      case types.backStepCotizador:
        return {
          ...state,
          ...payload
        }
      case types.resetCotizador:
        return initialState;

      default:
          return state;
  }

}
