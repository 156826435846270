import { type } from "../../types/index";
import { UpdateSecurityKey } from "../../helpers/SecurityKey";

import SolicitudServices from "../../services/seguros.service";

export const uploadDocument = (usuario, llaveSeguridad, id, documento, etiqueta, extension) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const request = {
        id: id,
        documento: documento,
        etiqueta: etiqueta,
        extension: extension 
    }

    return SolicitudServices.uploadDocument(usuario, llaveSeguridad, request).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.codigo === 0) {

                UpdateSecurityKey(data.llaveSeguridad);
                
            } else {
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.message
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            /*dispatch({
                type: type.getPostCotizaFail
            });*/
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    );
}