import { type } from "../types/index";
import PostCotizaService from "../services/post.cotiza.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { cotizadorPaso3 } from "./cotizador";
import { updateDataSolicitud } from "./solicitudes";

export const postCotiza = (CveDistribuidor, Clave, Inicial, IdDescripcion, CP, Modelo, usuario, llaveSeguridad, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes, seguroSeleccionado) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    dispatch({type: type.resetPostCotiza});
    return PostCotizaService.getPostCotiza(CveDistribuidor, Clave, Inicial, IdDescripcion, CP, Modelo, usuario, llaveSeguridad, IdCotizacionSeguro, ValorAuto, Enganche, Historial, parseInt(Comprobantes), seguroSeleccionado).then(
        (data) => {
            dispatch({type: type.setLoading});
            if(data.Codigo === 0) {
                if(data.Cotizaciones.length > 0) {
                    dispatch({
                        type: type.getPostCotiza,
                        payload: data
                    });
                    UpdateSecurityKey(data.llaveSeguridad);
                    let Aseguradora = "", isTradicional = true;
                    if(IdCotizacionSeguro !== null) {
                        Aseguradora = seguroSeleccionado.Aseguradora;
                        isTradicional = false;
                    }
                    const dataSolicitud = {
                        sucursal: Clave,
                        clave: Clave,
                        distribuidor: CveDistribuidor,
                        idCotizacion: IdCotizacionSeguro,
                        Aseguradora: Aseguradora,
                        engancheInicial: Enganche
                    }
                    
                    dispatch(updateDataSolicitud(dataSolicitud));
                    dispatch(cotizadorPaso3(Inicial, seguroSeleccionado, isTradicional));
                } else {
                    dispatch({
                        type: type.getPostCotizaFail
                    });
                    dispatch({
                        type: type.setError,
                        payload: {
                            seccion: "planesPago",
                            tipoError: 2,
                            mensajeError: "No hay plazos para esta cotizacion"
                        }
                    });
                }
                
            } else {
                dispatch({
                    type: type.getPostCotizaFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.Codigo,
                        mensajeError: data.Mensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getPostCotizaFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
