import React, { useState } from 'react'
import { Alert, Card, Col, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sendDoctoAdicional, sendDoctoCIKD } from '../actions/sendDoctoAdicional';
import { SIZE_IMAGE } from '../helpers/constants';
//import { FileUploader } from "react-drag-drop-files";
import { etiquetaDoc, extensionArchivo, FormatoCantidad, readFile } from '../helpers/functionGlobal';
import { getSecurityKey } from '../helpers/SecurityKey';
import FileUpload from './FileUpload';

const DocumentacionSolicitud = () => {

  const [alerShow, setAlertShow] = useState(true);
  const [dragActive, setDragActive] = React.useState(false);

  const dispatch = useDispatch();

  const securityKey = getSecurityKey();

  const dataUser = useSelector((state) => state.auth);
  const { user } = dataUser;
  const obtencionSolicitud = useSelector((state) => state.solicitudes);
  const { consecutivo, id, } = obtencionSolicitud.dataSolicitud;
  //console.log(obtencionSolicitud);

  const handleChange = async (file, nameInput) => {
    let archivo = await readFile(file);
    const { name, type } = file;
    let etiquetaDocumento = etiquetaDoc(nameInput), tipoArchivo = extensionArchivo(type);
    dispatch(sendDoctoAdicional(user.correo, securityKey, consecutivo, tipoArchivo, etiquetaDocumento, archivo, 0, id));
  }

  const handleChangeComprobante = async (files, nameInput) => {
    let etiquetaDocumento = "DOCADKD";
    let data = [];
    for( let i =0; i < files.length; i++ ) {
      let documento = await readFile(files[0]);
      let dataFile = {
        extensionDocumento: extensionArchivo(files[i].type),
        data: documento
      }
      data.push(dataFile);
    }
    dispatch(sendDoctoCIKD(user.correo, securityKey, consecutivo, id, 0, data, etiquetaDocumento));
  }
  
  const fileTypes = ["JPEG", "PNG", "PDF", "JPG"];
 
  return (
    <div className='seccion-documentacion'>
      <Alert show={alerShow} className='alert-info-primary' onClose={() => setAlertShow(false)}  dismissible>
          <p className='mb-0 pe-3'>Aquí subes los documentos de la solicitud. Cada apartado se habilitará según la etapa del proceso.</p>
      </Alert>
      <Card className='card-formulario'>
        <Card.Body className='px-0'>

          <Card.Title className='my-3 d-flex align-items-center'>
            <i className='icon-documentos me-3'></i>Identificación Oficial
          </Card.Title>
          <Row>
            <Col xs={6} >
              <p className='label-input-file'>Identificación frente</p>
              <FileUpload
                name="frenteINE"
                fileTypes={fileTypes}
                textoTipoDoc="Carga frente de identificación"
                textoFormatos="Elige formatos .jpg, .pdf o .png"
                textoLinkCarga="Cargar archivo"
                textoLinkReemplaza="Reemplazar"
                handleChange={handleChange}
              />
              

            </Col>
            <Col xs={6}>
              <p className='label-input-file'>Identificación reverso</p>
              <FileUpload
                name="reversoIne"
                fileTypes={fileTypes}
                textoTipoDoc="Carga reverso de identificación"
                textoFormatos="Elige formatos .jpg, .pdf o .png"
                textoLinkCarga="Cargar archivo"
                textoLinkReemplaza="Reemplazar"
                handleChange={handleChange}
              />
            
            </Col>
          </Row>

          <hr className='linea-separacion' />
          <Card.Title className='my-3 d-flex align-items-center'>
            <i className='icon-documentos me-3'></i>Comprobante de domicilio
          </Card.Title>

          <Row>
            <Col xs={6}>
              <p className='label-input-file'>Comprobante de domicilio</p>
              <FileUpload
                name="comprobanteDomicilio"
                fileTypes={fileTypes}
                textoTipoDoc="Carga el comprobante de domicilio"
                textoFormatos="Elige formatos .jpg, .pdf o .png"
                textoLinkCarga="Cargar archivo"
                textoLinkReemplaza="Reemplazar"
                handleChange={handleChange}
              />
            </Col>
          </Row>

          <hr className='linea-separacion' />
          <Card.Title className='my-3 d-flex align-items-center'>
            <i className='icon-documentos me-3'></i>Comprobante de ingresos
          </Card.Title>

          <Row>
            <Col xs={6}>
              <p className='label-input-file'>Comprobante de ingresos (Puedes seleccionar hasta 10 archivos ó 10 megas)</p>
              <FileUpload
                name="comprobanteIngresos"
                fileTypes={fileTypes}
                multiple={true}
                maxSize={SIZE_IMAGE}
                textoTipoDoc="Captura los documentos"
                textoFormatos="Elige formatos .jpg, .pdf o .png"
                textoLinkCarga="Cargar archivo"
                textoLinkReemplaza="Reemplazar"
                handleChange={handleChangeComprobante}
              />
            </Col>
          </Row>

          <hr className='linea-separacion' />
          <Card.Title className='my-3 d-flex align-items-center'>
            <i className='icon-documentos me-3'></i>Documentos adicionales
          </Card.Title>

          <Row>
            <Col xs={6}>
              <p className='label-input-file'>Documentos adicionales</p>
              <FileUpload
                name="documentosAdicionales"
                fileTypes={fileTypes}
                textoTipoDoc="Captura los documentos"
                textoFormatos="Elige formatos .jpg, .pdf o .png"
                textoLinkCarga="Cargar archivo"
                textoLinkReemplaza="Reemplazar"
                handleChange={handleChange}
              />
            </Col>
          </Row>


        </Card.Body>
      </Card>
    </div>
    
  )
}

export default DocumentacionSolicitud