import {axiosInstance} from "../helpers/axios";

const userAgent = window.navigator.userAgent;

const getPostCotiza = (CveDistribuidor, Clave, Inicial, IdDescripcion, CP, Modelo, usuario, llaveSeguridad, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes) => {
    return axiosInstance.post('postCotiza/', {
        "CveDistribuidor": CveDistribuidor,
        "Clave": Clave,
        "userAgent": userAgent,
        "carrierCelular": userAgent,
        "Cotizacion": {
            "Inicial": Inicial,
            "PagoMensual": "",
            "Plazo": "",
            "SeguroFinanciado": ""
        },
        "Auto": {
            "IdDescripcion": "",
            "Modelo": ""
        },
        "IdDescripcion": IdDescripcion,
        "CP": CP,
        "Modelo": Modelo,
        "usuario": usuario,
        "canal": "3",
        "version": "2.0.0",
        "llaveSeguridad": llaveSeguridad,
        "IdCotizacionSeguro": IdCotizacionSeguro,
        "ValorAuto": ValorAuto,
        "Plazo": "",
        "Enganche": Enganche,
        "Historial": Historial,
        "Comprobantes": Comprobantes
    }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        console.log("error");
        console.log(err.message);
    });
}

export default { getPostCotiza };