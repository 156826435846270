import {axiosInstance} from "../helpers/axios";

const getMarcas = (user, securitykey) => {
  return axiosInstance
    .post("getMarcasv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      llaveSeguridad: securitykey,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err;
    });
};

const getMarcasSeguros = (user, securitykey) => {
  return axiosInstance
    .post("getMarcasv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      llaveSeguridad: securitykey,
      modelo: 2
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      return err;
    });
};

export default { getMarcas, getMarcasSeguros };
