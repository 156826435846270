import React, { useEffect, useState }  from 'react';
import { Card, Col, Container, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import DetalleDelPlan from '../components/DetalleDelPlan';
import DetalleSolicitud from '../components/DetalleSolicitud';
import DocumentacionSolicitud from '../components/DocumentacionSolicitud';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import ModalErrores from '../components/ModalErrores';
import { getAssignedCommercial } from '../actions/assignedCommercial';
import { getSecurityKey } from '../helpers/SecurityKey';
import { EstatusSolicitudes } from '../models/EstatusSolicitudes';


const DetalleSolicitudScreen = () => {
    const [vistaMostrar, setVistaMostrar] = useState(1);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const securityKey = getSecurityKey();
    
    const loading = useSelector((state) => state.loading);
    const obtencionError = useSelector((state) => state.mensajeError);
    const { isError } = obtencionError;
    const { tipoError, mensajeError } = obtencionError.dataError;

    const obtencionSolicitud = useSelector((state) => state.solicitudes);
    const { nombre, segundoNombre, apellidoMaterno, apellidoPaterno, 
        fechaCreacion, consecutivo, analista, idFase, idEstatus, estatus, estatusDescripcion
    } = obtencionSolicitud.dataSolicitud;

    const dataUser = useSelector((state) => state.auth);
    const dataAssignedComer = useSelector((state) => state.assignedCommercial);
    const { assigned, email, full_name, url_whatsapp} = dataAssignedComer.dataAssignedComer;

    const mostrarSolicidutes = (e) => {
        setVistaMostrar(e);
    }

    useEffect(() => {
        dispatch(getAssignedCommercial(dataUser.user.correo, consecutivo, securityKey));
    }, [])
    
    const claseStatus = () => {
        const estatusSelect = EstatusSolicitudes.find(element => element.value === estatus);
        let claseEstatus = estatusSelect ? estatusSelect.clase : "caso-alterno";
        return <Card.Subtitle className={claseEstatus}>{estatusDescripcion}</Card.Subtitle>;
    }

    return (
        <section className='seccion-detalle-solicitud'>
            <Container>
                <Row>
                    <Col xs={4} className='col-menu-lateral'>
                        <div className='link-inicio'>
                            <Nav.Link className="nav-link" onClick={() => navigate("/home")}><i className='icon-arrow-left me-2'></i>Inicio</Nav.Link>
                        </div>
                        <h1 className='texto-titulo'>Créditos</h1>
                        <ul className='opcion-menu'>

                            <li className={`${vistaMostrar === 1 && "list-active"}`} >
                                <i className='icon-historial'></i>
                                <span className="nav-link" onClick={() => mostrarSolicidutes(1) } >Historial</span>
                                <span className='circle'></span>
                            </li>
                            {
                                (idFase === null || idFase === "" || idFase === "0") &&
                                <li className={`${vistaMostrar === 2 && "list-active"}`} >
                                    <i className='icon-documentacion'></i>
                                    <span className="nav-link" onClick={() => mostrarSolicidutes(2) } >Documentación</span>
                                    <span className='circle'></span>
                                </li>
                            }
                            <li className={`${vistaMostrar === 3 && "list-active"}`} >
                                <i className='icon-zoom-area'></i>
                                <span className="nav-link" onClick={() => mostrarSolicidutes(3) } >Detalle del plan</span>
                                <span className='circle'></span>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={8} className="col-contenido" >
                        <div className='text-end mt-3'>
                            <img src={require('../assets/images/logos/atria-color.png')} alt="Atria" className='logo-atria-color'  />
                        </div>
                        <Card className='card-datos-solicitud'>
                            <Card.Body className='px-0'>
                                <Card.Title>{`${nombre} ${segundoNombre} ${apellidoPaterno} ${apellidoMaterno}`}</Card.Title>
                                {/*<Card.Subtitle className={claseStatus}>{estatusDescripcion}</Card.Subtitle>*/}
                                {(claseStatus())}
                                <Row className='mt-3'>
                                    <Col xs={6}>
                                        <p className='m-0'>Solicitud creada el {format(new Date(fechaCreacion), 'dd/MM/yyyy')}</p>
                                        <p className='m-0'>Consecutivo: {consecutivo}</p>
                                    </Col>
                                    {
                                        assigned ?
                                        <>
                                            <Col xs={4}>
                                                <p className='tipo-cargo m-0'>Analista a cargo</p>
                                                <p className='m-0'>{full_name}</p>
                                            </Col>
                                            <Col xs={2}>
                                                <Nav.Link href={url_whatsapp} target="_blank"><i className='icon-whatsapp me-3'></i></Nav.Link>
                                                {/*<i className='icon-messenge'></i>*/}
                                            </Col>
                                        </>
                                        : ""
                                    }
                                </Row>
                            </Card.Body>
                        </Card>

                        {vistaMostrar === 1 && <DetalleSolicitud />}
                        {vistaMostrar === 2 && <DocumentacionSolicitud />}
                        {vistaMostrar === 3 && <DetalleDelPlan />}

                        <Footer />
                    </Col>
                </Row>
                <Loading show={loading} />
                <ModalErrores show={isError} tipoError={tipoError} mensajeError={mensajeError} />
            </Container>
        </section>
    )
}

export default DetalleSolicitudScreen