import { type as types } from "../types/index";

const initialState = {isAnio: false, anios: null};

export const AniosReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.getAnios:
            const result = {
                ...state, 
                isAnio: true,
                anios: payload
            };
            return result;
        case types.getAniosFail:
            return {
                ...state,
                isAnio: false,
                anios: null
            }
        case types.resetAnios:
            return initialState;
        default:
            return state;
    }
}
