import { type } from "../types/index";
import SendDoctoAdicionalService from "../services/send.docto.adicional.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const sendDoctoAdicional = (usuario, securitykey, consecutivo, extensionDocumento, etiquetaDocumento, documentoAdicional, idHistorialSolicitud, idSolicitud) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const userAgent = window.navigator.userAgent;

    const docto = {
        consecutivo: consecutivo,
        extensionDocumento: extensionDocumento,
        etiquetaDocumento: etiquetaDocumento,
        documentoAdicional: documentoAdicional
    }

    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        consecutivo: consecutivo,
        docto: docto,
        idSolicitud: idSolicitud,
        idHistorialSolicitud: idHistorialSolicitud,
        llaveSeguridad: securitykey
    }

    return SendDoctoAdicionalService.sendDoctoAdicional(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { detail, llaveSeguridad } = data;
            if(detail === "OK") {
                dispatch({
                    type: type.sendDoctoAdicional,
                    payload: data
                });
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.sendDoctoAdicionalFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.detail,
                        mensajeError: data.message
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.sendDoctoAdicionalFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}


export const sendDoctoCIKD = (usuario, securitykey, consecutivo, idSolicitud, idHistorialSolicitud, dataFiles, etiquetaDocumento) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const userAgent = window.navigator.userAgent;

    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        consecutivo: consecutivo,
        etiquetaDocumento: etiquetaDocumento,
        usuario_que_envia: usuario,
        documentoAdicional: dataFiles,
        llaveSeguridad: securitykey
    }
    
    return SendDoctoAdicionalService.sendPurchaseDocumentsCIKD(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { detail, llaveSeguridad } = data;
            if(detail === "OK") {
                dispatch({
                    type: type.sendDoctoAdicional,
                    payload: data
                });
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.sendDoctoAdicionalFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.detail,
                        mensajeError: data.message
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.sendDoctoAdicionalFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}
