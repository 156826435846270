import { DataUpdatePartialSolicitud } from "../models/DataUpdatePartialSolicitud";
import { type as types } from "../types/index";

const initialState = {isSave: false, dataUpdatePartial: DataUpdatePartialSolicitud};

export const UpdatePartialSolicitudReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.updatePartialSolicitud:
            const result = {
                ...state, 
                isSave: true,
                dataUpdatePartial: payload
            };
            //console.log(result);
            return result;
        case types.updatePartialSolicitudFail:
            return {
                ...state,
                isSave: false,
                dataUpdatePartial: initialState
            }
        default:
            return state;
    }
}
