import React from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const ModalAvisoPrivacidad = ({ show, onHide, onClick }) => {
  return (
    <Modal
        show = {show}
        onExit={onHide}
        centered
        className='modal-alerta'
    >
        <Modal.Header closeButton onClick={onHide}></Modal.Header>
        <Modal.Body>
            <h2 className='texto-subtitulo'>Autorización para solicitar Reportes de Crédito</h2>
            <p className='texto-informacion text-start'>
                Autorizo expresamente a ATRIALA S.A.P.I. DE C.V. para que lleve a cabo investigaciones sobre mi comportamiento crediticio en las Sociedades de Información Crediticia (SIC) que estime conveniente.
                Conozco la naturaleza y alcance de la información que se solicitará, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio. Consiento que esta autorización tenga una vigencia de tres años contados a partir de hoy, y en su caso mientras mantengamos relación jurídica. Acepto que este documento quede bajo propiedad de ATRIALA S.A.P.I. DE C.V. para efectos de control y cumplimiento del artículo 28 de la LRSIC. 
            </p>
            <Row className='justify-content-center'>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-light w-100" onClick={onHide} >Cancelar</Button>
                </Form.Group>
                <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                    <Button type="button" className="btn btn-primary w-100" onClick={onClick} >Acepto</Button>
                </Form.Group>
            </Row>
        </Modal.Body>
    </Modal>
  )
}

export default ModalAvisoPrivacidad