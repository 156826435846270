import { Col, Form } from "react-bootstrap";
import { SIZE_IMAGE } from "../helpers/constants";

export const MensajesAlertas = {
    errorConexion: {
        title: "Alerta",
        subtitle: "Uy, Algo pasó aquí",
        textoCustom: (
            <>
                Hubo un error en el servicio.<br />
                Por favor, revista tu conexión a internet
                e inténtalo de nuevo.
            </>
        ),
        textButtonPrimary: "",
        textButtonSecond: ""
    },
    mensajeDocumento: {
        title: "Alerta",
        subtitle: "¿Estás seguro de continuar sin comprobar ingresos y sin historial crediticio?",
        textoCustom: (
            <>
                Si está seguro presione el botón de Siguiente.
            </>
        ),
        textButtonPrimary: "Siguiente",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Siguiente",
            onClick: () => {
                
            }
        }
    },

    mensajeCancelacionCotizacion: {
        title: "Alerta",
        subtitle: "¿Estás seguro que deseas cancelar la cotización? ",
        textoCustom: (
            <>
                Si cancelas no podremos guardar la información que ingresaste hasta ahora.
            </>
        ),
        textButtonPrimary: "Siguiente",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Aceptar",
            onClick: () => {
                
            }
        },
        buttonSecond: {
            texto: "Cancelar",
            onClick: () => {
                
            }
        }
    },

    mensajeCancelacionSolicitud: {
        title: "Alerta",
        subtitle: "¿Estás seguro que deseas cancelar la solicitud? ",
        textoCustom: (
            <>
                Si cancelas no podremos guardar la información que ingresaste hasta ahora.
            </>
        ),
        textButtonPrimary: "Siguiente",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "No, regresar",
            onClick: () => {
                
            }
        },
        buttonSecond: {
            texto: "Si, cancelar",
            onClick: () => {
                
            }
        }
    },

    mensajeDocumentosMano: {
        title: "Alerta",
        subtitle: "Antes de comenzar...",
        textoCustom: (
            <div className="modal-documentos-mano">
                <p className="texto-descripcion">Te recomendamos tener a la mano estos documentos de tu cliente:</p>
                <ul className="lista-documentos">
                    <li><i className="icon-identificacion"></i><span>INE/IFE, Pasaporte Mexicano, FM2/FM3 o Cédula Profesional (no mayor a 10 años)</span></li>
                    <li><i className="icon-comprobante-domicilio"></i><span>Comprobante de domicilio (no mayor a 3 meses)</span></li>
                    <li><i className="icon-contactos"></i><span>Contacto de 3 referencias</span></li>
                </ul>
                <Form.Group as={Col} xs="12">
                    <Form.Check
                        name='mostrarModalDocumentos'
                        label="No volver a mostrar"
                    />
                </Form.Group>
            </div>
        ),
        textButtonPrimary: "Siguiente",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Entendido",
            onClick: () => {
                
            }
        },
        buttonSecond: {
            texto: "Cancelar",
            onClick: () => {
                
            }
        }
    },
    mensajeVerificacion: {
        title: "Alerta",
        subtitle: "¿Prefieres verificar en otro momento?",
        textoCustom: (
            <>
            La solicitud permanecerá en "Solicitudes en proceso" y se deberá verificar en otro momento para continuar la generación de la solicitud
            </>
        ),
        textButtonPrimary: "Entendido",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "No, verificar ahora",
            onClick: () => {
                
            }
        },
        buttonSecond: {
            texto: "Sí, verificar en otro momento",
            onClick: () => {
                
            }
        }
    },
    mensajeSolicitudPreautorizada: {
        title: "Alerta",
        subtitle: "¡Solicitud Preautorizada!",
        textoCustom: (
            <>
                <div className="modal-documentos-mano">
                    <p className="texto-descripcion">Consecutivo: 6454-890000</p>
                    <p className="texto-descripcion">Para continuar con el proceso, el cliente debe compartir estos documentos:</p>
                    <ul className="lista-documentos">
                        <li><i className="icon-identificacion"></i><span>INE, pasaporte o FM2,FM3</span></li>
                        <li><i className="icon-comprobante-domicilio"></i><span>Comprobante de domicilio (no mayor a 3 meses)</span></li>
                        <li><i className="icon-comprobante-ingresos"></i><span>Comprobante de ingresos (si cuenta con uno)</span></li>
                    </ul>
                    <a href="#" className="nav-link descarga-pdf" target="_blank">Descargar PDF con el detalle de la solicitud</a>
                </div>
            </>
        ),
        textButtonPrimary: "Entendido",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Finalizar",
            onClick: () => {
                
            }
        }
    },

    mensajeNoHaySeguros: {
        title: "Alerta",
        subtitle: "No hay seguros",
        textoCustom: (
            <>
                Lo sentimos no hay contizaciones de seguro<br />
                ¿Desea continuar sin cotizar un seguro?
            </>
        ),
        textButtonPrimary: "Entendido",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Continuar Cotización",
            onClick: () => {
                
            }
        },
        buttonSecond: {
            texto: "Regresar",
            onClick: () => {
                
            }
        }
    },
    
    mensajeNoHaySolicitudesDashboard: {
        title: "Alerta",
        subtitle: "",
        textoCustom: (
            <>
                Lo sentimos no hay Solicitudes
            </>
        ),
        textButtonPrimary: "Entendido",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Entendido",
            onClick: () => {
                
            }
        }
    },
    mensajeImagePerfilMaxmLength: {
        title: "Alerta",
        subtitle: "",
        textoCustom: (
            <>
                El tamaño de la imagen debe ser de {SIZE_IMAGE} MB
            </>
        ),
        textButtonPrimary: "Entendido",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Entendido",
            onClick: () => {
                
            }
        }
    },

    mensajeSuccessSolicitudSeguro: {
        title: "Alerta",
        subtitle: "",
        textoCustom: (
            <>
                <i className='icon-alerta-success mb-3'></i>
                <p className="texto-descripcion">Has finalizado correctamente<br /> esta solicitud </p>
                <p className="texto-subtitulo text-center mb-0">Un paso más cerca de convertirte<br /> en el vendedor Atria del mes</p>  
                <i className="icon-trophy-color mt-3"></i>
            </>
        ),
        textButtonPrimary: "Regresar al inicio",
        textButtonSecond: "",
        buttonPrimary: {
            texto: "Regresar al inicio",
            onClick: () => {
                
            }
        },
        buttonHeader: {
            onClick: () => {
                
            }
        }
    }
};