import {axiosInstance2} from "../helpers/axios";

const getDashBoard = (sucursal, distribuidor, securitykey) => {
    const headers = {
        'Authorization': `Bearer ${securitykey}`
    }
    return axiosInstance2.post('dashboard/get_user_metrics/', {
        "sucursal": sucursal,
        "distribuidor": distribuidor
    }, { headers: headers }).then((res) => {
        const data = res.data;
        return data;
    }).
    catch(err => {
        /*console.log("error");
        console.log(err.message);
        console.log(err.response.status);*/
        return err.response.status;
    });
}

export default { getDashBoard };