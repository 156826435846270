import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './routes/AppRouter';

import { store } from './store/store';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJ3_AOTi-9GTXPh-CQTlQWd4KeoeKyltU",
  authDomain: "atria-15518.firebaseapp.com",
  projectId: "atria-15518",
  storageBucket: "atria-15518.appspot.com",
  messagingSenderId: "335261919897",
  appId: "1:335261919897:web:3363d77a7685771b2fe608",
  measurementId: "G-GLC6DE1Y16"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


const App = () => {

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
}

export default App