import { type as types } from "../types/index";

const initialState = {isDoctoAdicional: false, dataDoctoAdicional: null};

export const SendDoctoAdicionalReducer = (state = initialState, action) => {

    const {type, payload } = action;
    
    switch (type) {
        case types.sendDoctoAdicional:
            return {
                ...state,
                isDoctoAdicional: true,
                dataDoctoAdicional: payload
            };
        case types.sendDoctoAdicionalFail:
            return {
                ...state,
                isDoctoAdicional: false,
                dataDoctoAdicional: null
            }
        default:
            return state;
    }
}
