import {axiosInstance} from "../helpers/axios";

const getModelos = (user, id, securitykey) => {
  return axiosInstance
    .post("getTiposv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      ID: id,
      llaveSeguridad: securitykey,
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error");
      console.log(err.message);
    });
};

const getModelosSeguro = (user, id, securitykey) => {
  return axiosInstance
    .post("getTiposv2/", {
      usuario: user,
      canal: "3",
      version: "1.0",
      ID: id,
      llaveSeguridad: securitykey,
      modulo: 2
    })
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err) => {
      console.log("error");
      console.log(err.message);
    });
};

export default { getModelos, getModelosSeguro };
