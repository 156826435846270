import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Nav, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import { TYPES_FILES_SEGUROS } from '../../helpers/constants';
import { extensionArchivo, readFile } from '../../helpers/functionGlobal';
import { ErrorMessage } from '@hookform/error-message';
import { getSecurityKey } from '../../helpers/SecurityKey';
import { resetFormSolicitud, solicitudPaso5 } from '../../actions/seguros/solicitudSeguros';
import { MensajesAlertas } from '../../models/MensajesAlertas';
import { hideAlert, showAlert } from '../../actions/alertMessage';
import { useNavigate } from 'react-router-dom';
import FileUploadIcon from '../FileUploadIcon';
import { uploadDocument } from '../../actions/seguros/documentosSeguros';
import { resetCotizadorSeguro } from '../../actions/seguros/cotizadorSeguros';

const FormDocumentosSeguro = (props) => {

    const { closeModal, clearCotizador } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dataUser = useSelector((state) => state.auth);
    const { correo } = dataUser.user;
    const securityKey = getSecurityKey();

    const obtencionSolicitud = useSelector((state) => state.solicitudSeguro);
    const { list_documents, id, idFase, get_poliza } = obtencionSolicitud;
    //console.log(obtencionSolicitud);
    const [totalArchivos, setTotalArchivos] = useState(list_documents.length)
    //console.log("totalArchivos:"+totalArchivos);

    const { formState: { errors }, handleSubmit, control, setValue, clearErrors } = useForm({
        mode: "onChange",
        criteriaMode: 'all',
    });

    const onSubmit = (data) => {
        const { correo } = dataUser.user;
        envioFinal();
        /*dispatch(solicitudPaso5(correo, securityKey, id, idFase)).then((res) => {
            if(res.codigo === 0) {
                envioFinal();
            }
        });*/
    };

    const handleChange = async (file, nameInput, tipoDocumento) => {
        //console.log(tipoDocumento);
        let archivo = await readFile(file);
        //console.log(archivo);
        const { name, type } = file;
        setValue(nameInput, file);
        //clearErrors(`${nameInput}`);
        let tipoArchivo = extensionArchivo(type);
        //console.log(tipoArchivo);
        dispatch(uploadDocument(correo, securityKey, id, archivo, tipoDocumento, tipoArchivo))
        .then((res) => {
            if(res.codigo === 0) {
                const { list_documents } = res.insurance_request;
                let totalRequeridos = 0;
                for(let i = 0; i < list_documents.length; i++) {
                    if(list_documents[i].required) {
                        totalRequeridos = totalRequeridos + 1;
                    }
                }
                setTotalArchivos(totalRequeridos);
            }
            
        })
    }
    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionSolicitud, () => { closeModal(); }, () => { closeModal(); clearCotizador(); navigate("/home"); } ));
    }

    const envioFinal = () => {
        dispatch(showAlert(MensajesAlertas.mensajeSuccessSolicitudSeguro, () => { closeModal(); clearCotizador(); navigate("/home")}, undefined, undefined, () => { closeModal(); clearCotizador(); navigate("/home")}))
    }

    useEffect(() => {
        let totalRequeridos = 0;
        for(let i = 0; i < list_documents.length; i++) {
            if(list_documents[i].required) {
                totalRequeridos = totalRequeridos + 1;
            }
        }
        setTotalArchivos(totalRequeridos);
    }, [])

    const descargarPoliza = () => {
        if(get_poliza !== null && get_poliza !== "") {
            window.open(get_poliza);
        }
    }
    
    
    const listaArchivos = list_documents.map((documento, index) => {
        const { name: nombre, required: requerido, value } = documento;
        return(
            <Col xs={6} key={index} className='mb-3'>
                <p className='label-input-file'>{nombre}</p>
                <Controller 
                    name={`file-${index}`}
                    control={control}
                    rules={{ required: requerido }}
                    render= {
                        ({field:{ name }}) => (
                            <FileUploadIcon
                                name={`file-${index}`}
                                fileTypes={TYPES_FILES_SEGUROS}
                                textoFormatos={"Elige formatos .jpg, .pdf o .png"}
                                textoLinkCarga={"Cargar archivo"}
                                textoLinkReemplaza={"Sustituir"}
                                handleChange={handleChange}
                                tipoDocumento = {value}
                            />
                        )
                    }
                />
                <p className='texto-error mt-2'>
                    {requerido ? "El campo es requerido" : ""}
                    {/*<ErrorMessage errors={errors} name={`file-${index}`} message="El campo es requerido" />*/}
                </p>
                
            </Col>
        )
    })

    return (
        <div className='seccion-documentacion'>
            <Card className='card-formulario' key="card-form-principal">
                <Card.Body className='px-0'>
                    {
                        (idFase === 110 || idFase === 111) ? 
                        <p className='mb-3'>
                            <a href="#" className='texto-liga liga-poliza' onClick={descargarPoliza} >Descargar póliza</a>
                        </p> : ""
                    }
                    
                    <Form className='formulario-documentos' onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            {listaArchivos}
                        </Row>
                        <Row className='mt-5'>
                            <Col xs={6} className='px-2'>
                                <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
                            </Col>
                            <Col xs={6} className='px-2'>
                                <Button type="submit" className="btn btn-primary w-100" disabled={ (totalArchivos === 0) ? false : true } >Enviar</Button>
                            </Col>
                            
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetFormSolicitud());
            dispatch(resetCotizadorSeguro());
        } 
	};
};
export default connect(null, mapDispatchToProps)(FormDocumentosSeguro);
