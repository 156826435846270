import React, { useState }  from 'react';
import { Nav } from 'react-bootstrap';


const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(false);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    }
    return (
        <div className='col-leer-mas'>
            <p className='texto-leer-mas'>
                { isReadMore ? text.slice(0, 250)+"..." : text }
            </p>
            <Nav.Link className='liga-ver-mas' onClick={toggleReadMore}>
                {isReadMore ? "Leer más" : " Leer menos "}
            </Nav.Link>
        </div>
    )
}

export default ReadMore