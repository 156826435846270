import { type } from "../types/index";
import UpdatePartialService from "../services/update.partial.solicitud.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";
import { sendCall } from '../actions/sendCall';
import { sendSMS } from '../actions/sendSMS';
import { updateDataSolicitud } from "./solicitudes";
import { formatDate, MotoSinFormato } from '../helpers/functionGlobal';


export const updatePartialSolicitudPersonal = (data, usuario, dataSucursal, llaveSeguridad, obtencionSolicitud) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const cuentaConVehiculo = data.vehiculosCuenta,
        dependientesEconomicos = data.dependientesEconomicos,
        estadoCivil = data.estadoCivil,
        nivelEStudios = data.nivelEstudios,
        genero = data.genero,
        seller = parseInt(data.vendedor);
        

    const intormacionAdicionalPersonal = {
        cuenta_con_vehiculo: data.vehiculosCuenta,
        dependientes_economicos: data.dependientesEconomicos,
        estado_civil: data.estadoCivil,
        nivel_de_estudios: data.nivelEstudios,
        genero: data.genero
    }
    const { Aseguradora, versionVehiculo, mensaualidad, modelo, idCotizacion,
        plazo, resultadoImporte, Financiado, marcaVehiculo, tipoVehiculo, costoAuto,
        enganche, idDescripcion, id, MontoSeguro, codigoValidacion1
    } = obtencionSolicitud.dataSolicitud;

    const { Proveedor, CveDistribuidor, Vendedor, Clave } = dataSucursal.sucursalSeleccionada;

    const userAgent = window.navigator.userAgent;

    let codigoValid = codigoValidacion1;
    if(codigoValidacion1 === null || codigoValidacion1 === "") {
        codigoValid = String(Math.floor(Math.random()*900000) + 100000);
    }

    const dataRequest = {
        id: id,
        canal: "3",
        version: "1.0",
        userAgent: userAgent,
        carrierCelular: userAgent,
        nombre: data.primerNombre,
        segundoNombre: data.segundoNombre,
        apellidoPaterno: data.primerApellido,
        apellidoMaterno: data.segundoApellido,
        intormacionAdicionalPersonal: JSON.stringify(intormacionAdicionalPersonal),
        fechaNacimiento: formatDate(data.fechaNacimiento),
        rfc: data.rfc+data.homoclave,
        calle: data.calle,
        noExterior: data.noExterior,
        noInterior: data.noInterior,
        codigoPostal: data.codigoPostal,
        tipoVivienda: data.vivienda,
        colonia: data.existeColonia ? "-1" : "0",
        coloniaStr: !data.existeColonia ? data.colonia : data.anadeColonia,
        municipio: data.alcaldia,
        estado: data.estado,
        aniosDomicilio: data.aniosDomicilio,
        mesesDomicilio: data.mesesDomicilio,
        telefonoMovil: data.numeroCelular,
        telefonoFijo: data.telefonoFijo,
        email: data.correoElectronico,
        vendedor: Vendedor,
        sucursal: Clave,
        clave: Clave,
        Aseguradora: Aseguradora,
        Financiado: Financiado,
        proveedor: Proveedor,
        distribuidor: CveDistribuidor,
        versionVehiculo: versionVehiculo,
        costoAuto: costoAuto,
        enganche: enganche,
        plazo: plazo,
        idDescripcion: idDescripcion,
        mensaualidad: mensaualidad,
        modelo: modelo,
        idCotizacion: idCotizacion,
        resultadoImporte: resultadoImporte,
        telefonoEmpresa: "",
        establecimientoFijo: "",
        marcaVehiculo: marcaVehiculo,
        tipoVehiculo: tipoVehiculo,
        codigoValidacion1: codigoValid,
        usuario: usuario,
        seller: seller,

        fechaCreacion: new Date(),
        MontoSeguro: MontoSeguro,
        etapaProceso: 3,
        
        llaveSeguridad: llaveSeguridad
    }
    return UpdatePartialService.updatePartialSolicitud(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { codigo, llaveSeguridad } = data;
            if(codigo === 0) {
                dataRequest.genero = genero;
                dataRequest.paso = 2;
                //dataRequest.codigoValidacion1 = String(Math.floor(Math.random()*900000) + 100000);
                dataRequest.cuenta_con_vehiculo = cuentaConVehiculo;
                dataRequest.dependientes_economicos = dependientesEconomicos;
                dataRequest.estado_civil = estadoCivil;
                dataRequest.nivel_de_estudios = nivelEStudios;
                dispatch({
                    type: type.updatePartialSolicitud,
                    payload: dataRequest
                });
                dispatch(updateDataSolicitud(dataRequest));
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.updatePartialSolicitudFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.respuesta
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.updatePartialSolicitudFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}

export const updatePartialSolicitud = (usuario, securityKey, etapaProceso, id, distribuidor, codigoValidacion1, opcionEnvio, sucursal, claveSucursal, telefonoMovil, nombre, seller) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const userAgent = window.navigator.userAgent;
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        id: id,
        codigoValidacion1: codigoValidacion1,
        etapaProceso: etapaProceso,
        distribuidor: distribuidor,
        usuario: usuario,
        seller: seller,
        llaveSeguridad: securityKey 
    }
    
    return UpdatePartialService.updatePartialSolicitud(dataRequest).then(
        (data) => {
            const { codigo, llaveSeguridad } = data;
            if(codigo === 0) {
                dispatch({
                    type: type.updatePartialSolicitud,
                    payload: dataRequest
                });
                //dataRequest.codigoValidacion1 = null;
                dispatch(updateDataSolicitud(dataRequest));
                opcionEnvio === "1" ?
                    dispatch(sendSMS(usuario, securityKey, id, sucursal, claveSucursal, codigoValidacion1, telefonoMovil))
                : dispatch(sendCall(usuario, securityKey, telefonoMovil, nombre, codigoValidacion1));
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({type: type.setLoading});
                dispatch({
                    type: type.updatePartialSolicitudFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.respuesta
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.updatePartialSolicitudFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}


export const updatePartialLaboral = (data, dataSolicitud, usuario, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    const userAgent = window.navigator.userAgent;

    const { ocupacion, aniosAntiguedad, noExteriorLaboral, ingresoNetoMensuales, noInteriorLaboral,
        nombreEmpresa, mesesAntiguedad, trabajadorIndependiente, estadoLaboral,
        alcaldiaLaboral, codigoPostalLaboral, trabajo, calleLaboral, telefonoEmpresa,
        tipoComprobante, coloniaLaboral, anadeColonia, noColoniaLaboral, tipoEstablecimiento, 
        segundaActividad, ingresoNeto2

    } = data;
    const { codigoValidacion1, codigoValidacion2, sucursal, distribuidor, id, seller } = dataSolicitud.dataSolicitud;
    let ingresoN2 = "";
    if(ingresoNeto2 !== "" && ingresoNeto2 !== undefined) {
        ingresoN2 = MotoSinFormato(ingresoNeto2)
    }
    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        llaveSeguridad: securityKey,
        trabajoId: ocupacion,
        antiguedadAnios: aniosAntiguedad,
        codigoValidacion2: codigoValidacion1,
        codigoValidacion1: codigoValidacion1,
        noExteriorEmpresa: noExteriorLaboral,
        noInteriorEmpresa: noInteriorLaboral,
        circuloCredito: true,
        sucursal: sucursal,
        ingresos: MotoSinFormato(ingresoNetoMensuales),
        nombreEmpresa: nombreEmpresa,
        antiguedadMeses: mesesAntiguedad,    
        coloniaEmpresa: !noColoniaLaboral ? "0" : "-1",
        distribuidor: distribuidor,
        terminosCondiciones: "true",
        id: id,
        independiente: trabajadorIndependiente,
        estadoEmpresa: estadoLaboral,
        municipioEmpresa: alcaldiaLaboral,
        codigoPostalEmpresa: String(codigoPostalLaboral),
        etapaProceso: "-2",
        trabajo: trabajo,
        ip_dispositivo: "0.0.0.0",
        calleEmpresa: calleLaboral,
        telefonoEmpresa: telefonoEmpresa,
        tipoComprobanteIngresos: tipoComprobante,
        coloniaEmpresaStr: !noColoniaLaboral ? coloniaLaboral : anadeColonia,
        establecimientoFijo: tipoEstablecimiento,
        seller: seller,
        segundaActividad: segundaActividad,
        ingresosSegundaActividad: ingresoN2
    }


    /*const dataLaboral = {
        independiente: trabajadorIndependiente,
        tipoEstablecimiento: tipoEstablecimiento,
        ocupacion: ocupacion,
        nombreEmpresa: nombreEmpresa,
        telefonoEmpresa: telefonoEmpresa,
        ingresosNetosMensuales: ingresoNetoMensuales,
        tipoIngreso: tipoComprobante,
        aniosAntiguedad: aniosAntiguedad,
        mesesAntiguedad: mesesAntiguedad,
        calleLaboral: calleLaboral,
        noExterior: noExteriorLaboral,
        noInterior: noInteriorLaboral,
        codigoPostal: codigoPostalLaboral,
        colonia: noColoniaLaboral ? coloniaLaboral : anadeColonia,
        noExisteColonia: noColoniaLaboral,
        municipio: alcaldiaLaboral,
        estado: estadoLaboral
    }*/
    
    return UpdatePartialService.updatePartialSolicitud(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { codigo, llaveSeguridad } = data;
            if(codigo === 0) {
                dispatch({
                    type: type.updatePartialSolicitud,
                    payload: data
                });
                dataRequest.paso = 6;
                dispatch(updateDataSolicitud(dataRequest));
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.updatePartialSolicitudFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.respuesta
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.updatePartialSolicitudFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}

export const updatePartialReferencias = (data, dataSolicitud, usuario, securityKey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});

    const userAgent = window.navigator.userAgent;
    const { nombreReferencia1, telefonoReferencia1, nombreReferencia2, telefonoReferencia2, nombreReferencia3, telefonoReferencia3 } = data;
    const { distribuidor, id, seller } = dataSolicitud.dataSolicitud;

    const dataRequest = {
        userAgent: userAgent,
        carrierCelular: userAgent,
        canal: "2",
        version: "2.0.0",
        usuario: usuario,
        llaveSeguridad: securityKey,
        id: id,
        nombreReferencia1: nombreReferencia1,
        telefonoReferencia1: telefonoReferencia1,
        nombreReferencia2: nombreReferencia2,
        telefonoReferencia2: telefonoReferencia2,
        nombreReferencia3: nombreReferencia3,
        telefonoReferencia3: telefonoReferencia3,
        distribuidor: distribuidor,
        seller: seller,
        ip_dispositivo: "0.0.0.0",
        etapaProceso: "7"
    }
    //console.log(dataRequest);

    /*const dataReferencias = {
        referencia1: {
            nombreCompleto: nombreReferencia1,
            telefono: telefonoReferencia1,
        },
        referencia2: {
            nombreCompleto: nombreReferencia2,
            telefono: telefonoReferencia2,
        },
        referencia3: {
            nombreCompleto: nombreReferencia3,
            telefono: telefonoReferencia3,
        }
    }*/

    return UpdatePartialService.updatePartialSolicitud(dataRequest).then(
        (data) => {
            dispatch({type: type.setLoading});
            const { codigo, llaveSeguridad } = data;
            if(codigo === 0) {
                dispatch({
                    type: type.updateSaveSolicitud,
                    payload: data
                });

                /*dispatch({
                    type: type.updateSolicitud,
                    payload: {
                        paso: 7,
                        dataReferencias: dataReferencias
                    }
                });*/
                dataRequest.paso = 7;
                dispatch(updateDataSolicitud(dataRequest));
                UpdateSecurityKey(llaveSeguridad);

            } else {
                dispatch({
                    type: type.updatePartialSolicitudFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.respuesta
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.updatePartialSolicitudFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    )
}