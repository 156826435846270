import React from 'react';
import { Card, Form, Col, Button, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, connect } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import ElementoAccordion from './ElementoAccordion';
import { postCotiza } from '../actions/postCotiza';
import { getSecurityKey } from '../helpers/SecurityKey';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { resetCotizador } from '../actions/cotizador';
import { useEffect } from 'react';

const FormDatosSeguros = (props) => {
  const { closeModal, clearCotizador } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const securityKey = getSecurityKey();
  const obtencionSeguros = useSelector((state) => state.cotizaSeguro);
  const { isCotizaSeguro, cotizaSeguro} = obtencionSeguros;
  const obtencionUser = useSelector((state) => state.auth);
  const sucursalSelec = useSelector((state) => state.sucursalSelection);
  const { Clave, CveDistribuidor } = sucursalSelec.sucursalSeleccionada;

  const obtencionCotizador = useSelector((state) => state.cotizador);
  const { ValorAuto, seguroSelect } = obtencionCotizador;

  const validateSeguro = Yup.object({
    seguro: Yup.number().required()
  });
  const { handleSubmit, formState: { errors, isDirty}, control, setValue } = useForm({
    resolver: yupResolver(validateSeguro),
    mode: "onChange"
  });
  const onSubmit = (data) => {
    const idSeguro = data.seguro;
    const { IdDescripcion, CP, Modelo, ValorAuto, Enganche, Historial, Comprobantes } = obtencionCotizador;
    const { correo } = obtencionUser.user;
    const { CotizaSeguro } = obtencionSeguros.cotizaSeguro;

    const seguroSeleccionado = CotizaSeguro.find( seguro => seguro.id_aseguradora === idSeguro );
    const { Inicial, IdCotizacionSeguro, } = seguroSeleccionado;
    
    dispatch(postCotiza(CveDistribuidor, Clave, Inicial, IdDescripcion, CP, Modelo, correo, securityKey, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes, seguroSeleccionado))
  }
  const salirCotizacion = () => {
    dispatch(showAlert(MensajesAlertas.mensajeCancelacionCotizacion, () => { closeModal(); clearCotizador(); navigate("/home"); }, () => { closeModal(); }  ));
  }
  useEffect(() => {
    setValue("seguro", seguroSelect ? seguroSelect.id_aseguradora : "");
  }, []);
  

  return (
    <Card className='card-formulario'>
        <Card.Body className='p-0'>
          <Form className='formulario-plan-pago' onSubmit={handleSubmit(onSubmit)} >
            <Row>
              { isCotizaSeguro ? 
              
                cotizaSeguro.CotizaSeguro.map((seguro, index) => {
                  return <Form.Group as={Col} xs="12" className='mb-3' key={index}>
                    <Controller
                      name='seguro'
                      control={control}
                      render={({ field: {onChange,value} }) => (
                        <>
                          <ElementoAccordion 
                            name="seguro" 
                            value={value} 
                            position={seguro.id_aseguradora}
                            onChange= {onChange} 
                            datosSeguro = {seguro} 
                            montoCotizar={seguro.PrimaTotal}
                          />
                        </>
                        
                      )}
                    />
                    <p className='texto-error'>{errors.seguro?.message}</p>
                </Form.Group>
              })
                
                :  <p>No hay seguros</p>
              }
              
              <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                  <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion} >Cancelar</Button>
              </Form.Group>
              <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                <Button type="submit" className="btn btn-primary w-100" disabled={ (isDirty || seguroSelect.id_aseguradora) ? false : true } >Siguiente</Button>
              </Form.Group>
            </Row>
          </Form>
      </Card.Body>
    </Card>
  )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
        dispatch(resetCotizador());
        } 
	};
};

//export default FormDatosSeguros
export default connect(null, mapDispatchToProps)(FormDatosSeguros)