import React, { useState, useEffect } from 'react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import { connect, useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';

import BarsProgress from './BarsProgress';
import { cotizaSeguro } from '../actions/cotizaSeguro';
import { getSecurityKey } from '../helpers/SecurityKey';
import { FormatoCantidad, MotoSinFormato } from '../helpers/functionGlobal';
import { validMontoMax, validMontoMin } from '../helpers/methodValidate';
import { MensajesAlertas } from '../models/MensajesAlertas';
import { hideAlert, showAlert } from '../actions/alertMessage';
import { useNavigate } from 'react-router-dom';
import { resetCotizador } from '../actions/cotizador';
import { postCotiza } from '../actions/postCotiza';

const FormDatosPlanPago = (props) => {
    const { closeModal, clearCotizador, aceptaCotizacionTrad } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const obtencionCotizador = useSelector((state) => state.cotizador);

    const { IdDescripcion, CP, Modelo, ValorAuto, Enganche, montoFinanciamiento, MinEnganche, Historial, Comprobantes } = obtencionCotizador;
    const [montoFinanciarState, setMontoFinanciar] = useState(String(montoFinanciamiento));
    const [ costoState , setCosto ] = useState(String(ValorAuto));
    const [ montoEnganche , setMontoEnganche] = useState(String(Enganche));
    const [ engancheMinState , setEngancheMin ] = useState(String(MinEnganche));

    const sucursalSelec = useSelector((state) => state.sucursalSelection);
    const { Clave, CveDistribuidor } = sucursalSelec.sucursalSeleccionada;

    const obtencionSeguros = useSelector((state) => state.cotizaSeguro);

    const dataUser = useSelector((state) => state.auth);
    //console.log(dataUser);
    const securityKey = getSecurityKey();

    const validateAuto = Yup.object({
        costoAuto: Yup.string().required('El campo es requerido')
                    .test('montoMinimo', "El monto mínimo value es de $50,000", (value) => validMontoMin(value, 50000))
                    .test('montoMaximo', "El monto máximo value es de $999,999,999", (value) => validMontoMax(value, 999999999)),
        enganche: Yup.string().required('El campo es requerido').test('montoMinimo', `El monto mínimo es de $${FormatoCantidad(engancheMinState,0,"",",")}`, (value) => {
            let respuesta = false;
            if(value !== "" && value !== undefined) {
                const expMonetario = /[0-9]+[.|,]*[0-9]*[.|,]*[0-9]*/;
                const valor = expMonetario.test(value);
                const reemplaza = value.replace(/[^0-9\\.]+/g,'');
                const reemplazaCostoAuto = costoState.replace(/[^0-9\\.]+/g,'');
                if(valor && (reemplaza === 0 || (parseFloat(reemplaza) >= parseFloat(engancheMinState) && parseFloat(reemplaza) <= parseFloat(reemplazaCostoAuto) ))){
                    respuesta = true;
                }
            }
            return respuesta;
        })
    });
    
    const { handleSubmit, formState: { errors }, control, setValue, getValues } = useForm({
        resolver: yupResolver(validateAuto),
        mode: "onChange"
    });

    const onSubmit = (data) => {
        const { costoAuto, enganche } = data;
            data.costoAuto = parseInt(MotoSinFormato(costoAuto));
            data.enganche = parseInt(MotoSinFormato(enganche));
            data.montonFinanciar = montoFinanciarState;
            data.MinEnganche = engancheMinState;
        dispatch(cotizaSeguro(IdDescripcion, CP, Modelo, dataUser.user.correo, securityKey, data))
        .then((res) => {
            if(res.Codigo === 1) {
                dispatch(showAlert(MensajesAlertas.mensajeNoHaySeguros, 
                    () => { 
                        /*Si closeModal();*/
                        closeModal();
                        aceptaCotizacionTrad(CveDistribuidor, Clave, "", IdDescripcion, CP, Modelo, dataUser.user.correo, securityKey, null, parseInt(MotoSinFormato(costoAuto)), parseInt(MotoSinFormato(enganche)), Historial, Comprobantes, null) 
                    }, 
                    () => { /*No*/closeModal(); }  ));
            }
        })
    }
    const salirCotizacion = () => {
        dispatch(showAlert(MensajesAlertas.mensajeCancelacionCotizacion, () => { closeModal(); clearCotizador(); navigate("/home"); }, () => { closeModal(); }  ));
    }

    useEffect(() => {
        setValue("costoAuto", String(ValorAuto));
        setValue("enganche", String(Enganche));
        setValue("montonFinanciar", String(montoFinanciamiento));

        /*setValue("costoAuto", `$${FormatoCantidad(ValorAuto,0,'',',')}`);
        setValue("enganche", `$${FormatoCantidad(Enganche,0,'',',')}`);
        setValue("montonFinanciar", `$${FormatoCantidad(montoFinanciamiento,0,'',',')}`);*/
    }, []);

    //console.log(dataUser.user.validacion_sh);
    
    const getMontoFinanciar = (montoAuto, enganche) => {
        const reemplazaEnganche = enganche.replace(/[^0-9\\.]+/g,'');
        const reemplazaCostoAuto = montoAuto.replace(/[^0-9\\.]+/g,'');
        const resta =  reemplazaCostoAuto - reemplazaEnganche;
        let porcentaje = 0.2;
        /*if(dataUser.user.validacion_sh) {
            if(Historial) {
                if(resta >= 250000) {
                    //console.log("entro en mayor a 250,000");
                    porcentaje = 0.35;
                }
            } else {
                porcentaje = 0.35;
            }
        } else {
            if(resta >= 250000) {
                porcentaje = 0.35;
            }
        }*/
        const engancheMin = porcentaje * reemplazaCostoAuto;
        setMontoFinanciar(resta);
        setEngancheMin(engancheMin);
    }
    
  return (
    <>
        <Card className='card-formulario'>
            <Card.Body className='p-0'>
                <Form className='formulario-plan-pago' onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Form.Group as={Col} xs="12" className='mb-3'>
                            <Form.Label>Costo del auto</Form.Label>
                            <Controller
                                name="costoAuto"
                                control={control}
                                render={({ field: { onChange, name, value } }) => (
                                    <NumberFormat
                                        className="form-control"
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        name={name}
                                        value={value}
                                        maxLength={12}
                                        onChange={(e) => {
                                            const cantidad = e.target.value;
                                            if(getValues("enganche") !== "") {
                                                getMontoFinanciar(cantidad, getValues("enganche"));
                                            }
                                            setCosto(cantidad);
                                            onChange(cantidad);
                                        }}
                                        onBlur={(e) => {
                                            const cantidad = e.target.value;
                                            if(getValues("enganche") !== "") {
                                                getMontoFinanciar(cantidad, getValues("enganche"));
                                            }
                                            setCosto(cantidad);
                                        }}
                                        autoComplete={"off"}
                                    />
                                )}
                            />
                            <p className='texto-error'>{errors.costoAuto?.message}</p>
                            <BarsProgress montoMin={MotoSinFormato(costoState)} montoMax={999999999} />
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>¿Cuánto quiere dar de enganche?</Form.Label>
                            <Controller
                                name="enganche"
                                control={control}
                                render={({ field: { onChange, name, value} }) => (
                                    <NumberFormat
                                        className="form-control"
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        name={name}
                                        value={value}
                                        maxLength={12}
                                        onChange={(e) => {
                                            const cantidad = e.target.value;
                                            
                                            if(getValues("costoAuto") !== "") {
                                                getMontoFinanciar(getValues("costoAuto"), cantidad);
                                            }
                                            setMontoEnganche(cantidad);
                                            onChange(cantidad);
                                        }}
                                        autoComplete={"off"}
                                    />
                                )}
                            />
                            {/*<p className='texto-error'>{errors.enganche?.message}</p>*/}
                            {
                                (engancheMinState !== "" && 
                                (parseFloat(montoEnganche.replace(/[^0-9\\.]+/g,'')) >= parseFloat(engancheMinState) ) 
                                && parseFloat(montoEnganche.replace(/[^0-9\\.]+/g,'')) <= parseFloat(costoState.replace(/[^0-9\\.]+/g,''))) ?
                                    <p className='texto-monto-min'>{`El monto mínimo es de $${FormatoCantidad(engancheMinState,2,".",",")}`}</p>
                                : engancheMinState !== "" ? 
                                    <p className='texto-error'>{`El monto mínimo es de $${FormatoCantidad(engancheMinState,2,".",",")}`}</p>
                                : ""
                            }
                            
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className='mb-3'>
                            <Form.Label>Monto a financiar</Form.Label>
                            <Controller
                                name="montonFinanciar"
                                control={control}
                                render={({ field: { onChange, name, value } }) => (
                                <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    name={name}
                                    value={montoFinanciarState === "" ? montoFinanciamiento : montoFinanciarState}
                                    maxLength={12}
                                    onChange={onChange}
                                    autoComplete={"off"}
                                    readOnly
                                />
                                )}
                            />
                            <p className='texto-error'>{errors.montonFinanciar?.message}</p>
                        </Form.Group>


                        <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                            <Button type="button" className="btn btn-light w-100" onClick={salirCotizacion}>Cancelar</Button>
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className="mt-5 mb-3" >
                            <Button type="submit" className="btn btn-primary w-100" >Continuar</Button>
                        </Form.Group>

                    </Row>
                </Form>
            </Card.Body>
        </Card>
    </>
  )
}

const mapDispatchToProps = dispatch => {
	return {
		closeModal: () => dispatch(hideAlert()),
        clearCotizador: () => {
            dispatch(resetCotizador());
        },
        aceptaCotizacionTrad: (CveDistribuidor, Clave, Inicial, IdDescripcion, CP, Modelo, correo, securityKey, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes, seguroSeleccionado) => {
            dispatch(postCotiza(CveDistribuidor, Clave, Inicial, IdDescripcion, CP, Modelo, correo, securityKey, IdCotizacionSeguro, ValorAuto, Enganche, Historial, Comprobantes, seguroSeleccionado))
        }
	};
};
//export default FormDatosPlanPago
export default connect(null, mapDispatchToProps)(FormDatosPlanPago)