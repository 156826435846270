import { type as types } from "../types";

const user = JSON.parse(sessionStorage.getItem("user"));
const initialState = user ? { isLoggedIn: true, user, errorMessage: null } : {isLoggedIn: false, user: null, errorMessage: null}

export const AuthReducer = (state = initialState, action) => {

    const {type, payload } = action;

    switch(type){
        
        case types.loginSucces:
            const data1 = {
                ...state,
                isLoggedIn: true,
                user: payload,
                errorMessage: null
            }
            return data1;
        case types.loginFail:

            const { errorMessage } = payload;
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                errorMessage: errorMessage
            }

        case types.logout:
            const data = {
                ...state,
                isLoggedIn: false,
                user: null,
                errorMessage: null
            }
            return data;
        
        default:
            return state;
    }

}
