import { type } from "../types/index";
import CatalogosService from "../services/catalogos.service";
import { UpdateSecurityKey } from "../helpers/SecurityKey";

export const getCatalogos = (usuario, securitykey) => (dispatch) => {
    dispatch({type: type.clearMessage});
    dispatch({type: type.setLoading});
    return CatalogosService.getCatalogos(usuario, securitykey).then(
        (data) => {
            dispatch({type: type.setLoading});
            
            if(data.codigo === 0) {
                dispatch({
                    type: type.getCatalagos,
                    payload: data
                });
                UpdateSecurityKey(data.llaveSeguridad);

            } else {
                dispatch({
                    type: type.getCatalagosFail
                });
                dispatch({
                    type: type.setError,
                    payload: {
                        tipoError: data.codigo,
                        mensajeError: data.mensaje
                    }
                });
            }
            dispatch({
                type: type.textMessage,
                payload: data
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = (error.response && error.response.data && error.response.data.message) ||
            error.message || error.toString();
            dispatch({type: type.setLoading});
            dispatch({
                type: type.getCatalagosFail
            });
            dispatch({
                type: type.textMessage,
                payload: message
            });
            dispatch({
                type: type.setError,
                payload: {
                    tipoError: 0,
                    mensajeError: message
                }
            });
            return Promise.reject(error);
        }
    ) .catch((error)=>{
        dispatch({
            type: type.setError,
            payload: {
                tipoError: 0,
                mensajeError: "Hay un problemas con el servicio intente mas tarde"
            }
        });
    });
}
