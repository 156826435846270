import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EstatusSolicitudes } from '../models/EstatusSolicitudes';

import { getHistorialSolicitud } from '../actions/solicitudes';
import { getSecurityKey } from '../helpers/SecurityKey';

const DetalleSolicitud = () => {

    const { id } = useParams();

    const dispatch = useDispatch();
    const securityKey = getSecurityKey();

    const dataUser = useSelector((state) => state.auth); 
    const obtencionSolicitud = useSelector((state) => state.solicitudes);
    const { idFase, idEstatus } = obtencionSolicitud.dataSolicitud;
    const { historial } = obtencionSolicitud;
    useEffect(() => {
        dispatch(getHistorialSolicitud(dataUser.user.correo, securityKey, id, idFase, idEstatus))
    }, []);
    
    const listaHistorial = historial.map((historia, index) => {
        const fechaHora = historia.fechaRespuesta.split(' ');

        const estatusSelect = EstatusSolicitudes.find(element => element.value === historia.estatus );
        const claseEstatus = estatusSelect && estatusSelect.clase;
 
        return <li className={`item-proceso ${claseEstatus}`}  key={index} >
                    <div className='descripcion'>{historia.estatusDescripcion}</div>
                    <div className='fecha'>{fechaHora[0]} <b>{fechaHora[1]}</b></div>
                </li>
    });
    return (
        <Card className='card-listado-proceso'>
            <Card.Body>
                <ul className='lista-procesos'>
                    {listaHistorial}
                </ul>
            </Card.Body>
        </Card>
    )
}

export default DetalleSolicitud